import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImageListType } from "react-images-uploading";

import DeliverablesForm from "./Form";

import SlotContext from "../../state/contexts/slot";
import SweepContext from "../../state/contexts/sweep";
import { useLoading } from "../../state/loader";
import { useAuth } from "../../state/auth";

import { SlotPayload } from "../../DataTypes";
import { Slots } from "../../apiClient/Slot";
import { useSlot } from "../../hooks/useSlot";
import useQuery from "../../hooks/useQuery";

import { sponsorChecklistSectionOrder } from "../../pages/SponsorChecklist/utils";
import nextIncompleteSection from "../../utils/nextSectionRedirection";
import { CustomError, handleError } from "../../utils/errorHandler";
import { errorToast } from "../../utils/toast";
import Loader from "../Loader/Loader";

import paths from "../../Paths";

import "./SweepstakesDeliverables.scss";

const SweepstakesDeliverables = (): JSX.Element => {
  const navigate = useNavigate();
  const { loginInfo } = useAuth();
  const query = useQuery();
  const slotId = parseInt(query.get("slot_id"));
  const { setSlot, slot } = useContext(SlotContext);
  const { sweep } = useContext(SweepContext);
  const { setLoading } = useLoading();

  const [companyImage, setCompanyImage] = useState<ImageListType>([]);
  const [lifestyleImages, setLifestyleImages] = useState<ImageListType>([]);
  const [sweepVideo, setSweepVideo] = useState<File>();

  const slotFromApi = useSlot(slotId);

  useEffect(() => {
    slotFromApi && setSlot(slotFromApi);
  }, [slotFromApi, setSlot]);

  const imageStateProps = {
    companyImage,
    setCompanyImage,
    lifestyleImages,
    setLifestyleImages,
    sweepVideo,
    setSweepVideo,
  };

  const onSubmit = async (values: SlotPayload): Promise<void> => {
    const slotClient = new Slots();
    try {
      setLoading(true);

      if (lifestyleImages.some((image) => image?.file)) {
        await Promise.all(
          [...lifestyleImages]
            .filter((image) => image.file)
            .map(async (image) => {
              return await slotClient.postAssetApi(
                slot.id,
                {
                  field: "store_lifestyle_images",
                  filename: image.file,
                },
                {
                  headers: {
                    Authorization: loginInfo.token,
                  },
                }
              );
            })
        );
        setLifestyleImages([]);
      }

      if (sweepVideo) {
        if (slot.brand_assets.sweep_videos.length === 0) {
          const { data } = await slotClient.postAssetApi(
            slot.id,
            {
              field: "sweep_videos",
              filename: sweepVideo,
            },
            {
              headers: {
                Authorization: loginInfo.token,
              },
            }
          );
          setSweepVideo(null);
          setSlot(data);
        } else {
          const { data } = await slotClient.putAssetApi(
            slot.id,
            {
              field: "sweep_videos",
              filename: sweepVideo,
              asset_id: slot.brand_assets.sweep_videos[0].id,
            },
            {
              headers: {
                Authorization: loginInfo.token,
              },
            }
          );
          setSweepVideo(null);
          setSlot(data);
        }
      }
      const payload =
        companyImage.length > 0 && companyImage.every((image) => image?.file)
          ? {
              ...values,
              image: companyImage[0].file,
            }
          : values;

      const { data } = await slotClient.patchSlotApi(slot.id, payload, {
        headers: {
          Authorization: loginInfo.token,
        },
      });

      setSlot(data);
      setLoading(false);
      alert("Data Saved!");
      nextIncompleteSection(navigate, sponsorChecklistSectionOrder(data, sweep));
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  if (!slot && !slotId) {
    navigate(paths.sponsorChecklist.url);
    return null;
  }

  return (
    <div className="sweepstakes-deliverables">
      <h1 className="heading-primary">Sweepstakes Deliverables</h1>
      {slot ? (
        <DeliverablesForm
          onSubmit={onSubmit}
          slot={slot}
          {...imageStateProps}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SweepstakesDeliverables;
