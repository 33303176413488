import { useEffect, useState } from "react";

import { Company } from "../DataTypes";
import { Companies } from "../apiClient/Company";

import { useAuth } from "../state/auth";
import { CustomError, handleError } from "../utils/errorHandler";
import { useLoading } from "../state/loader";

export const useCompany = (tick = 0): Company => {
  const { loginInfo } = useAuth();
  const { setLoading } = useLoading();
  const [company, setCompany] = useState<Company>(null);

  // This returns the first company in array to update in BP
  const getContactCompany = (companies: Company[]): Company => companies[0];

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        setLoading(true);
        const { data } = await new Companies().getCompanyListApi({
          headers: { Authorization: loginInfo.token },
        });
        setCompany(getContactCompany(data));
      } catch (error) {
        console.error(handleError(error as CustomError).message);
      } finally {
        setLoading(false);
      }
    };
    tick > 0 && fetchCompany();
  }, [tick, loginInfo, setLoading]);

  return company;
};
