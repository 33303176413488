import React from "react";

import loadingHeart from "../../../../assets/images/fkl-loading-heart.gif";
import { bpContactEmail } from "utils/constants";

const PaymentProcessing = (): JSX.Element => {
  return (
    <>
      <h1 className="onboarding-title">Your Payment Is Processing</h1>
      <p className="onboarding-info">
        It will take a few seconds to complete...
      </p>
      <img className="payment-loading" src={loadingHeart} alt="loading" />
      <p className="onboarding-info">
        {`Questions about your payment? Contact your FindKeep.Love account manager or reach out to us at ${bpContactEmail}`}
      </p>
    </>
  );
};

export default PaymentProcessing;
