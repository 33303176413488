import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CompanyContext from "../../state/contexts/company";
import {
  BrandSigning,
  PaymentStatus,
  Payment,
  ProcessPaymentState,
} from "../../DataTypes";
import { getCurrentBrandSigning } from "../../Components/StatementOfWork/utils";
import { Payments } from "../../apiClient/Payment";
import { useAuth } from "../../state/auth";
import { useCompany } from "../../hooks/useCompany";
import { bpBillingEmail } from "../../utils/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { CreditCardValues } from "./Form";

import { Companies } from "../../apiClient/Company";
import loadingHeart from "../../assets/images/fkl-loading-heart.gif";
import staticHeart from "../../assets/images/fkl-static-heart.png";
import paths from "../../Paths";
import "./PayYourBalance.scss";
import { PaymentBox } from "./components/PaymentBox";
import EmailLink from "./components/EmailLink";

const companyClient = new Companies();
const apiPayment = new Payments();

interface ProcessPaymentArgs {
  brandSigning: BrandSigning;
  creditCardValues: CreditCardValues;
  onSuccess: () => void;
  onErrors: (errors: string[]) => void;
  onAnyCase: () => void;
  token: string;
}

interface CreditCardErrors {
  number: string[];
  code: string[];
  expiration_date: string[];
  transaction_errors: string[];
}

const mapErrorName = (key: string): string => {
  const map: Record<string, string> = {
    number: "Credit Card Number",
    code: "CVV",
    expiration_date: "Expiration Date",
    transaction_errors: "Transaction Error",
  };
  return map[key] || "Unknown error";
};

const combineErrors = (errors: CreditCardErrors): string[] =>
  Object.entries(errors)
    .filter(([_1, value]) => !!value)
    .map(([key, value]) => `${mapErrorName(key)}: ${value}`);

interface ProcessPaymentProps {
  title: string;
  isFailure: boolean;
  content: JSX.Element;
}

const propsFromState = (
  state: ProcessPaymentState,
  onBack: () => void
): ProcessPaymentProps => {
  switch (state) {
    case ProcessPaymentState.Processing:
      return {
        title: "Your Payment is Processing",
        isFailure: false,
        content: (
          <div className="processing__content">
            <p>It will take a few seconds to complete...</p>
            <img height={100} src={loadingHeart} alt={"Loading logo"} />
          </div>
        ),
      };
    case ProcessPaymentState.Success:
      return {
        title: "Thank You!",
        isFailure: false,
        content: (
          <div className="processing__content">
            <p>We will be in touch with the next steps.</p>
            <p>
              In the meantime, explore Brand Portal starting with the{" "}
              <Link to={paths.home.url} className="link link--black">
                Home Page
              </Link>
              .
            </p>
            <img height={50} src={staticHeart} alt={"FKL logo"} />
          </div>
        ),
      };
    case ProcessPaymentState.Failure:
      return {
        title: "We Couldn't Process Your Payment",
        isFailure: true,
        content: (
          <div className="processing__content">
            <p>Please review your information and try again</p>
            <button className="btn" onClick={onBack}>
              <FontAwesomeIcon className="fa-sm" icon={faArrowLeft} /> Back to
              Payment
            </button>
          </div>
        ),
      };
    default:
      return {
        title: "Unknown Process Payment Status",
        isFailure: true,
        content: <div>Please, contact support</div>,
      };
  }
};

const ProcessPaymentDisplay: React.FC<ProcessPaymentProps> = (props) => (
  <section className="pay-your-balance processing">
    <h1
      className={`heading-primary heading__font ${
        props.isFailure && "failure-payment"
      }`}
    >
      {props.title}
    </h1>
    {props.content}
    <p>
      Questions about your payment? Contact your account manager directly for
      the quickest response, or reach us at <EmailLink highlight />.
    </p>
  </section>
);

const processPayment = async ({
  brandSigning,
  creditCardValues,
  token,
  onSuccess,
  onAnyCase,
  onErrors,
}: ProcessPaymentArgs) => {
  try {
    const [expirationMonth, expirationYear] =
      creditCardValues.expiration_date.split("/");

    await apiPayment.postBrandSigningPaymentApi(
      brandSigning.id,
      {
        number: parseInt(creditCardValues.credit_card_number),
        expiration_date: `20${expirationYear}-${expirationMonth}`,
        code: creditCardValues.cvv,
        billing_zip_code: creditCardValues.billing_zip_code,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    alert("Data Saved!");
    onSuccess();
  } catch (data) {
    const requestErrors = combineErrors(
      (data as { error?: { errors?: CreditCardErrors } })?.error?.errors
    ) ?? ["Error on request. Please contact support team."];
    alert(requestErrors.join("\n"));
    onErrors(requestErrors);
  } finally {
    onAnyCase();
  }
};

const getLatestPayment = async (brandSigning: BrandSigning, token: string) => {
  const response = await apiPayment.getBrandSigningPaymentApi(brandSigning.id, {
    headers: {
      Authorization: token,
    },
  });
  const paymentPage = response.data;

  return paymentPage.payments.length ? paymentPage.payments[0] : null;
};

interface PaymentStatusInfo {
  title: string;
  paragraph: JSX.Element;
  required: boolean;
}

const toPaymentInfo = (payment: Payment): PaymentStatusInfo => {
  switch (payment.status) {
    case PaymentStatus.success:
      return {
        title: "Your payment is complete",
        paragraph: (
          <p>
            If you have any questions, contact your account manager directly for
            the quickest response, or reach us at <EmailLink />.
          </p>
        ),
        required: false,
      };
    case PaymentStatus.failure:
      return {
        title: "There has been an issue with your latest payment",
        paragraph: (
          <p>
            Please contact your account manager directly for the quickest
            response, or reach us at <EmailLink />.
          </p>
        ),
        required: true,
      };
    case PaymentStatus.declined:
      return {
        title: "Your latest payment has been declined",
        paragraph: (
          <>
            <p>
              Please, check carefully the card information when proceeding with
              the payment again.
            </p>
            <p>
              If you consider this an error in FKL platform, contact your
              account manager directly for the quickest response, or reach us at{" "}
              <EmailLink />.
            </p>
          </>
        ),
        required: true,
      };
    case PaymentStatus.refunded:
    case PaymentStatus.voided:
      return {
        title: "Your latest payment has been refunded",
        paragraph: (
          <p>
            <span>FindKeep.Love works on a pre-payment basis. </span>
            <span>
              Please complete the information below to pay your balance.
            </span>
          </p>
        ),
        required: true,
      };
    case PaymentStatus.pending:
    case PaymentStatus.onHold:
      return {
        title: "Thanks! Your payment is processing.",
        paragraph: (
          <>
            <p>
              You can close this page. Our team might contact you via email
              regarding your payment.
            </p>
            <p>
              If you have any questions, please reach out at <EmailLink /> or
              via live chat below.
            </p>
          </>
        ),
        required: false,
      };
    default:
      return {
        title: "Unknown status",
        paragraph: (
          <p>
            Please contact your account manager directly for the quickest
            response, or reach us at <EmailLink />.
          </p>
        ),
        required: false,
      };
  }
};

const PaymentStatusDisplay = ({
  payment,
  onSubmit,
  errors,
}: {
  payment: Payment;
  onSubmit: (creditCardValues: CreditCardValues) => void;
  errors: string[];
}) => {
  const paymentInfo = toPaymentInfo(payment);
  const paymentBox = paymentInfo.required ? (
    <PaymentBox amount={payment.amount} onSubmit={onSubmit} errors={errors} />
  ) : (
    <></>
  );
  return (
    <section className="pay-your-balance">
      <h1
        className={`heading-primary heading__font ${
          paymentInfo.required && "failure-payment"
        }`}
      >
        {paymentInfo.title}
      </h1>
      {paymentInfo.paragraph}
      {paymentBox}
    </section>
  );
};

const PayYourBalance = (): JSX.Element => {
  const { loginInfo } = useAuth();
  const { company, setCompany } = useContext(CompanyContext);
  const [errors, setErrors] = useState<string[]>([]);
  const [tick, setTick] = useState<number>(0);
  const [processPaymentStatus, setProcessPaymentStatus] =
    useState<ProcessPaymentState>(ProcessPaymentState.NotProcessing);
  const companyFromApi = useCompany(tick);
  const brandSigning = getCurrentBrandSigning(company);
  const [latestPayment, setPayment] = useState<Payment>(null);

  useEffect(() => {
    companyFromApi && setCompany(companyFromApi);
  }, [companyFromApi, setCompany]);

  useEffect(() => {
    getLatestPayment(brandSigning, loginInfo.token)
      .then((payment) => {
        setPayment(
          payment
            ? {
                status: payment.status,
                amount: payment.amount_paid,
              }
            : null
        );
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [brandSigning, loginInfo.token]);

  const handleSubmit = async (creditCardValues: CreditCardValues) => {
    setProcessPaymentStatus(ProcessPaymentState.Processing);

    if (brandSigning.date_agreement === null) {
      await companyClient.postCompanySowSigningApi(
        brandSigning.id,
        company.id,
        {},
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      );
    }

    await processPayment({
      brandSigning,
      creditCardValues,
      onSuccess: () => {
        setProcessPaymentStatus(ProcessPaymentState.Success);
        setTick(tick + 1);
      },
      onAnyCase: () => {
        return;
      },
      onErrors: (errors) => {
        setProcessPaymentStatus(ProcessPaymentState.Failure);
        setErrors(errors);
      },
      token: loginInfo.token,
    });
  };

  if (processPaymentStatus !== ProcessPaymentState.NotProcessing) {
    return (
      <ProcessPaymentDisplay
        {...propsFromState(processPaymentStatus, () =>
          setProcessPaymentStatus(ProcessPaymentState.NotProcessing)
        )}
      />
    );
  }

  if (!brandSigning) {
    return (
      <div>
        Pay Your Balance is not ready. Please contact{" "}
        <EmailLink>{bpBillingEmail}</EmailLink>.
      </div>
    );
  }

  if (!brandSigning.sow_total_to_be_paid) {
    return (
      <div>
        Your payment is not set yet. Please come back later or contact{" "}
        <EmailLink>{bpBillingEmail}</EmailLink>.
      </div>
    );
  }

  if (latestPayment) {
    return (
      <PaymentStatusDisplay
        payment={latestPayment}
        onSubmit={handleSubmit}
        errors={errors}
      />
    );
  }

  return (
    <section className="pay-your-balance">
      <h1 className="heading-primary heading__font">Pay Your Balance</h1>
      <p>
        <span>FindKeep.Love works on a pre-payment basis. </span>
        <span>Please complete the information below to pay your balance.</span>
      </p>
      <PaymentBox
        amount={brandSigning.sow_total_to_be_paid}
        onSubmit={handleSubmit}
        errors={errors}
      />
    </section>
  );
};

export default PayYourBalance;
