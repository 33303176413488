import React, { FC, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../Components/Loader/Loader";
import { useCplV2 } from "../../../../../hooks/useCpl";
import { useLoading } from "../../../../../state/loader";
import { CustomError, handleError } from "../../../../../utils/errorHandler";
import redirectionTrigger from "../../../../../utils/redirectionTrigger";
import { errorToast } from "../../../../../utils/toast";
import AssetCollectionForm from "./components/AssetCollectionForm";
import useDeleteAsset from "./hooks/useDeleteAsset";
import useImageUpload from "./hooks/useImageUpload";
import usePatchAssets from "./hooks/usePatchAssets";
import useVideoUpload from "./hooks/useVideoUpload";

const AssetCollection: FC<{ next: string }> = ({ next }) => {
  const { data: cpl } = useCplV2();
  const { setLoading } = useLoading();
  const initialValues = useMemo(
    () => ({
      brand_description: cpl?.company?.brand_description,
      short_brand_description: cpl?.company?.short_brand_description,
      tagline: cpl?.company?.tagline,
      logo: cpl?.company?.image
        ? {
            dataURL: cpl?.company?.image,
          }
        : undefined,
      offer_image: cpl?.brand_assets?.offer_images?.[0]?.filename
        ? {
            dataURL: cpl.brand_assets.offer_images[0].filename,
            id: cpl.brand_assets.offer_images[0].id,
          }
        : undefined,
      product_images: cpl?.brand_assets?.product_shot_images?.map(
        ({ id, description, filename, title }) => ({
          image: {
            dataURL: filename,
            id,
          },
          description,
          title,
          isUploaded: true,
        })
      ),
      offer_video: cpl?.brand_assets?.cpl_videos?.[0]?.filename,
      no_cpl_videos: cpl?.no_cpl_videos,
    }),
    [cpl]
  );
  const uploadImage = useImageUpload(cpl);
  const deleteAsset = useDeleteAsset(cpl);
  const patchAssets = usePatchAssets(cpl);
  const uploadVideo = useVideoUpload(cpl);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  if (!cpl) {
    return <Loader />;
  }

  return (
    <>
      <p data-testid="intro">
        FindKeep.Love uses your copy and assets to introduce your brand to
        users, and ultimately generate leads.
      </p>
      <AssetCollectionForm
        initialValues={initialValues}
        onSubmit={async (values, _methods) => {
          setLoading(true);

          try {
            // Add offer image
            if (values?.offer_image?.file) {
              if (cpl.brand_assets.offer_images[0]) {
                await deleteAsset(
                  "offer_images",
                  cpl.brand_assets.offer_images[0].id
                );
              }

              await uploadImage("offer_images", {
                file: values.offer_image.file,
              });
            }

            // Add new product images
            for (const productImage of values.product_images) {
              if (!productImage.isUploaded) {
                await uploadImage("product_shot_images", {
                  file: productImage.image.file,
                  description: productImage.description,
                  title: productImage.title,
                });
              }
            }

            // Add or remove product video
            const hasNewVideo =
              !values.no_cpl_videos && typeof values.offer_video === "object";
            if (
              (hasNewVideo || values.no_cpl_videos) &&
              cpl?.brand_assets?.cpl_videos?.[0]
            ) {
              await deleteAsset(
                "cpl_videos",
                cpl.brand_assets.cpl_videos[0].id
              );
            }
            if (hasNewVideo) {
              await uploadVideo(values.offer_video as File);
            }

            // Remove product images not present in values
            await Promise.all(
              cpl.brand_assets.product_shot_images
                .filter(
                  ({ id }) =>
                    !values.product_images.find(({ image }) => image.id === id)
                )
                .map(({ id }) => deleteAsset("product_shot_images", id))
            );

            // Update brand assets details
            await patchAssets({
              brand_description: values.brand_description,
              short_brand_description: values.short_brand_description,
              tagline: values.tagline,
              image: values.logo?.file,
              no_cpl_videos: values.no_cpl_videos,
            });

            queryClient.invalidateQueries("cpl");
            redirectionTrigger(navigate, next);
          } catch (error) {
            errorToast(handleError(error as CustomError).message);
          }
          setLoading(false);
        }}
      />
    </>
  );
};

export default AssetCollection;
