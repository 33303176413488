import React, { FC, useContext, useEffect, useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { Companies } from "../../apiClient/Company";
import {
  PrizingOptions,
  RecurringFrequency,
  TypeOfAgreement,
  BrandSigning,
} from "../../DataTypes";

import { useAuth } from "../../state/auth";
import CompanyContext from "../../state/contexts/company";
import { useLoading } from "../../state/loader";
import { useUser } from "../../hooks/useUser";
import {
  isSOWComplete,
  getStartedSectionOrder,
  isLetsGetStartedComplete,
} from "../../pages/GetStartedCheckList/GetStartedChecklist";
import ErrorFormDisplay from "../ErrorFormDisplay/ErrorFormDisplay";
import PDFPreview from "../PDFPreview/PDFPreview";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import { getCurrentBrandSigning } from "./utils";
import validationSchemaFn from "./validations";
import nextIncompleteSection from "../../utils/nextSectionRedirection";
import { CustomError, handleError } from "../../utils/errorHandler";
import { bpContactEmail } from "../../utils/constants";

import "./StatementOfWork.scss";
import { useCompany } from "../../hooks/useCompany";
import { errorToast } from "../../utils/toast";

type Values = {
  statement_of_work: boolean;
  prizing_option: PrizingOptions | "";
};

const StatementOfWork: FC = () => {
  const INITIAL_TICK = 0;
  const user = useUser();
  // The default value of `isDocRead` has been set to true to always show the t&c's checkbox
  const [isDocRead, setIsDocRead] = useState<boolean>(true);

  const [sowDownloaded, setSowDownloaded] = useState<boolean>(false);
  const [brandSigning, setBrandSigning] = useState<BrandSigning>();
  const [tick, setTick] = useState<number>(INITIAL_TICK);

  const companyFromApi = useCompany(tick);

  const navigate = useNavigate();

  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const { company, setCompany } = useContext(CompanyContext);

  useEffect(() => {
    companyFromApi && setCompany(companyFromApi);
  }, [companyFromApi, setCompany]);

  useEffect(() => {
    company && setBrandSigning(getCurrentBrandSigning(company));
  }, [company]);

  const isDisable = isSOWComplete(company);
  const isATCComplete = isLetsGetStartedComplete(company);

  const onSubmit = async (values: Values) => {
    const companyClient = new Companies();

    const submitData = async () => {
      await companyClient.postCompanySowSigningApi(
        brandSigning.id,
        company.id,
        {
          is_downloaded: sowDownloaded,
          prizing_option: values.prizing_option as PrizingOptions,
        },
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      );
      setTick(tick + 1);
      alert("Data Saved!");
      nextIncompleteSection(navigate, getStartedSectionOrder(company, user));
    };

    try {
      if (brandSigning.type_of_agreement === TypeOfAgreement.recurring) {
        // eslint-disable-next-line no-restricted-globals
        confirm(
          `By accepting this Statement of Work, you understand you are agreeing
              to a recurring service program and you authorize FindKeep.Love to
              charge you on a ${
                RecurringFrequency[brandSigning.recurring_frequency]
              }
              basis, as outlined in the Statement of Work`
        );
      }
      setLoading(true);
      submitData();
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  if (!brandSigning) {
    return (
      <div>
        Statement of Work is not ready. Please contact{" "}
        <a href={`mailto:${bpContactEmail}`} className="terms-download">
          {bpContactEmail}.
        </a>
      </div>
    );
  }

  const initialValues: Values = {
    statement_of_work: isDisable,
    prizing_option: brandSigning.prizing_option || "",
  };

  return (
    <>
      {isATCComplete ? (
        <div>
          <h1 className="heading-primary heading__font">Statement of Work</h1>
          <p>
            In order to work with us you must review and agree to our{" "}
            <a
              className="terms-download"
              href={brandSigning.sow_document_full_path}
              onClick={() => setSowDownloaded(true)}
              target="_blank"
              rel="noreferrer"
            >
              Statement of Work
            </a>
          </p>
          <PDFPreview
            file={brandSigning.sow_document_full_path}
            setIsDocRead={setIsDocRead}
          />
          {isDocRead && (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchemaFn(brandSigning.sow_sweep)}
            >
              {({
                values,
                setFieldValue,
                errors,
                isSubmitting,
                submitCount,
                isValid,
              }) => {
                return (
                  <Form>
                    <p>
                      <input
                        type="checkbox"
                        className="spacing-checkbox"
                        name="statement_of_work"
                        id="statement_of_work"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setFieldValue(
                            "statement_of_work",
                            event.currentTarget.checked
                          );
                        }}
                        disabled={isDisable}
                        defaultChecked={isDisable}
                      />
                      <label htmlFor="statement_of_work">
                        By clicking the box, you agree to the FindKeep.Love{" "}
                        <a
                          className="terms-download"
                          href={brandSigning.sow_document_full_path}
                          onClick={() => setSowDownloaded(true)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Statement of Work
                        </a>
                      </label>
                    </p>
                    {values.statement_of_work && brandSigning.sow_sweep && (
                      <>
                        <h3 className="heading-tertiary">PRIZE DETAILS</h3>
                        <p>
                          All prizing requirements have a minimum $400 value.
                          Please select one of the options below.
                        </p>
                        <h3 className="heading-tertiary">
                          OPTION 1: DIGITAL GIFT CARD TO COMPANY SITE (STRONGLY
                          RECOMMENDED)
                        </h3>
                        <p>
                          This option is a digital card that the winner can go
                          directly to your site to redeem with a promotion code
                        </p>
                        <h3 className="heading-tertiary">
                          OPTION 2: VISA OR AMEX GIFT CARD
                        </h3>
                        <p>
                          If you cannot provide a gift card to your site, you
                          can contribute a VISA or Amex gift card. A 25%
                          processing fee will apply. These gift cards must be
                          purchased by the FindKeep.Love team. Gift card and 25%
                          fee will be invoiced prior to the service commencing.
                        </p>
                        <h3 className="heading-tertiary">
                          OPTION 3: PHYSICAL PRIZE (NOT RECOMMENDED)
                        </h3>
                        <p>
                          If providing a physical prize, FindKeep.Love must
                          receive product(s) at least 2 weeks prior to the
                          sweepstakes launch date. You will be charged a min.
                          upfront $100 fee for FindKeep.Love to package and ship
                          product(s) to the winner. If shipping is {">"}$100,
                          you will be billed the difference.
                        </p>
                        <div>
                          <ErrorMessage
                            name="prizing_option"
                            component="div"
                            className="form__control--error"
                          />
                          <Field
                            as="select"
                            name="prizing_option"
                            id="prizing_option"
                            className="form__input"
                            disabled={isDisable}
                            data-testid="test_prizing_option_selected"
                          >
                            <option value="" defaultChecked>
                              Select the prize details
                            </option>
                            <option value={PrizingOptions.GiftCard}>
                              OPTION 1: DIGITAL GIFT CARD TO COMPANY SITE
                            </option>
                            <option value={PrizingOptions.CreditCard}>
                              OPTION 2: VISA OR AMEX GIFT CARD
                            </option>
                            <option value={PrizingOptions.Physical}>
                              OPTION 3: PHYSICAL PRIZE
                            </option>
                          </Field>
                        </div>
                      </>
                    )}
                    {!isDisable && <SpinnerButton label="Continue" />}
                    <ErrorFormDisplay
                      errors={errors}
                      isSubmitting={isSubmitting}
                      submitCount={submitCount}
                      isValid={isValid}
                    />
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      ) : (
        <span className="atcWarning">
          Please complete{" "}
          <Link to="/get-started-checklist/lets-get-started" className="link">
            Let's get started section
          </Link>{" "}
          to sign the SOW
        </span>
      )}
    </>
  );
};

export default StatementOfWork;
