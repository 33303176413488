import { User } from "../apiClient/data-contracts";
import { Auth } from "../apiClient/Auth";

export async function getUser(token: string): Promise<User> {
  const { data } = await new Auth().getGetUser({
    headers: {
      Authorization: token,
    },
  });
  
  return data;
}
