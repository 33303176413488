import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router";

import Sidebar, { SidebarItem } from "Layout/Sidebar/Sidebar";
import Content from "Layout/Content/Content";
import BrandInformation from "Components/BrandInformation/BrandInformation";
import LetsGetStarted, {
  STD_ATC_CHECKS,
  STD_SIGNED,
  DATE_THRESHOLD,
} from "Components/LetsGetStarted/LetsGetStarted";
import StatementOfWork from "Components/StatementOfWork/StatementOfWork";
import PayYourBalance from "Components/PayYourBalance/PayYourBalance";

import NavBar from "Layout/NavBar/NavBar";
import Page from "Layout/Page/Page";
import Main from "Layout/Main/Main";

import Loader from "Components/Loader/Loader";

import paths from "Paths";

import {
  getCurrentContact,
  getSecondaryContact,
} from "../../Components/BrandInformation/utils";
import { getCurrentBrandSigning } from "../../Components/StatementOfWork/utils";
import { sectionOrder } from "../../utils/nextSectionRedirection";

import { User } from "../../apiClient/data-contracts";
import { Company, PaymentStatus } from "../../DataTypes";

import { useCompany } from "../../hooks/useCompany";
import { useUser } from "../../hooks/useUser";
import CompanyContext from "../../state/contexts/company";
import UserContext from "../../state/contexts/user";

const GetStartedChecklist = (): JSX.Element => {
  const { company, setCompany } = useContext(CompanyContext);
  const { user, setUser } = useContext(UserContext);

  const companyFromApi = useCompany();
  const userFromApi = useUser();

  useEffect(() => {
    companyFromApi && setCompany(companyFromApi);
  }, [companyFromApi, setCompany]);

  useEffect(() => {
    userFromApi && setUser(userFromApi);
  }, [userFromApi, setUser]);

  if (!user || !company) {
    return <Loader />;
  }

  return (
    <Page>
      <Sidebar>
        <SidebarItem
          path={paths.brandInformation.url}
          name="Your Brand Information"
          hasCheck
          checked={isBrandInformationComplete(company, user)}
        />
        <SidebarItem
          path={paths.letsGetStarted.url}
          name="Let's Get Started"
          hasCheck
          checked={isLetsGetStartedComplete(company)}
        />
        {isPayBalanceVisible(company, user) && (
          <SidebarItem
            path={paths.payYourBalance.url}
            name="Pay Your Balance"
            hasCheck
            checked={isPayBalanceComplete(company)}
          />
        )}
      </Sidebar>
      <Content>
        <NavBar />
        <Main>
          <Routes>
            <Route path="lets-get-started" element={<LetsGetStarted />} />
            <Route path="brand-information" element={<BrandInformation />} />
            <Route path="statement-of-work" element={<StatementOfWork />} />
            <Route path="pay-your-balance" element={<PayYourBalance />} />
          </Routes>
        </Main>
      </Content>
    </Page>
  );
};

export const isBrandInformationComplete = (
  company: Company,
  user: User
): boolean => {
  const currentContact = getCurrentContact(company, user);
  const secondaryContact = getSecondaryContact(company, user);
  return (
    company &&
    user &&
    [
      !!company.name,
      !!company.street_address_1,
      !!company.city,
      !!company.state,
      !!company.zip_code,
      !!company.phone,
      !!company.url,
      !!currentContact?.first_name,
      !!currentContact?.last_name,
      !!currentContact?.position_title,
      !!currentContact?.email_address,
      currentContact?.phone_numbers.length > 0,
      !!secondaryContact?.first_name,
      !!secondaryContact?.last_name,
      !!secondaryContact?.position_title,
      !!secondaryContact?.email_address,
      secondaryContact?.phone_numbers.length > 0,
    ].every((i) => i)
  );
};

export const isLetsGetStartedComplete = (company: Company): boolean => {
  const acceptedTC =
    company?.signing_history.length > 0 ? company?.signing_history : STD_SIGNED;
  const acceptedTCList = acceptedTC.reduce(
    (acc: string[], signHistory) =>
      acc.concat(JSON.parse(signHistory.acknowledged_checks)),
    []
  );

  const availableTC = getCurrentBrandSigning(company) || STD_ATC_CHECKS;
  const availableTCList = availableTC.available_atc_checks;

  const completeATC = (): boolean => {
    if (
      Date.parse(company?.msa_signed_timestamp) < DATE_THRESHOLD &&
      company?.msa_signee_email
    )
      return true;
    return availableTCList
      .map((check) => acceptedTCList.includes(check))
      .every((i) => i);
  };

  return (
    company &&
    [
      !!company.msa_signed_timestamp,
      company.msa_signee_email,
      company.msa_version,
      completeATC(),
    ].every((i) => i)
  );
};

export const isSOWComplete = (company: Company): boolean => {
  const brandSigning = getCurrentBrandSigning(company);
  return (
    brandSigning &&
    [!!brandSigning.date_agreement, !!brandSigning.sow_signee_email].every(
      (i) => i
    )
  );
};

export const isPayBalanceComplete = (company: Company): boolean => {
  const brandSigning = getCurrentBrandSigning(company);
  if (!brandSigning) {
    return false;
  }
  return (
    !!brandSigning.sow_payment &&
    brandSigning.sow_payment.status === PaymentStatus.success
  );
};

export const isPayBalanceVisible = (company: Company, user: User): boolean => {
  const brandSigning = getCurrentBrandSigning(company);
  if (!isBrandInformationComplete(company, user)) {
    return false;
  }
  return (
    !!brandSigning &&
    !!brandSigning.sow_total_to_be_paid &&
    !!brandSigning.sow_document_full_path
  );
};

export const getStartedSectionOrder = (
  company: Company,
  user: User
): sectionOrder[] => [
  {
    section: "brandInformation",
    url: paths.brandInformation.url,
    isComplete: isBrandInformationComplete(company, user),
    isVisible: true,
  },
  {
    section: "letsGetStarted",
    url: paths.letsGetStarted.url,
    isComplete: isLetsGetStartedComplete(company),
    isVisible: true,
  },
  {
    section: "payYourBalance",
    url: paths.payYourBalance.url,
    isComplete: isPayBalanceComplete(company),
    isVisible: isPayBalanceVisible(company, user),
  },
];

export default GetStartedChecklist;
