import { useCallback } from "react";
import { Cpl } from "../../../../../../apiClient/Cpl";
import { HttpResponse } from "../../../../../../apiClient/http-client";
import { CPL, patchCplBrandPortalApi } from "../../../../../../DataTypes";
import { useAuth } from "../../../../../../state/auth";

const usePatchAssets = (
  cpl: CPL
): ((
  payload: patchCplBrandPortalApi
) => Promise<HttpResponse<CPL, unknown>>) => {
  const { loginInfo } = useAuth();

  return useCallback(
    async (payload: patchCplBrandPortalApi) =>
      new Cpl().patchCplBrandPortalApi(cpl.id, payload, {
        headers: {
          Authorization: loginInfo.token,
        },
      }),
    [cpl?.id, loginInfo?.token]
  );
};

export default usePatchAssets;
