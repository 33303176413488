import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import "./SidebarItem.scss";

interface SidebarItemProps {
  name: string;
  path: string;
  current: boolean;
}

const SidebarItem = ({
  name,
  path,
  current,
}: SidebarItemProps): JSX.Element => (
  <li>
    <Link
      to={path}
      className={clsx("side-bar__item", current && "side-bar__item--current")}
    >
      {name}
    </Link>
  </li>
);

export default SidebarItem;
