function createZendeskScript(): void {
  const script = document.createElement("script");

  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;
  script.id = "ze-snippet";

  document.body.appendChild(script);
}

export function loadZendesk(): void {
  const existingZendeskScript =
    document.querySelector<HTMLElement>("#ze-snippet");

  if (!existingZendeskScript) {
    createZendeskScript();
    return;
  }

  const zendeskWidget = document.querySelector<HTMLElement>("iframe#launcher");
  if (zendeskWidget) {
    zendeskWidget.style.visibility = "visible";
  }
}

export function unloadZendesk(): void {
  const zendeskWidget = document.querySelector<HTMLElement>("iframe#launcher");

  if (zendeskWidget) {
    zendeskWidget.style.visibility = "hidden";
  }
}
