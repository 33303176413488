/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */

import { useContext, useMemo } from "react";
import CompanyContext from "../../../state/contexts/company";
import { getCurrentBrandSigning } from "../../StatementOfWork/utils";
import { isDefined } from "../../../utils";
import { SigningType } from "../../../DataTypes/signingType";
import assertNever from "assert-never";
import { BrandSigning } from "apiClient/data-contracts";
import { bpContactEmail } from "utils/constants";

interface BillingDetailsBlock {
  readonly primaryLabel: string;
  readonly secondaryLabel?: string;
  readonly price: number | null;
}

export interface BillingDetailsResponse {
  readonly blocks: BillingDetailsBlock[];
  readonly initialPaymentAmount: number;
  readonly recurringPaymentInfo?: {
    readonly price?: number;
    readonly interval?: string;
    readonly details?: string;
  };
  readonly description?: string;
}

export function billingDetails(
  brandSigning: BrandSigning
): BillingDetailsResponse {
  const type =
    (brandSigning.signing_type as SigningType) || SigningType.BOX_AUGMENTER;
  const isWithTrial = brandSigning.recurring_trial_days > 0;
  const customSweepDesc = `You will be billed after your
    ${brandSigning.recurring_trial_days}-day trial and
    ${brandSigning.recurring_frequency_text}, until your subscription is canceled.`;

  const nonCustomSweepDesc = `You will be billed after your ${brandSigning.recurring_trial_days}-day trial and
    annually thereafter, until your subscription is canceled. Your trial starts when your 1st sweepstakes is launched.`;

  switch (type) {
    case SigningType.SINGLE:
      return {
        blocks: [
          {
            primaryLabel: "Single sweepstake credit",
            price: brandSigning.sow_total_to_be_paid,
          },
        ],
        initialPaymentAmount: brandSigning.sow_total_to_be_paid,
      };
    case SigningType.SWEEP_LARGE:
    case SigningType.BOX_LARGE:
      if (isWithTrial) {
        return {
          blocks: [
            {
              primaryLabel: `${brandSigning.recurring_trial_days}-Day Trial`,
              secondaryLabel: `
              ${brandSigning.recurring_sweeps_during_trial} 
              sweepstake credit${
                brandSigning.recurring_sweeps_during_trial > 1 ? "s" : ""
              }
            `,
              price: null,
            },
            {
              primaryLabel: brandSigning.api_feed ? "API Fee" : "Sign-Up Fee",
              price: brandSigning.sow_total_to_be_paid,
            },
          ],
          initialPaymentAmount: brandSigning.sow_total_to_be_paid,
          recurringPaymentInfo: {
            // This is hardcoded right now because Yenta only uses annual frequency
            price: Math.round(brandSigning.recurring_total_to_be_paid / 12),
            interval: "month",
            details: `
            ${
              brandSigning.recurring_sweeps_per_year
            } sweepstake credits billed at 
            $${brandSigning.recurring_total_to_be_paid.toLocaleString()} ${
              brandSigning.recurring_custom_plan_enabled
                ? brandSigning.recurring_frequency_text
                : "annually"
            }
          `,
          },
          description: brandSigning.recurring_custom_plan_enabled
            ? customSweepDesc
            : nonCustomSweepDesc,
        };
      } else {
        return {
          blocks: [
            {
              primaryLabel: `
                ${brandSigning.recurring_sweeps_per_year} 
                sweepstake credit${
                  brandSigning.recurring_sweeps_per_year > 1 ? "s" : ""
                }
              `,
              price: brandSigning.sow_total_to_be_paid,
            },
          ],
          initialPaymentAmount: brandSigning.sow_total_to_be_paid,
          recurringPaymentInfo: {
            // This is hardcoded right now because Yenta only uses annual frequency
            price: Math.round(brandSigning.recurring_total_to_be_paid / 12),
            interval: "month",
            details: `
            ${
              brandSigning.recurring_sweeps_per_year
            } sweepstake credits billed at 
            $${brandSigning.recurring_total_to_be_paid.toLocaleString()} ${
              brandSigning.recurring_custom_plan_enabled
                ? brandSigning.recurring_frequency_text
                : "annually"
            }
          `,
          },
          description: brandSigning.recurring_custom_plan_enabled
            ? customSweepDesc
            : null,
        };
      }
    case SigningType.SWEEP_AUGMENTER:
    case SigningType.BOX_AUGMENTER:
      const nonCustomSweepTrialDescription = isWithTrial
        ? nonCustomSweepDesc
        : null;
      return {
        blocks: [
          {
            primaryLabel: isWithTrial
              ? `${brandSigning.recurring_trial_days}-Day Trial`
              : `
              ${brandSigning.recurring_sweeps_per_year} 
              sweepstake credit${
                brandSigning.recurring_sweeps_per_year > 1 ? "s" : ""
              }
            `,
            secondaryLabel: isWithTrial
              ? `
              ${brandSigning.recurring_sweeps_during_trial} 
              sweepstake credit${
                brandSigning.recurring_sweeps_during_trial > 1 ? "s" : ""
              }
            `
              : null,
            price: brandSigning.sow_total_to_be_paid,
          },
        ],
        initialPaymentAmount: brandSigning.sow_total_to_be_paid,
        recurringPaymentInfo: {
          // This is hardcoded right now because Yenta only uses annual frequency
          price: Math.round(brandSigning.recurring_total_to_be_paid / 12),
          interval: "month",
          details: `
            ${
              brandSigning.recurring_sweeps_per_year
            } sweepstake credits billed at 
            $${brandSigning.recurring_total_to_be_paid.toLocaleString()} ${
            brandSigning.recurring_custom_plan_enabled
              ? brandSigning.recurring_frequency_text
              : "annually"
          }
          `,
        },
        description: brandSigning.recurring_custom_plan_enabled
          ? customSweepDesc
          : nonCustomSweepTrialDescription,
      };
    case SigningType.CPL:
      const cplNoMonthlyBudgetDesc = `
        You will be billed automatically according to the terms in your SOW until your subscription is canceled.
        You can update your bid or lead count by reaching out to us at ${bpContactEmail}.`;
      const cplMonthlyBudgetDesc = `
        You will be billed the recurring total on the 1st of every month until your subscription is canceled.
        You can update your bid or lead count by reaching out to us at ${bpContactEmail}.
      `;

      const description = brandSigning.has_monthly_spending_limit
        ? cplMonthlyBudgetDesc
        : cplNoMonthlyBudgetDesc;

      if (brandSigning.free_trial) {
        return {
          blocks: [
            {
              primaryLabel: "CPL Trial",
              secondaryLabel: `${brandSigning.free_trial_total_optins} free leads`,
              price: null,
            },
            {
              primaryLabel: brandSigning.api_feed ? "API Fee" : "Sign-Up Fee",
              price: brandSigning.sow_total_to_be_paid,
            },
          ],
          initialPaymentAmount: brandSigning.sow_total_to_be_paid,
          recurringPaymentInfo: {
            details: `
              $${brandSigning.total_cost} / 
              ${brandSigning.total_optins} leads at 
              $${brandSigning.bid_per_lead} per lead
            `,
          },
          description: description,
        };
      } else {
        return {
          blocks: [
            {
              primaryLabel: `${brandSigning.total_optins} leads`,
              secondaryLabel: `$${brandSigning.bid_per_lead} bid per lead`,
              price: brandSigning.total_cost,
            },
          ],
          initialPaymentAmount: brandSigning.total_cost,
          description: description,
        };
      }
    default:
      assertNever(type);
  }
}

export function useBillingDetailsState(): BillingDetailsResponse | null {
  const { company } = useContext(CompanyContext);
  const brandSigning = useMemo(() => {
    return getCurrentBrandSigning(company);
  }, [company]);

  if (!isDefined(brandSigning)) {
    return null;
  }

  return billingDetails(brandSigning);
}
