import React, { ReactNode, useState } from "react";
import { SweepReport } from "../../apiClient/data-contracts";

import ReportContext from "../contexts/report";

interface ReportProviderProps {
  children?: ReactNode;
}

const ReportProvider = ({ children }: ReportProviderProps): JSX.Element => {
  const [sweepReport, setSweepReport] = useState<SweepReport>(null);
  return (
    <ReportContext.Provider value={{ sweepReport, setSweepReport }}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
