import React, { useCallback, useContext, useState } from "react";

import CplContext from "../../../../../state/contexts/cpl";
import { EspPayload } from "../../../../../DataTypes";
import { Cpl } from "../../../../../apiClient/Cpl";
import { useLoading } from "../../../../../state/loader";
import { useAuth } from "../../../../../state/auth";
import { CustomError, handleError } from "../../../../../utils/errorHandler";
import Loader from "../../../../../Components/Loader/Loader";
import { isESPComplete } from "../../../../CostPerLead/utils";
import EspForm from "../../../../../Components/Esp/Form";
import { bpContactEmail } from "../../../../../utils/constants";
import { errorToast } from "../../../../../utils/toast";
import "./CplEsp.scss";

const CplEsp = (): JSX.Element => {
  const { cpl, setCpl } = useContext(CplContext);
  const { loading, setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [err, setErr] = useState("Error");
  const [supportedEsp, setSupportedESP] = useState(false);

  const onSubmit = useCallback(
    async (values: EspPayload) => {
      try {
        setLoading(true);
        const cplClient = new Cpl();
        const { data } = await cplClient.patchEspApi(
          cpl.id,
          { ...values, esp_integration_enable: true },
          {
            headers: {
              Authorization: loginInfo.token,
            },
          }
        );
        setSubmitted(true);
        setCpl(data);
        setValid(true);

        if (data.esp_supported) {
          setSupportedESP(true);
        }
      } catch (error) {
        const customError = error as CustomError;
        if (customError.status === 417) {
          setErr(handleError(customError).message);
          setSubmitted(true);
          setValid(false);
        } else {
          errorToast(handleError(customError).message);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      loginInfo,
      cpl,
      setCpl,
      setLoading,
      setSubmitted,
      setValid,
      setSupportedESP,
    ]
  );

  return !loading && cpl ? (
    <div className="cpl-esp-container">
      <p className="section__paragraph--primary">
        Use our easy API integration to set up a real-time sync of your CPL
        entries to your email provider. Immediate email sync and welcome flows
        are proven to increase conversion and open rates.
      </p>
      <p className="section__paragraph--primary">
        {`For help, please reach to us at ${bpContactEmail}`}
      </p>
      <EspForm
        onSubmit={onSubmit}
        dataSource={cpl}
        isComplete={isESPComplete}
        valid={valid}
        err={err}
        supportedEsp={supportedEsp}
        submitted={submitted}
        newUI={true}
      />
      <p className="section__paragraph--primary">
        If you have any questions, contact us via live chat or at{" "}
        <a href={`mailto:${bpContactEmail}`}>{bpContactEmail}</a>
      </p>
    </div>
  ) : (
    <Loader />
  );
};

export default CplEsp;
