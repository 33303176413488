import * as yup from "yup";
import productImagesSchema from "./ProductImages/validationSchema";
import videoValidationSchema from "./VideoInput/validationSchema";

export const maxLength = {
  short_brand_description: 110,
  brand_description: 350,
};

const validationSchema = yup.object().shape({
  short_brand_description: yup
    .string()
    .required("Brand short description is required")
    .max(
      maxLength.short_brand_description,
      `Your description can't exceed ${maxLength.short_brand_description} characters.`
    ),
  brand_description: yup
    .string()
    .required("Brand description is required")
    .max(
      maxLength.brand_description,
      `Your description can't exceed ${maxLength.brand_description} characters.`
    ),
  tagline: yup.string().required("Tagline is required"),
  logo: yup.object().required("Logo is required"),
  offer_image: yup.object().required("Offer image is required"),
  product_images: productImagesSchema,
  ...videoValidationSchema,
});

export default validationSchema;
