import React, { ReactNode, useContext, useState, createContext } from "react";
import { History } from "history";

import AuthStore, { BareLoginInformation } from "../store/AuthStore";

export interface LoginInformation extends BareLoginInformation {
  hasExpired: () => boolean;
  hasValidLogin: () => boolean;
}

const fromBareLoginInfo = (info: BareLoginInformation): LoginInformation => {
  const hasExpired = () =>
    info.expiresIn != null && new Date() > info.expiresIn;
  return {
    ...info,
    hasExpired,
    hasValidLogin: () => info.token != null && !hasExpired(),
  };
};

type LoginInfo = {
  access_token: string;
  expires_in: number;
  history: History;
  setLoginInfo: (info: LoginInformation) => void;
  redirectionRoute: string;
};

export const login = ({ access_token, expires_in, setLoginInfo }: Partial<LoginInfo>): void => {
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + expires_in);
  const bareLoginInfo = {
    token: access_token,
    expiresIn: expirationDate,
  };
  AuthStore.setLoginInfo(bareLoginInfo);
  setLoginInfo(fromBareLoginInfo(bareLoginInfo));
};

export interface AuthProvided {
  loginInfo: LoginInformation;
  setLoginInfo: (loginInfo: LoginInformation) => void;
  removeLoginInfo: () => void;
}

const emptyLoginInfo: BareLoginInformation = { token: null, expiresIn: null };
const init: AuthProvided = {
  loginInfo: fromBareLoginInfo(emptyLoginInfo),
  setLoginInfo: null,
  removeLoginInfo: null,
};

const AuthContext = createContext(init);

const ProvideAuth = ({ children }: { children?: ReactNode }): JSX.Element => {
  const bareLoginInfo = AuthStore.getLoginInfo();
  const defaultLoginInfo = fromBareLoginInfo(bareLoginInfo || emptyLoginInfo);
  const [loginInfo, setLoginInfo] =
    useState<LoginInformation>(defaultLoginInfo);

  const removeLoginInfo = () => {
    setLoginInfo(fromBareLoginInfo(emptyLoginInfo));
    AuthStore.removeLoginInfo();
  };

  if (defaultLoginInfo.hasExpired()) {
    removeLoginInfo();
  }

  return (
    <AuthContext.Provider value={{ loginInfo, setLoginInfo, removeLoginInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthProvided {
  return useContext(AuthContext);
}

export { AuthContext, ProvideAuth, useAuth, fromBareLoginInfo };
