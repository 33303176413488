import { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";

/**
 * A component creating a side-effect to focus on the first error in the form.
 * @see https://github.com/jaredpalmer/formik/issues/146#issuecomment-1016975788
 */
const FocusFormikError: FC = () => {
  const { errors, isSubmitting, submitCount } = useFormikContext();
  const [count, setCount] = useState<number>(submitCount);

  useEffect(() => {
    if (count === submitCount) {
      return;
    }

    setCount(submitCount);
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      setTimeout(() => {
        const input = document.getElementsByName(errorKeys?.[0])?.[0];
        if (input && input.scrollIntoView && input.focus) {
          input.scrollIntoView({ behavior: "smooth", block: "center" });

          setTimeout(() => {
            input.focus();
          }, 500);
        }
      }, 10);
    }
  }, [errors, isSubmitting, submitCount, count, setCount]);

  return null;
};

export default FocusFormikError;
