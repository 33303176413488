import { Payment } from "../apiClient/data-contracts";
import { Payments } from "../apiClient/Payment";

export async function getBrandSigningLastPayment(
  token: string,
  brand_signing_id: number
): Promise<Payment> {
  const { data } = await new Payments().getBrandSigningPaymentApi(
    brand_signing_id,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return data.payments?.length ? data.payments[0] : null;
}
