import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import "./Header.scss";
import logo from "../../assets/images/brand-portal-logo-subtitle.png";
import { useAuth } from "../../state/auth";

import { Auth } from "../../apiClient/Auth";

import paths from "../../Paths";
import { CustomError, handleError } from "../../utils/errorHandler";

export interface Props {
  externalLink?: ReactNode;
}

const authClient = new Auth();

const Header = ({ externalLink }: Props): JSX.Element => {
  const { loginInfo, removeLoginInfo } = useAuth();

  const handleLogout = async () => {
    try {
      authClient.postLogoutUser({
        headers: { Authorization: loginInfo.token },
      });
      removeLoginInfo();
    } catch (error) {
      console.error(handleError(error as CustomError).message);
    }
  };

  return (
    <header className="header">
      <div className="header__text" />
      <Link to={paths.home.url}>
        <img className="header__image" src={logo} alt="brand portal logo" />
      </Link>
      {externalLink || (
        <button onClick={handleLogout} className="header__logout">
          Log Out
        </button>
      )}
    </header>
  );
};

export default Header;
