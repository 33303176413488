import "./Form.scss";

import React, { useState, useContext, useEffect, useCallback } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

import validationSchema from "./validation";
import TermCondCheckbox from "./CheckboxComponent";
import SpinnerButton from "../SpinnerButton/SpinnerButton";

import CompanyContext from "../../state/contexts/company";
import { useAuth } from "../../state/auth";
import { useLoading } from "../../state/loader";
import { Companies } from "../../apiClient/Company";
import {
  isLetsGetStartedComplete,
  getStartedSectionOrder,
} from "../../pages/GetStartedCheckList/GetStartedChecklist";
import { useCompany } from "../../hooks/useCompany";
import { useUser } from "../../hooks/useUser";
import nextIncompleteSection from "../../utils/nextSectionRedirection";
import { BrandSigning, Company, SigningHistory } from "../../DataTypes";
import { getCurrentBrandSigning } from "../StatementOfWork/utils";
import { CustomError, handleError } from "../../utils/errorHandler";
import { errorToast } from "../../utils/toast";

interface TCType {
  [value: string]: boolean;
}
const STD_LABELS: string[] = [];

export const STD_SIGNED: SigningHistory[] = [
  {
    acknowledged_checks: JSON.stringify(STD_LABELS),
  },
];

export const STD_ATC_CHECKS: BrandSigning = {
  available_atc_checks: STD_LABELS,
};

export const DATE_THRESHOLD: number = Date.parse("2021-07-22 00:00:00");

const allChecksAccepted = (values: TCType, totalVals: TCType): boolean => {
  // -1 removes terms_conditions key not included in ATC from API
  const lenVals = Object.keys(values).length - 1;
  const totalLenVal = Object.values(totalVals).filter((check) => !check).length;

  return lenVals > 0 && lenVals === totalLenVal
    ? Object.values(values).every((value) => value)
    : false;
};

const atcPreviousSigned = (company: Company, ATC: SigningHistory[]) =>
  Date.parse(company?.msa_signed_timestamp) < DATE_THRESHOLD &&
  company?.msa_signee_email &&
  ATC.length === 0;

const getAvailableATC = (company: Company): TCType => {
  const bs = getCurrentBrandSigning(company) || STD_ATC_CHECKS;
  return bs.available_atc_checks.reduce((ac, a) => ({ ...ac, [a]: false }), {});
};

const getAcceptedTC = (company: Company): TCType => {
  const ATC = company?.signing_history || [];

  const listATC = ATC.reduce(
    (acc: string[], signHistory) =>
      acc.concat(JSON.parse(signHistory.acknowledged_checks)),
    []
  );

  if (listATC.length > 0) {
    return listATC.reduce((acc, atc) => ({ ...acc, [atc]: true }), {});
  }

  if (atcPreviousSigned(company, ATC)) {
    const modifiedATC = getAvailableATC(company);
    Object.keys(modifiedATC).forEach(function (key) {
      modifiedATC[key] = true;
    });
    return modifiedATC;
  }
  return {};
};

const getNotAccepted = (initialValues: TCType): string[] => {
  const identifiers = Object.keys(initialValues);
  return identifiers.filter((id) => !initialValues[id]);
};

const LetsGetStarted = (): JSX.Element => {
  const INITIAL_TICK = 0;
  const [agreementTermsCondDownloaded, setAgreementTermsCondDownloaded] =
    useState<boolean>(false);
  const [tick, setTick] = useState<number>(INITIAL_TICK);

  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const { company, setCompany } = useContext(CompanyContext);

  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [allChecked, setAllChecked] = useState<boolean>(isDisable);

  const companyFromApi = useCompany(tick);
  const user = useUser();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<TCType>({});

  useEffect(() => {
    companyFromApi && setCompany(companyFromApi);
  }, [companyFromApi, setCompany]);

  useEffect(() => {
    setIsDisable(isLetsGetStartedComplete(company));
  }, [company]);

  useEffect(() => {
    setInitialValues({
      ...getAvailableATC(company),
      ...getAcceptedTC(company),
    });
  }, [company]);

  const onSubmit = async () => {
    const companyClient = new Companies();
    try {
      setLoading(true);
      await companyClient.postCompanyMsaSigningApi(
        company.id,
        {
          is_downloaded: agreementTermsCondDownloaded,
          acknowledged_checks: JSON.stringify(getNotAccepted(initialValues)),
        },
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      );
      setTick(tick + 1);
      alert("Data Saved!");
      nextIncompleteSection(navigate, getStartedSectionOrder(company, user));
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="heading-primary heading__font">Let's Get Started</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <Form>
                <div className="container">
                  <p>On behalf of {company && company.name}, you agree:</p>
                  <FormChecklist
                    isDisable={isDisable}
                    setAllChecked={setAllChecked}
                    availableAtcChecks={initialValues}
                  />
                </div>
                {(allChecked || isDisable) && (
                  <div className="terms">
                    <input
                      type="checkbox"
                      className="spacing-checkbox"
                      name="terms_conditions"
                      id="terms_conditions"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFieldValue(
                          "terms_conditions",
                          event.currentTarget.checked
                        );
                      }}
                      disabled={isDisable}
                      defaultChecked={isDisable}
                    />
                    <label htmlFor="terms_conditions">
                      By Clicking the box, you agree to the FindKeep.Love{" "}
                      <a
                        className="terms-download"
                        href={company && company.msa_document_full_path}
                        onClick={() => setAgreementTermsCondDownloaded(true)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Advertiser Terms &amp; Conditions
                      </a>
                    </label>
                  </div>
                )}
                {!isDisable && (
                  <>
                    <p className="note">
                      To proceed, please check the box next to each statement to
                      confirm your acknowledgement
                    </p>
                    {allChecksAccepted(values, initialValues) && (
                      <SpinnerButton label="Continue" />
                    )}
                  </>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

type Props = {
  isDisable: boolean;
  setAllChecked: (value: boolean) => void;
  availableAtcChecks: TCType;
};

type acceptedTermsType = {
  [tc: string]: { [name: string]: boolean };
};

const FormChecklist = ({
  isDisable,
  setAllChecked,
  availableAtcChecks,
}: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [acceptedTerms, setAcceptedTerms] = useState<acceptedTermsType>({
    tc: {},
  });
  const [checkList, setCheckList] = useState([]);

  const memoizedHandleStateChanges = useCallback(() => {
    const handleStateChanges = (idx: number, atcState: boolean) => {
      const items = { tc: acceptedTerms.tc };
      const item = items.tc;
      item[idx] = atcState;
      setAcceptedTerms(items);
    };

    setCheckList(
      Object.entries(availableAtcChecks).map(([tc, value], idx) => ({
        name: tc,
        key: idx,
        label: tc,
        isVisible: true,
        isCompleted: value,
        handler: handleStateChanges,
      }))
    );
  }, [availableAtcChecks, acceptedTerms]);

  const memoizedSetAllChecked = useCallback(() => {
    const atcValues = Object.values(acceptedTerms.tc);

    setAllChecked(
      atcValues.length > 0 &&
        atcValues.length ===
          Object.values(checkList).filter((check) => !check.isCompleted).length
        ? atcValues.every((x) => x)
        : false
    );
  }, [acceptedTerms, checkList, setAllChecked]);

  useEffect(() => {
    memoizedHandleStateChanges();
  }, [memoizedHandleStateChanges]);

  useEffect(() => {
    memoizedSetAllChecked();
  }, [memoizedSetAllChecked]);

  return (
    <>
      {checkList.map((item, idx) => {
        return (
          <TermCondCheckbox
            key={idx}
            item={item}
            isDisable={isDisable}
            setFieldValue={setFieldValue}
          />
        );
      })}
    </>
  );
};

export default LetsGetStarted;
