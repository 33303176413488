import React, { ChangeEvent, FC } from "react";
import { Field as FormikField, FieldProps } from "formik";

import Field, { InputProps } from "../Field/Field";
import clsx from "clsx";

export type FileInputProps = Omit<InputProps, "type" | "maxLength"> & {
  accept?: string;
  multiple?: boolean;
  hideInput?: boolean;
};

const FileInput: FC<FileInputProps> = ({
  name,
  accept,
  multiple,
  hideInput,
  children,
  ...fieldProps
}) => {
  return (
    <Field name={name} {...fieldProps}>
      <FormikField name={name}>
        {(props: FieldProps<File | FileList>) => (
          <>
            {children}
            {hideInput ? (
              <input type="hidden" name={name} id={name} />
            ) : (
              <input
                type="file"
                multiple={multiple}
                name={name}
                id={name}
                accept={accept}
                className={clsx(
                  "form__input",
                  !!props.meta.error &&
                    !!props.meta.touched &&
                    "custom-field__input--error"
                )}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const target = event?.target as HTMLInputElement;
                  const file = target?.files?.length > 0 && target.files[0];
                  if (file) {
                    props.form.setFieldValue(
                      props.field.name,
                      multiple ? target?.files : file
                    );
                  }
                }}
                data-testid="file-input"
              />
            )}
          </>
        )}
      </FormikField>
    </Field>
  );
};

export default FileInput;
