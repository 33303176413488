import "../../sass/formsStyles.scss";

import React from "react";

type Props = {
  errors: Record<string, unknown>;
  className?: string;
  submitCount: number;
  isSubmitting: boolean;
  isValid?: boolean;
};

const ErrorFormDisplay = ({
  errors,
  className,
  submitCount,
  isSubmitting,
  isValid
}: Props): JSX.Element => {
  return (
    submitCount > 0 &&
    !isSubmitting &&
    !isValid && (
      <>
        {Object.keys(errors).length > 0 && (
          <div className={`${className} form-question__error`}>
            {" "}
            Error(s) found. Please correct and try again.
          </div>
        )}
      </>
    )
  );
};
export default ErrorFormDisplay;
