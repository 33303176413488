import { OfferType } from "./offerType";
import { CPL as BaseCpl } from "../apiClient/data-contracts";
import { IntegrationProvider, EspName, Company } from "./";

export interface CPL extends Omit<BaseCpl, "company"> {
  api_setup?: IntegrationProvider<EspName>;
  company?: Company;
}

export enum cplStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  SIGNED = 2,
  IN_PRODUCTION = 3,
  IN_CREATION = 5,
  OUT_OF_SPEND = 7,
}

export interface patchCplPayload {
  esp_integration_enable: boolean;
  esp_selected?: EspName;
  "Moosend-api_key"?: string;
  "Moosend-mail_list_id"?: string;
  "Klaviyo-api_key"?: string;
  "Klaviyo-member_list_id"?: string;
  "Klaviyo-single_optin"?: boolean;
  "Salesforce-subdomain"?: string;
  "Salesforce-client_id"?: string;
  "Salesforce-client_secret"?: string;
  "Salesforce-data_extension_key"?: string;
  "Bronto-api_token"?: string;
  "Bronto-contact_list_id"?: string;
  "Sailthru-api_key"?: string;
  "Sailthru-secret_key"?: string;
  "Sailthru-list_name"?: string;
  "Cordial-api_key"?: string;
  "Cordial-contact_list_name"?: string;
  "Mailchimp-audience_list"?: string;
  "Mailchimp-tag"?: string;
  "Mailchimp-api_key"?: string;
  "Mailchimp-server_prefix"?: string;
  "RetentionScience-api_key"?: string;
  "RetentionScience-source_sweeps"?: string;
  "RetentionScience-source_onsite"?: string;
  "Omnisend-api_key"?: string;
  "Omnisend-tags_sweeps"?: string;
  "Omnisend-tags_onsite"?: string;
  "Listrak-login"?: string;
  "Listrak-password"?: string;
  "Listrak-listname_sweeps"?: string;
  "Listrak-listname_onsite"?: string;
  "ConstantContact-login"?: string;
  "ConstantContact-password"?: string;
  "ConstantContact-listname_sweeps"?: string;
  "ConstantContact-listname_onsite"?: string;
  "Hubspot-login"?: string;
  "Hubspot-password"?: string;
  "Hubspot-listname_sweeps"?: string;
  "Hubspot-listname_onsite"?: string;
  "Remarkety-api_key"?: string;
  "Remarkety-store_id"?: string;
  "Remarkety-tags_sweeps"?: string;
  "Remarkety-tags_onsite"?: string;
  "ActiveCampaign-api_url"?: string;
  "ActiveCampaign-api_key"?: string;
  "ActiveCampaign-listname_sweeps"?: string;
  "ActiveCampaign-listname_onsite"?: string;
  "Drip-api_token"?: string;
  "Drip-listname_sweeps"?: string;
  "Drip-listname_onsite"?: string;
  "Drip-tags_sweeps"?: string;
  "Drip-tags_onsite"?: string;
  "Other-details"?: string;
}

export interface patchCplBrandPortalApi {
  offer_type?: OfferType;
  free_gift?: string;
  free_gift_min_purchase_qualifications?: number;
  promo_code?: string;
  expiration?: string;
  off_amount?: number;
  off_amount_min_purchase_amount?: number;
  exclusions_notes?: string;
  short_brand_description?: string;
  brand_description?: string;
  tagline?: string;
  image?: File;
  no_cpl_videos?: boolean;
}

export type Field =
  | "lifestyle_images"
  | "product_shot_images"
  | "cpl_videos"
  | "offer_images";

export enum cplAssetFieldType {
  LIFESTYLE_IMAGES = "lifestyle_images",
  PRODUCT_SHOT_IMAGES = "product_shot_images",
  CPL_VIDEOS = "cpl_videos",
}

export interface postAssetsUploadPayload {
  filename: File;
  field: Field;
  description?: string;
  title?: string;
}

export interface putAssetsUploadPayload {
  filename: File;
  field: Field;
  description?: string;
  title?: string;
  asset_id: number;
}

export interface deleteAssetsUploadPayload {
  field: Field;
  asset_id: number;
  title?: string;
}
