import React from "react";

import {
  initialValuesCreditCard,
  validationSchemaCreditCard,
  initialValuesGiftCard,
  validationSchemaGiftCard,
  initialValuesPhysicalPrize,
  validationSchemaPhysicalPrize,
} from "./validations";
import { Slot, SlotPayload } from "../../DataTypes";

import { Formik, Form, Field, ErrorMessage } from "formik";
import SpinnerButton from "../SpinnerButton/SpinnerButton";

type Props = {
  onSubmit: (values: SlotPayload) => void;
  slot: Slot;
  disabled: boolean;
};

export const CreditCardForm = ({
  onSubmit,
  slot,
  disabled,
}: Props): JSX.Element => {
  return (
    <>
      <h3 className="heading-tertiary">OPTION 2: VISA OR AMEX GIFT CARD</h3>
      <p className="paragraph-secondary">
        If you cannot provide a gift card to your site you can contribute a VISA
        or Amex gift card. A 25% processing fee will apply. These gift cards
        must be purchased by the FindKeep.Love team. Gift card and 25% fee will
        be invoiced prior to service commencing.
      </p>
      <Formik
        initialValues={initialValuesCreditCard(slot)}
        onSubmit={onSubmit}
        validationSchema={validationSchemaCreditCard}
      >
        {() => {
          return (
            <Form>
              <div className="form-field">
                <label>VISA/Amex gift card value (min. requirement $400)</label>
                <Field
                  type="number"
                  as="input"
                  name="sweeps_prize_value"
                  className="form__input"
                  disabled={disabled}
                />
                <ErrorMessage
                  name="sweeps_prize_value"
                  component="div"
                  className="form__control--error"
                />
              </div>
              {!disabled && <SpinnerButton label="Submit" />}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const GiftCardForm = ({
  onSubmit,
  slot,
  disabled,
}: Props): JSX.Element => {
  return (
    <>
      <h3 className="heading-tertiary">
        OPTION 1: DIGITAL GIFT CARD (STRONGLY RECOMMENDED)
      </h3>
      <p className="paragraph-secondary">
        FindKeep.Love recommends a digital gift card to your site.
      </p>
      <Formik
        initialValues={initialValuesGiftCard(slot)}
        onSubmit={onSubmit}
        validationSchema={validationSchemaGiftCard}
      >
        {() => {
          return (
            <Form>
              <div className="form-field">
                <label>Prize Description</label>
                <Field
                  as="textarea"
                  name="sweeps_prize_description"
                  className="form__input"
                  disabled={disabled}
                />
                <ErrorMessage
                  name="sweeps_prize_description"
                  component="div"
                  className="form__control--error"
                />
              </div>
              <div className="form-field">
                <label>Prize Code</label>
                <Field
                  as="input"
                  name="sweeps_prize_code"
                  className="form__input"
                  disabled={disabled}
                />
                <ErrorMessage
                  name="sweeps_prize_code"
                  component="div"
                  className="form__control--error"
                />
              </div>
              <div className="form-field">
                <label>
                  Prize Value of Digital Gift Card (min. requirement $400)
                </label>
                <Field
                  type="number"
                  as="input"
                  name="sweeps_prize_value"
                  className="form__input"
                  disabled={disabled}
                />
                <ErrorMessage
                  name="sweeps_prize_value"
                  component="div"
                  className="form__control--error"
                />
              </div>
              {!disabled && <SpinnerButton label="Submit" />}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const PhysicalForm = ({
  onSubmit,
  slot,
  disabled,
}: Props): JSX.Element => {
  return (
    <>
      <h3 className="heading-tertiary">
        OPTION 3: PHYSICAL PRIZE (NOT RECOMMENDED)
      </h3>
      <p className="paragraph-secondary">
        If providing a physical prize, FindKeep.Love must receive product(s) at
        least 2 weeks prior to sweepstakes launch date. You will be charged a
        min. upfront $100 fee for FindKeep.Love to package and ship product(s)
        to winner. If shipping is {">$100"}, you will be billed the difference.
      </p>
      <Formik
        initialValues={initialValuesPhysicalPrize(slot)}
        onSubmit={onSubmit}
        validationSchema={validationSchemaPhysicalPrize}
      >
        {() => {
          return (
            <Form>
              <div className="form-field">
                <label>Physical Prize Quantities and description</label>
                <Field
                  as="textarea"
                  name="sweeps_prize_description"
                  className="form__input"
                  disabled={disabled}
                />
                <ErrorMessage
                  name="sweeps_prize_description"
                  component="div"
                  className="form__control--error"
                />
              </div>
              <div className="form-field">
                <label>
                  Total Product(s) Prize Value (min. requirement $400)
                </label>
                <Field
                  type="number"
                  as="input"
                  name="sweeps_prize_value"
                  className="form__input"
                  disabled={disabled}
                  min={400}
                />
                <ErrorMessage
                  name="sweeps_prize_value"
                  component="div"
                  className="form__control--error"
                />
              </div>
              {!disabled && <SpinnerButton label="Submit" />}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
