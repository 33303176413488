import React, { useContext } from "react";

import WarningIcon from "!file-loader!../../../../assets/images/warning.svg";
import CheckoutContext from "../../../../state/contexts/checkout";
import { ProcessPaymentState } from "../../../../DataTypes";
import { bpContactEmail } from "utils/constants";

const PaymentError = (): JSX.Element => {
  const { setPaymentState } = useContext(CheckoutContext);

  return (
    <>
      <h1 className="onboarding-title">Looks like something went wrong...</h1>
      <div className="payment-status">
        <img src={WarningIcon} alt="warning"/>
        <span className="onboarding-paragraph">There has been an error with your payment</span>
      </div>
      <button
        className="onboarding-button greyed-out"
        onClick={() => setPaymentState(ProcessPaymentState.NotProcessing)}
      >
        Try Again
      </button>
      <p className="onboarding-info">
        {`If you need help, please contact your FindKeep.Love account manager or reach out to us at ${bpContactEmail}`}
      </p>
    </>
  );
};

export default PaymentError;
