import React, { FC, ReactNode } from "react";
import { Field as FormikField, FieldProps } from "formik";
import TextArea from "../../TextArea/TextArea";
import "./Field.scss";
import clsx from "clsx";
import FieldLabel from "../FieldLabel/FieldLabel";

export interface InputProps {
  label: ReactNode;
  instructions?: ReactNode;
  description?: ReactNode;
  name: string;
  type?: "text" | "password" | "number" | "textarea";
  maxLength?: number;
  placeholder?: string;
  secondary?: boolean;
  showErrorsImmediately?: boolean;
}

const Field: FC<InputProps> = ({
  label,
  description,
  instructions,
  name,
  type,
  maxLength = 10,
  secondary,
  placeholder,
  children,
  showErrorsImmediately,
}) => (
  <div
    data-testid="field"
    className={clsx("custom-field", secondary && "custom-field--secondary")}
  >
    <FieldLabel htmlFor={name} secondary={secondary}>
      {label}
    </FieldLabel>
    <div>{instructions}</div>
    {type === "textarea" && (
      <FormikField name={name}>
        {(props: FieldProps<string>) => (
          <TextArea
            id={name}
            maxLength={maxLength}
            placeholder={placeholder}
            hasError={!!props.meta.error && !!props.meta.touched}
            {...props.field}
          />
        )}
      </FormikField>
    )}
    {(type === "text" || type === "password" || type === "number") && (
      <FormikField name={name}>
        {(props: FieldProps<string>) => (
          <input
            id={name}
            placeholder={placeholder}
            {...props.field}
            className={clsx(
              "custom-field__input",
              !!props.meta.error &&
                !!props.meta.touched &&
                "custom-field__input--error"
            )}
          />
        )}
      </FormikField>
    )}
    {children}

    <FormikField name={name}>
      {(props: FieldProps) =>
        props?.meta?.error &&
        (props?.form.submitCount > 0 || showErrorsImmediately) ? (
          <div className="custom-field__validation-message">
            {props?.meta?.error}
          </div>
        ) : null
      }
    </FormikField>
    <div>{description}</div>
  </div>
);

export default Field;
