import React, { FC } from "react";
import clsx from "clsx";
import "./Heading.scss";

type HeadingType = "primary";

interface HeadingProps {
  type: HeadingType;
  customClassName?: string[];
}

const Heading: FC<HeadingProps> = ({
  type,
  customClassName,
  children,
}): JSX.Element => {
  return (
    <h1
      className={clsx(
        type === "primary" && "heading--primary",
        customClassName
      )}
    >
      {children}
    </h1>
  );
};

export default Heading;
