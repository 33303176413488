import React, { FC } from "react";
import { Toaster } from "react-hot-toast";

const toastOptions = {
  containerStyle: {
    top: "2rem",
  },
  toastOptions: {
    duration: Infinity,
    style: { minWidth: "20rem" },
  },
};

const ToasterProvider: FC = ({ children }) => (
  <>
    <Toaster {...toastOptions} />
    {children}
  </>
);

export default ToasterProvider;
