import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import SidebarItem from "./SidebarItem/SidebarItem";
import paths from "../../../../Paths";

import "./Sidebar.scss";
import CplContext from "state/contexts/cpl";

// TODO: these should replace the existing site url mapping
// for now, they redirect to the first page in the map
const routes = [
  { path: paths.home.url, name: "Home" },
  { path: paths.costPerLeadV2.url, name: "Advertising" },
  { path: paths.myAccount.url, name: "Account" },
  { path: paths.sponsorChecklist.url, name: "Sponsor Checklist" },
  { path: paths.currentSweepstakes.url, name: "Reports" },
];

const Sidebar = (): JSX.Element => {
  const location = useLocation();
  const { cpl } = useContext(CplContext);

  const routesRequiringCPL = [paths.costPerLeadV2.url];

  return (
    <ul className="sidebar-v2">
      {routes
        .filter((route) =>
          routesRequiringCPL.includes(route.path) ? !!cpl : true
        )
        .map((route) => {
          const current = location.pathname.startsWith(route.path);
          return (
            <SidebarItem
              key={route.path}
              name={route.name}
              path={route.path}
              current={current}
            />
          );
        })}
    </ul>
  );
};

export default Sidebar;
