import React, { ComponentProps, FC } from "react";
import { useLocation } from "react-router";
import StepItem from "./StepItem/StepItem";
import "./Steps.scss";

interface StepsProps {
  items: Array<{
    name: ComponentProps<typeof StepItem>["title"];
    path: ComponentProps<typeof StepItem>["path"];
    complete?: boolean;
  }>;
}

const Steps: FC<StepsProps> = ({ items }) => {
  const location = useLocation();
  const activeItem = items.findIndex((item) =>
    location.pathname.includes(item.path)
  );

  return (
    <nav className="steps">
      <ul>
        {items.map(({ name, path, complete }, index) => {
          // Allow to click only:
          //   - on complete steps
          //   - on steps before active
          //   - on steps directly after complete steps
          const route =
            complete || index < activeItem || items?.[index - 1]?.complete
              ? path
              : undefined;

          return (
            <StepItem
              key={path}
              path={route}
              complete={complete}
              active={index === activeItem}
              title={name}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Steps;
