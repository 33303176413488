import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "../utils/PrivateRoute";
import PaymentReminder from "../Components/PaymentReminder/PaymentReminder";

import Login from "./Login/Login";
import ResetPassword from "./ResetPassword/ResetPassword";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import { FeatureFlagsDashboard } from "../utils/featureFlags";
import HomePage from "./HomePage/HomePage";
import GetStartedCheckList from "./GetStartedCheckList/GetStartedChecklist";
import SponsorChecklist from "./SponsorChecklist/SponsorChecklist";
import CostPerLead from "./CostPerLead/CostPerLead";
import CostPerLeadV2 from "./v2/CostPerLead/CostPerLead";
import Reports from "./Reports/Reports";
import Onboarding from "./Onboarding/Onboarding";

import paths from "../Paths";
import MyAccount from "./v2/MyAccount/MyAccount";

const Pages: FC = () => {
  return (
    <Routes>
      <Route path={paths.login.url} element={<Login />} />

      <Route path={paths.resetPassword.url} element={<ResetPassword />} />
      <Route path={paths.forgotPassword.url} element={<ForgotPassword />} />

      <Route
        path={paths.featureFlags.url}
        element={<FeatureFlagsDashboard />}
      />
      <Route path={paths.root.url} element={<Navigate to={paths.home.url} />} />

      <Route
        path={`${paths.onboarding.url}/*`}
        element={
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        }
      />

      <Route
        path="*"
        element={
          <PrivateRoute>
            <PaymentReminder />
            <Routes>
              <Route path={paths.home.url} element={<HomePage />} />
              <Route
                path={`${paths.getStartedCheckList.url}/*`}
                element={<GetStartedCheckList />}
              />
              <Route
                path={`${paths.sponsorChecklist.url}/*`}
                element={<SponsorChecklist />}
              />
              {/* TODO: <Route path="*" element={<>404 Not found</>} /> */}
            </Routes>
          </PrivateRoute>
        }
      />

      <Route
        path={`${paths.costPerLead.url}/*`}
        element={
          <PrivateRoute>
            <PaymentReminder />
            <CostPerLead />
          </PrivateRoute>
        }
      />

      <Route
        path={`${paths.reports.url}/*`}
        element={
          <PrivateRoute>
            <PaymentReminder />
            <Reports />
          </PrivateRoute>
        }
      />

      <Route
        path="v2/cpl/*"
        element={
          <PrivateRoute>
            <PaymentReminder />
            <CostPerLeadV2 />
          </PrivateRoute>
        }
      />
      <Route
        path={paths.myAccount.url}
        element={
          <PrivateRoute>
            <PaymentReminder />
            <MyAccount />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default Pages;
