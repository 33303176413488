/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { User } from "./data-contracts";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Auth extends HttpClient {
  /**
   * No description
   *
   * @tags auth
   * @name PostAuthApi
   * @summary Authenticate an existing user and return an access token
   * @request POST:/auth/login
   * @secure
   */
  postAuthApi = (data: { email: string; password: string }, params?: RequestParams) =>
    this.request<any, any>(`/auth/login`, "POST", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags auth
   * @name PostLogoutUser
   * @summary Remove token from user
   * @request POST:/auth/logout
   * @secure
   */
  postLogoutUser = (params?: RequestParams) =>
    this.request<any, any>(`/auth/logout`, "POST", params, null, BodyType.Json, true);
  /**
   * No description
   *
   * @tags auth
   * @name GetGetUser
   * @summary Validate access token and return user info
   * @request GET:/auth/user
   * @secure
   */
  getGetUser = (params?: RequestParams) =>
    this.request<User, any>(`/auth/user`, "GET", params, null, BodyType.Json, true);
  /**
   * No description
   *
   * @tags auth
   * @name PostForgotUserPassword
   * @summary Sends a email notification containing a link to reset the password
   * @request POST:/auth/user/forgot-password
   * @secure
   */
  postForgotUserPassword = (data: { email: string }, params?: RequestParams) =>
    this.request<any, any>(`/auth/user/forgot-password`, "POST", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags auth
   * @name PostResetUserPassword
   * @summary Resets a user password and returns an access token
   * @request POST:/auth/user/reset-password
   * @secure
   */
  postResetUserPassword = (data: { token: string; password: string }, params?: RequestParams) =>
    this.request<any, any>(`/auth/user/reset-password`, "POST", params, data, BodyType.FormData, true);
}
