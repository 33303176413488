import React, {ReactNode} from "react";

import Header, { Props as HeaderProps } from "../../Layout/Header/Header";
import Body from "../../Layout/Body/Body";
import Footer from "../../Layout/Footer/Footer";

interface Props {
  children?: ReactNode;
  headerProps?: HeaderProps;
}

const Page = ({ children, headerProps }: Props): JSX.Element => (
  <>
    <Header {...headerProps} />
    <Body>
      {children}
    </Body>
    <Footer />
  </>
);

export default Page;
