import { NavigateFunction } from "react-router-dom";

import redirectionTrigger from "./redirectionTrigger";

export interface sectionOrder {
  section: string;
  url: string;
  isComplete: boolean;
  isVisible: boolean;
}

const nextIncompleteSection = (
  callback: NavigateFunction,
  sectionOrder: sectionOrder[]
): void => {
  const incompleteSection = sectionOrder.find(
    (section) => !section.isComplete && section.isVisible
  );

  if (incompleteSection) redirectionTrigger(callback, incompleteSection.url);
};

export default nextIncompleteSection;
