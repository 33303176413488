import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Checkbox.scss";

type Props = {
  checked?: boolean;
};

const Checkbox = ({ checked }: Props): JSX.Element => {
  return (
    <div className={`checkbox ${checked ? "checkbox--checked" : ""}`}>
      {checked && <FontAwesomeIcon className="checkbox__icon" icon={faCheck} />}
    </div>
  );
};

export default Checkbox;
