import React, { FC, useContext } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router";
import clsx from "clsx";

import "./Onboarding.scss";
import paths from "../../Paths";
import Explainer from "../../Components/Onboarding/Explainer/Explainer";
import CompanyInformation from "../../Components/Onboarding/CompanyInformation/CompanyInformation";
import logo from "!file-loader!../../assets/images/bp-logo.svg";
import { Auth } from "../../apiClient/Auth";
import { useAuth } from "../../state/auth";
import { onboardingImages } from "./OnboardingImages";
import Checklist from "../../Components/Onboarding/Checklist/Checklist";
import redirectionTrigger from "../../utils/redirectionTrigger";
import Checkout from "../../Components/Onboarding/Checkout/Checkout";
import BillingDetails from "../../Components/Onboarding/BillingDetails/BillingDetails";
import CheckoutContext from "../../state/contexts/checkout";
import { ProcessPaymentState } from "../../DataTypes";
import { errorToast } from "../../utils/toast";
import { CustomError, handleError } from "../../utils/errorHandler";

const authClient = new Auth();

const Onboarding: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentState } = useContext(CheckoutContext);
  const { loginInfo, removeLoginInfo } = useAuth();
  const handleLogout = async () => {
    try {
      authClient.postLogoutUser({
        headers: { Authorization: loginInfo.token },
      });
      removeLoginInfo();
      redirectionTrigger(navigate, paths.login.url);
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    }
  };

  const shouldRenderBillingDetails =
    location.pathname === paths.checkout.url &&
    paymentState === ProcessPaymentState.NotProcessing;

  return (
    <div className="onboarding">
      <button
        data-testid="logout-btn"
        className="onboarding-logout-btn"
        onClick={handleLogout}
      >
        Log Out
      </button>
      <div className="onboarding-content">
        <div className="onboarding-header">
          <img
            className="onboarding-header__logo"
            src={logo}
            alt="onboarding logo"
          />
        </div>
        <Routes>
          <Route path="get-started" element={<Explainer />} />
          <Route path="company-information" element={<CompanyInformation />} />
          <Route path="checklist" element={<Checklist />} />
          <Route path="checkout" element={<Checkout />} />
        </Routes>
      </div>
      <div
        className={clsx(
          "onboarding-right-panel",
          shouldRenderBillingDetails && "billing-details-container"
        )}
      >
        {shouldRenderBillingDetails ? (
          <BillingDetails />
        ) : (
          <img src={onboardingImages[location.pathname]} alt="side banner" />
        )}
      </div>
    </div>
  );
};

export default Onboarding;
