import clsx from "clsx";
import React, { FC } from "react";
import Input from "../Input/Input";
import Label from "../Label/Label";
import { FieldAttributes } from "formik";
import "./FormGroup.scss";
import ValidationMessage from "../ValidationMessage/ValidationMessage";

interface FormGroupProps {
  as: "input" | "textarea";
  customClassName?: string[];
  label: string;
  name: string;
  placeholder?: string;
}

type Props = FieldAttributes<unknown> & FormGroupProps;

const FormGroup: FC<Props> = (props): JSX.Element => {
  const { customClassName, label, name, placeholder = "", children } = props;
  return (
    <div className={clsx("form-group", customClassName)}>
      <Label label={label} name={name} />
      {children}
      <Input name={name} placeholder={placeholder} {...props} />
      <ValidationMessage componentType={"span"} name={name} />
    </div>
  );
};

export default FormGroup;
