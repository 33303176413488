import React, { FC } from "react";
import clsx from "clsx";
import "./Paragraph.scss";

interface ParagraphProps {
  type: "primary" | "secondary";
  customClassName?: string[];
}

const Paragraph: FC<ParagraphProps> = (props): JSX.Element => {
  const { type, children, customClassName } = props;
  return (
    <p
      className={clsx(
        {
          "paragraph--primary": type === "primary",
          "paragraph--secondary": type === "secondary",
        },
        customClassName
      )}
    >
      {children}
    </p>
  );
};

export default Paragraph;
