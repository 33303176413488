import { CPL } from "../../../DataTypes";
import paths from "../../../Paths";
import { sectionOrder } from "../../../utils/nextSectionRedirection";

export const maxLifestyleImages = 10;
export const maxProductShotImages = 10;

export const isOfferPromoCodeComplete = (cpl: CPL): boolean =>
  cpl &&
  [
    cpl.offer_type,
    cpl.off_amount || cpl.free_gift,
    cpl.promo_code,
    cpl.exclusions_notes,
    cpl.expiration,
  ].every((i) => i);

export const isCPlAssetsComplete = (cpl: CPL): boolean =>
  cpl &&
  [
    !!cpl.company.image,
    !!cpl.company.brand_description,
    cpl.brand_assets.lifestyle_images.length === maxLifestyleImages,
    cpl.brand_assets.product_shot_images.length === maxProductShotImages,
    cpl.no_cpl_videos || cpl.brand_assets.cpl_videos.length > 0,
  ].every((i) => i);

export const isESPComplete = (cpl: CPL): boolean =>
  cpl &&
  [
    cpl.api_setup.is_enabled,
    !["No ESP", "None"].includes(cpl.api_setup.name),
    cpl.api_setup.keys && cpl.api_setup.keys.every((k) => k.value),
  ].every((i) => i);

export const cplSectionOrder = (cpl: CPL): sectionOrder[] => [
  {
    section: "cplOfferPromoCode",
    url: paths.cplOfferPromoCode.url,
    isComplete: isOfferPromoCodeComplete(cpl),
    isVisible: true,
  },
  {
    section: "cplAssetsCollection",
    url: paths.cplAssetsCollection.url,
    isComplete: isCPlAssetsComplete(cpl),
    isVisible: true,
  },
  {
    section: "espIntegration",
    url: paths.cplEspIntegration.url,
    isComplete: isESPComplete(cpl),
    isVisible: true,
  },
];
