import React, { FC, useContext, useEffect, useState } from "react";

import { Cpl } from "../../../../../../apiClient/Cpl";
import { CPL, OfferType } from "../../../../../../DataTypes";
import { isOfferPromoCodeComplete } from "../../../../../CostPerLead/utils";
import CplContext from "../../../../../../state/contexts/cpl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../state/auth";
import { useLoading } from "../../../../../../state/loader";
import { CustomError, handleError } from "../../../../../../utils/errorHandler";
import { cleanValuesOnOfferSelection } from "../../../../../../Components/OfferPromoCode/OfferAcceptanceForm/utils";
import "./AcceptanceForm.scss";
import Paragraph from "../../../../../../Components/v2/UI/Paragraphs/Paragraph";
import { Formik, Form } from "formik";
import clsx from "clsx";
import SubmitButton from "../../../../../../Components/v2/UI/Buttons/SubmitButton/SubmitButton";
import Link from "../../../../../../Components/v2/UI/Link/Link";
import { bpContactEmail } from "../../../../../../utils/constants";
import { validationSchema } from "../../../../../../pages/OnSiteOffer/formValidation";
import FormErrors from "../../../../../../Components/v2/UI/Forms/FormErrors/FormErrors";
import { errorToast } from "../../../../../../utils/toast";
import redirectionTrigger from "../../../../../../utils/redirectionTrigger";
import Dropdown from "../../../../../../Components/v2/UI/Forms/Dropdown/Dropdown";
import FormGroup from "../../../../../../Components/v2/UI/Forms/FormGroup/FormGroup";

export interface OfferPromoValues {
  offer_type: OfferType;
  off_amount: number;
  off_amount_min_purchase_amount: number;
  free_gift: string;
  free_gift_min_purchase_qualifications: number;
  promo_code: string;
  exclusions_notes: string;
  expiration?: string;
}

const RenderOfferTypeInputs = ({
  offerType,
}: {
  offerType: OfferType;
}): JSX.Element =>
  offerType === OfferType.OffOffer ? (
    <>
      <FormGroup
        label={"$ Discount Offer:"}
        name={"off_amount"}
        as={"input"}
        type={"number"}
      />
      <FormGroup
        label={"Minimum purchase amount for discount:"}
        name={"off_amount_min_purchase_amount"}
        as={"input"}
        type={"number"}
        placeholder={"$120"}
      />
    </>
  ) : (
    <>
      <FormGroup
        label={"Free Gift:"}
        name={"free_gift"}
        as={"input"}
        type={"text"}
        placeholder={"Product/Service"}
      />
      <FormGroup
        label={"Minimum purchase amount for free gift:"}
        name={"free_gift_min_purchase_qualifications"}
        as={"input"}
        type={"number"}
        placeholder={"$120"}
      />
    </>
  );

const OfferAcceptanceForm: FC<{ cpl: CPL; next: string }> = ({ cpl, next }) => {
  const [offerType, setOfferType] = useState<string>(OfferType.OffOffer);
  const { setCpl } = useContext(CplContext);

  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  const navigate = useNavigate();

  const cplOfferOptions = {
    [OfferType.OffOffer]: "$ Discount Offer",
    [OfferType.FreeGift]: "Free Gift",
  };

  useEffect(() => {
    cpl && cpl.offer_type && setOfferType(cpl.offer_type);
  }, [cpl]);

  const disabled = isOfferPromoCodeComplete(cpl);

  const onSubmit = async (values: OfferPromoValues) => {
    const cleanValues = cleanValuesOnOfferSelection(values);
    try {
      setLoading(true);
      const { data } = await new Cpl().patchCplBrandPortalApi(
        cpl.id,
        cleanValues,
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      );
      setCpl(data);
      redirectionTrigger(navigate, next);
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: OfferPromoValues = {
    offer_type: (cpl.offer_type || offerType) as OfferType,
    off_amount: cpl.off_amount ?? ("" as unknown as number),
    off_amount_min_purchase_amount:
      cpl.off_amount_min_purchase_amount ?? ("" as unknown as number),
    free_gift: cpl.free_gift || "",
    free_gift_min_purchase_qualifications:
      cpl.free_gift_min_purchase_qualifications ?? ("" as unknown as number),
    promo_code: cpl.promo_code || "",
    exclusions_notes: cpl.exclusions_notes || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, isValid, submitCount, values }) => {
        return (
          <Form>
            <fieldset
              className={clsx("flex-wrap-container")}
              disabled={disabled}
            >
              <Dropdown
                label={"Select Your Offer Type:"}
                name="offer_type"
                showEmptyOption={!values.offer_type}
              >
                {Object.entries(cplOfferOptions).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Dropdown>

              <RenderOfferTypeInputs offerType={values.offer_type} />

              <FormGroup
                label={"Promo Code:"}
                name={"promo_code"}
                as={"input"}
                type={"text"}
              />

              <FormGroup
                label={"Offer Terms & Conditions"}
                name={"exclusions_notes"}
                as={"textarea"}
                type={"text"}
              >
                <Paragraph type="secondary">
                  Terms of the offer. Fine print, exclusions, apply, limit 1 per
                  household, can't combine with other offers, etc.
                </Paragraph>
              </FormGroup>

              <div className="important-message">
                <Paragraph type="secondary" customClassName={["bold"]}>
                  Important! This offer cannot have an expiration date.
                </Paragraph>

                {disabled ? null : <SubmitButton label="Next" />}
                <FormErrors
                  errors={errors}
                  isSubmitting={isSubmitting}
                  submitCount={submitCount}
                  isValid={isValid}
                />
              </div>

              <Paragraph type={"primary"}>
                If you have any questions, contact us via live chat or at{" "}
                <Link type={"mail"} url={bpContactEmail} />
              </Paragraph>
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OfferAcceptanceForm;
