import * as yup from "yup";

const size = 15;
const sizeInBytes = size * 1024 ** 2;

const validationSchema = {
  offer_video: yup.mixed().when("no_cpl_videos", {
    is: (checked: boolean) => !checked,
    then: yup
      .mixed()
      .required("Add a video or opt to use offer images instead.")
      .test(
        "fileSize",
        `Selected video file is larger than ${size} MB. Please compress it or select a smaller file.`,
        (value: File): boolean =>
          value?.size === undefined || value?.size <= sizeInBytes
      )
      .test(
        "fileFormat",
        `Selected video file format is not supported.`,
        (value: File) =>
          typeof value === "string" ||
          ["video/mp4", "video/quicktime"].includes(value?.type)
      ),
  }),
  no_cpl_videos: yup.boolean(),
};

export default validationSchema;
