import { BrandInformationPayload, CompanyPayload, ContactPayload } from "../../../DataTypes";

export const generateCompanyPayload = (
  values: BrandInformationPayload
): CompanyPayload => ({
  parent_company: values.parent_company,
  street_address_1: values.street_address_1,
  street_address_2: values.street_address_2,
  city: values.city,
  state: values.state,
  zip_code: values.zip_code,
  phone: values.phone,
  phone_extension: values.phone_extension,
  esp: values.esp,
  esp_other_details: values.esp_other_details,
  url: values.url,
});

export const generatePrimaryContactPayload = (
  values: BrandInformationPayload
): ContactPayload => ({
  first_name: values.first_name,
  last_name: values.last_name,
  position_title: values.position_title,
  email_address_1: values.email_address_1,
  email_address_2: values.email_address_2,
  phone_1_number: values.phone_1_number,
  phone_1_extension: values.phone_1_extension,
  phone_2_number: values.phone_2_number,
});

export const generateSecondaryContactPayload = (
  values: BrandInformationPayload
): ContactPayload => ({
  first_name: values.secondary_first_name,
  last_name: values.secondary_last_name,
  position_title: values.secondary_position_title,
  email_address_1: values.secondary_email_address_1,
  email_address_2: values.secondary_email_address_2,
  phone_1_number: values.secondary_phone_1_number,
});
