/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EspPayload,
  Slot,
  SlotBrandAssetDeletePayload,
  SlotBrandAssetPostPayload,
  SlotBrandAssetPutPayload,
  SlotPayload,
  SmsPayload,
} from "../DataTypes";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Slots extends HttpClient {
  /**
   * No description
   *
   * @tags slot
   * @name PatchSlotApi
   * @summary Update the slot
   * @request PATCH:/slot/{id}
   * @secure
   */
  patchSlotApi = (id: number, data: SlotPayload, params?: RequestParams) =>
    this.request<any, any>(
      `/slot/${id}`,
      "PATCH",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags slot
   * @name GetSlotApi
   * @summary Fetch a slot given its identifier
   * @request GET:/slot/{id}
   * @secure
   */
  getSlotApi = (id: number, params?: RequestParams) =>
    this.request<Slot, any>(
      `/slot/${id}`,
      "GET",
      params,
      null,
      BodyType.Json,
      true
    );
  /**
   * No description
   *
   * @tags slot
   * @name PostAssetApi
   * @summary Add a new Asset
   * @request POST:/slot/{slot_id}/asset
   * @secure
   */
  postAssetApi = (
    slot_id: number,
    data: SlotBrandAssetPostPayload,
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/slot/${slot_id}/asset`,
      "POST",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags slot
   * @name PutAssetApi
   * @summary Updates an Asset
   * @request PUT:/slot/{slot_id}/asset
   * @secure
   */
  putAssetApi = (
    slot_id: number,
    data: SlotBrandAssetPutPayload,
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/slot/${slot_id}/asset`,
      "PUT",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags slot
   * @name DeleteAssetApi
   * @summary Deletes an Asset
   * @request DELETE:/slot/{slot_id}/asset
   * @secure
   */
  deleteAssetApi = (
    slot_id: number,
    data: SlotBrandAssetDeletePayload,
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/slot/${slot_id}/asset`,
      "DELETE",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags slot
   * @name PatchEspApi
   * @summary Update the ESP data for a slot
   * @request PATCH:/slot/{slot_id}/esp
   * @secure
   */
  patchEspApi = (slot_id: number, data: EspPayload, params?: RequestParams) =>
    this.request<any, any>(
      `/slot/${slot_id}/esp`,
      "PATCH",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags slot
   * @name PatchSmsApi
   * @summary Update the SMS data for a slot
   * @request PATCH:/slot/{slot_id}/sms
   * @secure
   */
  patchSmsApi = (slot_id: number, data: SmsPayload, params?: RequestParams) =>
    this.request<any, any>(
      `/slot/${slot_id}/sms`,
      "PATCH",
      params,
      data,
      BodyType.FormData,
      true
    );
}
