import React, { useState, useContext, useEffect, useMemo } from "react";

import { ErrorMessage, Field, Form as FormikForm, Formik } from "formik";

import SpinnerButton from "../../Components/SpinnerButton/SpinnerButton";
import validationSchema from "./validationSchema";
import { IMaskInput } from "react-imask";
import { BrandSigning } from "../../DataTypes";
import {
  isSOWComplete,
  isLetsGetStartedComplete,
  isPayBalanceComplete,
} from "../../pages/GetStartedCheckList/GetStartedChecklist";
import CompanyContext from "../../state/contexts/company";
import { getCurrentBrandSigning } from "../StatementOfWork/utils";
import { Link } from "react-router-dom";
import { bpContactEmail } from "../../utils/constants";

export interface CreditCardValues {
  credit_card_number: string;
  cvv: string;
  expiration_date: string;
  billing_zip_code: string;
  sow_signed: boolean;
}

interface FormProps {
  onSubmit: (creditCardValues: CreditCardValues) => void;
}

const Form = ({ onSubmit }: FormProps): JSX.Element => {
  const { company } = useContext(CompanyContext);
  const SoWCompleted = isSOWComplete(company);
  const initialValues: CreditCardValues = useMemo(() => ({
    credit_card_number: "",
    cvv: "",
    expiration_date: "",
    billing_zip_code: "",
    sow_signed: SoWCompleted,
  }), [SoWCompleted]);
  const [brandSigning, setBrandSigning] = useState<BrandSigning>();
  const isDisable = isPayBalanceComplete(company) && SoWCompleted;
  const isATCComplete = isLetsGetStartedComplete(company);

  useEffect(() => {
    company && setBrandSigning(getCurrentBrandSigning(company));
  }, [company]);

  if (!company || !brandSigning) {
    return (
      <p>
        Your FindKeep.Love plan is not ready yet. If you believe it is an error,
        please contact us at
        <a href={`mailto:${bpContactEmail}`} className="link">
          {bpContactEmail}
        </a>
      </p>
    );
  }

  return (
    company &&
    brandSigning && (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema(new Date())}
      >
        {({ setFieldValue }) => {
          return (
            <FormikForm>
              <div className="row">
                <div className="form__group col col--one-half">
                  <label htmlFor="credit_card_number">
                    <strong>Credit Card Number</strong>
                  </label>
                  <Field
                    as="input"
                    name="credit_card_number"
                    id="credit_card_number"
                    className="form__input"
                    placeholder="4007000000027"
                    pattern="[\d| ]{10,22}"
                  />
                  <ErrorMessage
                    name="credit_card_number"
                    component="div"
                    className="form__control--error"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form__group col col--one-third">
                  <label htmlFor="expiration_date">
                    <strong>Expiration</strong>
                  </label>
                  <Field name="expiration_date">
                    {({ field }: { field: typeof Field }) => (
                      <IMaskInput
                        {...field}
                        id="expiration_date"
                        className="form__input"
                        placeholder="MM/YY"
                        pattern="\d\d/\d\d"
                        mask="00/00"
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="expiration_date"
                    component="div"
                    className="form__control--error"
                  />
                </div>
                <div className="form__group col col--one-third">
                  <label htmlFor="cvv">
                    <strong>CVV</strong>
                  </label>
                  <Field
                    as="input"
                    type="password"
                    name="cvv"
                    id="cvv"
                    className="form__input"
                    placeholder="123"
                    pattern="\d{3,4}"
                  />
                  <ErrorMessage
                    name="cvv"
                    component="div"
                    className="form__control--error"
                  />
                </div>
                <div className="form__group col col--one-third">
                  <label htmlFor="billing_zip_code">
                    <strong>Zip Code</strong>
                  </label>
                  <Field
                    as="input"
                    name="billing_zip_code"
                    id="billing_zip_code"
                    className="form__input"
                    placeholder="12345"
                  />
                  <ErrorMessage
                    name="billing_zip_code"
                    component="div"
                    className="form__control--error"
                  />
                </div>
              </div>
              {isATCComplete ? (
                <div className="sow-checkbox">
                  <Field
                    type="checkbox"
                    name="sow_signed"
                    id="sow_signed"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("sow_signed", event.currentTarget.checked);
                    }}
                    disabled={isDisable}
                  />
                  <label htmlFor="sow_signed">
                    By clicking the box, you agree to the FindKeep.Love{" "}
                    <a
                      className="terms-download"
                      href={brandSigning.sow_document_full_path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Statement of Work
                    </a>
                  </label>
                  <ErrorMessage
                    name="sow_signed"
                    component="div"
                    className="form__control--error"
                  />
                </div>
              ) : (
                <span className="atcWarning">
                  Please complete{" "}
                  <Link
                    to="/get-started-checklist/lets-get-started"
                    className="link"
                  >
                    Let's get started section
                  </Link>{" "}
                  to sign the SOW
                </span>
              )}
              <SpinnerButton />
            </FormikForm>
          );
        }}
      </Formik>
    )
  );
};

export default Form;
