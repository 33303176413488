import {
  CreditCardErrors,
  CreditCardValues,
  ParsedPayment,
} from "../../../DataTypes";
import { isDefined } from "../../../utils";

export const parsePayment = (cc: CreditCardValues): ParsedPayment => {
  const [expirationMonth, expirationYear] = cc.expiration_date.split("/");
  return {
    number: parseInt(cc.credit_card_number),
    expiration_date: `20${expirationYear}-${expirationMonth}`,
    code: cc.cvv,
    billing_zip_code: cc.billing_zip_code,
  };
};

export const mapErrorName = (key: string): string => {
  const map: Record<string, string> = {
    number: "Credit Card Number",
    code: "CVV",
    expiration_date: "Expiration Date",
    transaction_errors: "Transaction Error",
  };
  return map[key] || "Unknown error";
};

export const combineErrors = (errors: CreditCardErrors): string[] =>
  Object.entries(errors)
    .filter(([_, value]) => isDefined(value))
    .map(([key, value]) => `${mapErrorName(key)}: ${value}`);
