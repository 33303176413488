import React, { FC } from "react";
import { Contact } from "../../../../apiClient/data-contracts";
import {
  getAlternateEmailAddress,
  getPhoneNumber,
} from "../../../../Components/BrandInformation/utils";
import Paragraph from "../../../../Components/v2/UI/Paragraphs/Paragraph";
import { PhoneTypes } from "../../../../DataTypes";

type ContactDetailsProps = {
  contact: Contact;
};

const ContactDetails: FC<ContactDetailsProps> = ({ contact }) => {
  const workPhone = getPhoneNumber(contact, PhoneTypes.WORK);
  const mobilePhone = getPhoneNumber(contact, PhoneTypes.OTHER);
  const alternativeEmail = getAlternateEmailAddress(contact);

  return (
    <div className="my_account-info">
      <Paragraph type="primary">
        <strong className="bold">
          {contact?.first_name} {contact?.last_name}
        </strong>
      </Paragraph>
      {contact?.position_title && (
        <Paragraph type="primary">{contact?.position_title}</Paragraph>
      )}
      {contact?.email_address && (
        <Paragraph type="primary">
          <a href={`mailto:${contact?.email_address}`} className="link">
            {contact?.email_address}
          </a>
        </Paragraph>
      )}
      {alternativeEmail && (
        <Paragraph type="primary">
          Alternative email:{" "}
          <a href={`mailto:${alternativeEmail}`} className="link">
            {alternativeEmail}
          </a>
        </Paragraph>
      )}
      {workPhone && (
        <Paragraph type="primary">
          Work phone number: {workPhone?.number}{" "}
          {workPhone?.extension ? `ext ${workPhone?.extension}` : ""}
        </Paragraph>
      )}
      {mobilePhone && (
        <Paragraph type="primary">
          Mobile phone number: {mobilePhone?.number}{" "}
          {mobilePhone?.extension ? `ext ${mobilePhone?.extension}` : ""}
        </Paragraph>
      )}
    </div>
  );
};

export default ContactDetails;
