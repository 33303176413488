import React, { FC } from "react";
import "./Form.scss";

import {
  EspName,
  EspPayload,
  Slot,
  CPL,
  SweepstakeStatus,
  PaymentStatusValues,
} from "../../DataTypes";
import { Field, Form, Formik, FormikConfig, ErrorMessage } from "formik";

import DisplayIntegrationFields from "../DisplayIntegrationFields/DisplayIntegrationFields";
import ErrorFormDisplay from "../ErrorFormDisplay/ErrorFormDisplay";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import validationSchema from "./validations";
import { bpContactEmail } from "../../utils/constants";

const noEspValue: EspName[] = ["No ESP", "None"];

type dataSourceType = Slot & CPL;

type Props = {
  dataSource: dataSourceType;
  sweepStatus?: number;
  onSubmit: (values: EspPayload) => void;
  isComplete: (dataSource: dataSourceType) => boolean;
  valid: boolean;
  err?: string;
  supportedEsp: boolean;
  submitted: boolean;
  newUI?: boolean;
};

interface DocUrl {
  [espName: string]: string;
}

const espDocChoices: DocUrl = {
  Moosend:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_MOOSEND.pdf?v=1615998549",
  Klaviyo:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_KLAYVIO.pdf?v=1615998549",
  Bronto:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_BRONTO.pdf?v=1615998548",
  Sailthru:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_SAILTHRU.pdf?v=1615998549",
  Mailchimp:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_MAILCHIMP_v2.pdf?v=1621542475",
  RetentionScience:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_RETENTION-SCIENCE.pdf?v=1615998549",
  Omnisend:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_OMNISEND.pdf?v=1615998549",
  Listrak:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_LISTRAK.pdf?v=1615998549",
  ConstantContact:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_CONSTANT-CONTACT.pdf?v=1615998549",
  Hubspot:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_HUBSPOT.pdf?v=1615998549",
  Remarkety:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_REMARKETY.pdf?v=1615998549",
  ActiveCampaign:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_ACTIVECAMPAIGN.pdf?v=1615998548",
  Drip: "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_DRIP.pdf?v=1615998549",
  Cordial:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_CORDIAL.pdf?v=1616018932",
  Salesforce:
    "https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Master-API-Portal-Instructions_SALESFORCE.pdf?v=1616018932",
};

const DocEsp = ({ espSelected }: { espSelected: string }): JSX.Element => {
  const espDocUrl = espDocChoices[espSelected];
  return espDocUrl ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="black-link"
      href={espDocUrl}
    >
      {espSelected} Documentation
    </a>
  ) : null;
};

const EspForm: FC<Props> = ({
  onSubmit,
  dataSource,
  isComplete,
  sweepStatus,
  valid,
  err,
  supportedEsp,
  submitted,
  newUI,
}) => {
  const baseFormikProps: FormikConfig<EspPayload> = {
    onSubmit,
    validationSchema: validationSchema(dataSource.api_setup.choices),
    initialValues: {
      esp_integration_enable: !!dataSource.api_setup.is_enabled,
      is_welcome_email_setup: !!dataSource.is_welcome_email_setup,
    },
  };
  const formikProps = !noEspValue.includes(dataSource.api_setup.name)
    ? {
        ...baseFormikProps,
        initialValues: {
          ...baseFormikProps.initialValues,
          esp_selected: dataSource.api_setup.name,
        },
      }
    : baseFormikProps;

  const disabled =
    sweepStatus === SweepstakeStatus.Live || isComplete(dataSource);

  const isComplimentary =
    dataSource.payment_status?.id === PaymentStatusValues.COMPLIMENTARY;

  const handleClick = (
    event: React.MouseEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: boolean,
      shouldValidate?: boolean
    ) => void
  ) => {
    setFieldValue("is_welcome_email_setup", event.currentTarget.checked);
  };
  return (
    <article>
      <Formik {...formikProps}>
        {({
          values,
          handleChange,
          errors,
          isSubmitting,
          submitCount,
          isValid,
          setFieldValue,
        }) => (
          <Form data-testid="form">
            <div className="form__control">
              <div>
                <label htmlFor="esp_selected">
                  {newUI
                    ? "Email Service Provider:*"
                    : "Email Service Provider"}
                </label>
              </div>
              <ErrorMessage
                name="esp_selected"
                component="div"
                className="form__control--error"
              />
              <Field
                as="select"
                name="esp_selected"
                id="esp_selected"
                className="form__input"
                disabled={disabled}
                value={values.esp_selected || ""}
                data-testid="test_esp_selected"
              >
                <option value="">Select a provider </option>
                {dataSource.api_setup.choices.map(({ name, value }, idx) => (
                  <option key={idx} value={value}>
                    {name}
                  </option>
                ))}
              </Field>
              {!newUI && (
                <div className="esp-note">
                  (All data provided is stored on a secure and encrypted server.
                  We will send an email to you ESP once the credentials are
                  validated)
                </div>
              )}
              <div className="esp-documentation">
                <DocEsp espSelected={values.esp_selected} />
              </div>
            </div>
            <DisplayIntegrationFields
              choices={dataSource.api_setup.choices}
              currentIntegrationName={values.esp_selected}
              slotIntegrationName={dataSource.api_setup.name}
              keys={dataSource.api_setup.keys}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              disabled={disabled}
              errors={errors}
            />
            {isComplimentary && !!values.esp_selected && (
              <div className="welcome-checkbox">
                <p>
                  FindKeep.Love hosts your sweepstakes and will API feed opt-ins
                  to the email service provider and list ID you provide, but you
                  are solely responsible for email marketing and triggering your
                  welcome flows to newly acquired users. You can check out some
                  examples of strong welcome flows{" "}
                  <a
                    href="https://www.findkeep.love/pages/welcome-flow-samples"
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                  . Password: EMAIL (case sensitive).{" "}
                </p>
                <label>
                  <input
                    type="checkbox"
                    name="is_welcome_email_setup"
                    onClick={(event) => handleClick(event, setFieldValue)}
                    required={isComplimentary}
                    disabled={disabled}
                    defaultChecked={values.is_welcome_email_setup}
                  />{" "}
                  I confirm that I'm setting up a Welcome Series for my new
                  email opt-ins{" "}
                </label>
              </div>
            )}
            {submitted && valid && supportedEsp ? (
              <p>
                <b className="success">Your integration is valid!</b>
                <br />
                {`To verify that everything is set up correctly on your end,
                please check your email list for the test lead we sent:
                ${bpContactEmail}`}
                <br />
                If you <b>do not see the contact</b>, please reach out to{" "}
                <a href={`mailto:${bpContactEmail}`} className="link">
                  {bpContactEmail}
                </a>{" "}
                for assistance.
                <br />
              </p>
            ) : submitted && valid && !supportedEsp ? (
              <p>
                <b className="success">Data Saved</b>
              </p>
            ) : null}
            {submitted && !valid ? (
              <p>
                <b className="warning">Your integration is invalid.</b>
                <br />
                <b>Error</b>: {err} <br />
                Please verify the credentials you provided and try again, or
                reach out to{" "}
                <a href={`mailto:${bpContactEmail}`} className="link">
                  {bpContactEmail}
                </a>{" "}
                for assistance.
                <br />
              </p>
            ) : null}
            {!disabled && (
              <div className="form__control">
                <SpinnerButton label={newUI ? "Submit" : "SUBMIT"} />
              </div>
            )}
            <ErrorFormDisplay
              errors={errors}
              isSubmitting={isSubmitting}
              submitCount={submitCount}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </article>
  );
};
export default EspForm;
