import { OptinReportPoint } from "../../../apiClient/data-contracts";
import { ChartLabels } from "../../../DataTypes";

export const assignColorToLabel = (
  labels: string[],
  colors: string[]
): string[] => labels.map((_value, index) => colors[index % colors.length]);

export const formatFullDate = (date: string): string => {
  const [year, month, day] = date.split("-");
  const newMonth = month.replace(/^0+/, "");
  return `${newMonth}/${day}/${year}`;
};

export const convertDataToLabels = (data: OptinReportPoint[]): ChartLabels => {
  const initialLabels: ChartLabels = { xLabels: [], yLabels: [] };

  if (!data) {
    return initialLabels;
  }

  return data.reduce((acc, currentValue) => {
    acc.xLabels.push(currentValue.date);
    acc.yLabels.push(currentValue.count);
    return acc;
  }, initialLabels);
};

export const formatDateLabel = (date: string): string =>
  new Date(date)
    .toISOString()
    .substring(5, 10)
    .replace("-", "/")
    .replace(/^0+/, "");

export const getOptinsFromDate = (
  date: string,
  optinsData: OptinReportPoint[]
): OptinReportPoint[] =>
  optinsData.filter((optin) => new Date(optin.date) >= new Date(date));

export const getOptinsBetweenDates = (
  startDate: string,
  endDate: string,
  optinsData: OptinReportPoint[]
): OptinReportPoint[] =>
  optinsData?.filter(
    (optin) =>
      new Date(optin.date) >= new Date(startDate) &&
      new Date(optin.date) <= new Date(endDate)
  );

export const generateOptInsDatesRange = (dates: string[]): OptinReportPoint[] =>
  dates.map((current) => ({ date: current, count: 0 }));

export const fillBrandOptInsByDate = <T extends OptinReportPoint[]>(
  brandOptIns: T,
  datesOptIns: T
): T => {
  const datesWithBrandOptIns = datesOptIns?.map((dateOptIn) => {
    const foundDateOptInd = brandOptIns.find(
      (brandOptIn) => brandOptIn.date === dateOptIn.date
    );
    if (foundDateOptInd) {
      dateOptIn.count = foundDateOptInd.count;
    }
    return dateOptIn;
  });
  return datesWithBrandOptIns as T;
};

const getDateTime = (date: string): number => new Date(date).getTime();

export const getStartDate = (
  startDate: string,
  optInsData: OptinReportPoint[]
): string => {
  if (optInsData.length === 0) {
    return startDate;
  }

  const sortedOptinsData = optInsData
    .slice()
    .sort(
      (optinA, optinB) => getDateTime(optinA.date) - getDateTime(optinB.date)
    );

  return getDateTime(startDate) < getDateTime(sortedOptinsData[0].date)
    ? startDate
    : sortedOptinsData[0].date;
};
