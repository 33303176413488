import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";

import SuccessIcon from "../../../../assets/images/checkmark-round.svg";
import {
  OnboardingStatus,
  setOnboardingStatus,
} from "../../../../pages/Onboarding/OnboardingStatus";
import redirectionTrigger from "../../../../utils/redirectionTrigger";
import paths from "../../../../Paths";
import { bpContactEmail } from "utils/constants";

const PaymentSuccessful = (): JSX.Element => {
  useEffect(() => {
    setOnboardingStatus(OnboardingStatus.FINISHED);
  }, []);

  const navigate = useNavigate();
  const handleNextStep = useCallback(() => {
    redirectionTrigger(navigate, paths.home.url);
  }, [navigate]);

  return (
    <>
      <h1 className="onboarding-title">We’re excited to work with you!</h1>
      <div className="payment-status nowrap">
        <img src={SuccessIcon} alt="success"/>
        <span className="onboarding-paragraph">Your payment is complete</span>
      </div>
      <button className="onboarding-button" onClick={handleNextStep}>
        Done
      </button>
      <p className="onboarding-info">
        {`Questions about your payment? Contact your FindKeep.Love account manager or reach out to us at ${bpContactEmail}`}
      </p>
    </>
  );
};

export default PaymentSuccessful;
