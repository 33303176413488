import React, { FC, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorIcon from "../../assets/images/error.svg";
import { PaymentStatus } from "../../DataTypes";
import paths from "../../Paths";
import LastRecurringPaymentContext from "state/contexts/lastRecurringPayment";
import { isLastPaymentFailed } from "Components/Onboarding/Checkout/Checkout";
import "./PaymentReminder.scss";

const hideAt = [paths.payYourBalance.url];

const PaymentReminder: FC<{ hideButton?: boolean }> = ({
  hideButton = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHidden = hideAt.includes(location.pathname);
  const { lastRecurringPayment } = useContext(LastRecurringPaymentContext);

  useEffect(() => {
    if (
      isLastPaymentFailed(lastRecurringPayment) &&
      !location.pathname.includes(paths.onboarding.url)
    ) {
      navigate(paths.checkout.url);
    }
  }, [lastRecurringPayment, location.pathname, navigate]);

  if (isHidden) {
    return null;
  }

  if (
    !lastRecurringPayment ||
    lastRecurringPayment.status !== PaymentStatus.failure
  ) {
    return null;
  }

  return (
    <div className="payment-reminder" data-testid="paymentReminder">
      <div className="payment-reminder__content">
        <div className="payment-reminder__info">
          <img src={ErrorIcon} alt="error" />
          <p className="payment-reminder__message">
            We couldn't process your last payment. Please update your billing
            information to continue using the service.
          </p>
        </div>
        {!hideButton && (
          <button
            className="payment-reminder__button"
            onClick={() => navigate(paths.payYourBalance.url)}
          >
            Update
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentReminder;
