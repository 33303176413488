import React, { useEffect, useState } from "react";

import { ErrorMessage, Form, Formik, FormikConfig } from "formik";
import { ImageListType } from "react-images-uploading";

import ErrorFormDisplay from "../ErrorFormDisplay/ErrorFormDisplay";
import MultipleImageUploader from "../MultipleImageUploader/MultipleImageUploader";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import TextArea from "../TextArea/TextArea";
import validationSchema from "./validations";

import { trimStringToLast25Chars } from "../../utils";
import { isSweepstakesDeliverablesComplete } from "../../pages/SponsorChecklist/utils";
import { Slot, SlotPayload } from "../../DataTypes";

type Props = {
  slot: Slot;
  onSubmit: (values: SlotPayload) => void;
  lifestyleImages: ImageListType;
  setLifestyleImages: (images: ImageListType) => void;
  companyImage: ImageListType;
  setCompanyImage: (images: ImageListType) => void;
  sweepVideo?: File;
  setSweepVideo: (video: File) => void;
};

const DeliverablesForm = ({
  onSubmit,
  slot,
  lifestyleImages,
  setLifestyleImages,
  companyImage,
  setCompanyImage,
  setSweepVideo,
}: Props): JSX.Element => {
  const [uploadedLogoImage, setUploadedLogoImage] = useState<number>(
    slot && slot.company.image ? 1 : 0
  );
  const maxLogoImageNumber = 1;

  const [uploadedImages, setUploadedImages] = useState<number>(
    slot.brand_assets.store_lifestyle_images.length
  );
  const maxImageNumber = 2;

  const [videoPreview, setVideoPreview] = useState<string>("");

  const [noVideoCheck, setNoVideoCheck] = useState<boolean>(
    slot.no_sweep_videos
  );

  useEffect(() => {
    slot.company?.image &&
      setCompanyImage([
        {
          dataURL: slot.company.image,
        },
      ]);

    setLifestyleImages(
      slot.brand_assets.store_lifestyle_images.map(({ filename }) => ({
        dataURL: filename,
      }))
    );

    if (slot.brand_assets.sweep_videos.length > 0) {
      setVideoPreview(slot.brand_assets.sweep_videos[0].filename);
    }

    setNoVideoCheck(slot.no_sweep_videos);
  }, [slot, setCompanyImage, setLifestyleImages]);

  const formikProps: FormikConfig<SlotPayload> = {
    onSubmit,
    validationSchema,
    initialValues: {
      brand_description: slot.company.brand_description || "",
      no_sweep_videos: slot.no_sweep_videos,
    },
  };

  const disabled = isSweepstakesDeliverablesComplete(slot);

  return (
    <Formik {...formikProps}>
      {({
        values,
        handleChange,
        errors,
        isSubmitting,
        isValid,
        submitCount,
      }) => (
        <Form>
          <article>
            <p>
              Please upload the required assets below. These will be used to
              feature your brand on the sweepstakes landing page and the
              announcement email.
            </p>
            <p>
              <a
                className="link"
                href="https://www.findkeep.love/pages/sample-preview-of-landing-page"
                target="_blank"
                rel="noreferrer"
              >
                Click here for a sample preview of a lander page.
              </a>
            </p>
            <p>
              <a
                className="link"
                href="https://www.findkeep.love/pages/sample-preview-of-announcement-email"
                target="_blank"
                rel="noreferrer"
              >
                Click here for a sample preview of an announcement email.
              </a>
            </p>
          </article>

          <article>
            <h3 className="heading-tertiary">
              1. Add your logo ({uploadedLogoImage}/{maxLogoImageNumber}{" "}
              uploaded)
            </h3>
            <p className="paragraph-secondary">
              Requirements: 500px minimum width. Must have transparent
              background.
            </p>

            <MultipleImageUploader
              maxImageNumber={maxLogoImageNumber}
              setUploadedImages={setUploadedLogoImage}
              imageState={companyImage}
              setImageState={setCompanyImage}
              disabled={disabled}
            />
          </article>

          <article>
            <h3 className="heading-tertiary">
              2. Add your Lifestyle images ({uploadedImages}/{maxImageNumber}{" "}
              uploaded)
            </h3>
            <p className="paragraph-secondary">
              Requirements: Upload two images (1 - 800x800px and 1 -
              1250x800px).
            </p>

            <MultipleImageUploader
              maxImageNumber={maxImageNumber}
              setUploadedImages={setUploadedImages}
              imageState={lifestyleImages}
              setImageState={setLifestyleImages}
              disabled={disabled}
            />
          </article>

          <article>
            <h3 className="heading-tertiary">
              3. Provide a description of your brand
            </h3>
            <p className="paragraph-secondary">
              Provide a 1-2 sentence description to introduce your brand to
              sweepstakes participants. This will be displayed on the
              sweepstakes landing page and is subject to editorial approval or
              adjustments.
            </p>
            <ErrorMessage
              name="brand_description"
              component="div"
              className="form__control--error"
            />
            <TextArea
              cols={40}
              maxLength={350}
              name="brand_description"
              id="brand_description"
              onChange={handleChange}
              defaultValue={values.brand_description}
              disabled={disabled}
            />
          </article>

          <article>
            <h3 className="heading-tertiary">4. Add your video</h3>
            <p className="paragraph-secondary">
              Recommended video specifications:{" "}
              <span className="bold">MOV</span> or{" "}
              <span className="bold">MP4</span> formats up to{" "}
              <span className="bold">15MB</span> in size and{" "}
              <span className="bold">4K</span> (4096 x 2160) resolution.{" "}
            </p>
            <p className="paragraph-secondary">
              The volume will be defaulted to off, please use a video that does
              not depend on sound. If your video exceeds 15MB, you can compress
              your file before uploading{" "}
              <a
                href="http://www.youcompress.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                here
              </a>
              .
            </p>
            <p className="paragraph-secondary">
              Your video will be displayed in a quiz after users enter the
              sweepstakes{" "}
              <a
                className="link"
                href="https://www.findkeep.love/products/on-site-offer-quiz-question"
                target="_blank"
                rel="noreferrer"
              >
                (see example)
              </a>
              . If you don't have a video, we will use a lifestyle image
              instead.
            </p>
            {!noVideoCheck && (
              <input
                type="file"
                className="form__control form__control__video-upload"
                accept="video/*,.mov,.mp4"
                disabled={disabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSweepVideo(event.currentTarget.files[0]);
                  setVideoPreview(
                    URL.createObjectURL(event.currentTarget.files[0])
                  );
                }}
              />
            )}
            {videoPreview && (
              <div className="imageData video-preview">
                <video controls width="250" src={videoPreview} />
                <div>{trimStringToLast25Chars(videoPreview)}</div>
              </div>
            )}
            <div className="form__control">
              <input
                type="checkbox"
                id="no_sweep_videos"
                name="no_sweep_videos"
                defaultChecked={values.no_sweep_videos}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNoVideoCheck(event.currentTarget.checked);
                  setSweepVideo(null);
                  setVideoPreview(null);
                  handleChange(event);
                }}
                disabled={disabled}
              />{" "}
              <label htmlFor="no_sweep_videos">
                I don't want to use a video for my sweep. Use lifestyle images
                instead
              </label>
            </div>
          </article>

          {!disabled && <SpinnerButton label="Submit assets" />}
          <ErrorFormDisplay
            errors={errors}
            isSubmitting={isSubmitting}
            submitCount={submitCount}
            isValid={isValid}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DeliverablesForm;
