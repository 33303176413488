import React, { FC } from "react";
import clsx from "clsx";
import { bpBillingEmail } from "../../../utils/constants";

const EmailLink: FC<{ children?: string; highlight?: boolean }> = ({
  children = bpBillingEmail,
  highlight,
}) => (
  <a
    className={clsx("link", highlight ? "link--blue" : "link--black")}
    href={`mailto:${children}`}
  >
    {children}
  </a>
);

export default EmailLink;
