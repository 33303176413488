import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SweepContext from "../../state/contexts/sweep";
import { Sweep, SweepstakeStatus } from "../../DataTypes";

import { faCaretRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./SweepLink.scss";

const sweepstakeStatusPresenter = (status: SweepstakeStatus): string => {
  switch (status) {
    case SweepstakeStatus.Preparing:
    case SweepstakeStatus.ReadyToReviewNewSlots:
    case SweepstakeStatus.FKLIsOutreachSlots:
      return "Open";
    case SweepstakeStatus.Booked:
      return "Booked";
    case SweepstakeStatus.Completed:
      return "Completed";
    case SweepstakeStatus.Cancelled:
      return "Cancelled";
    case SweepstakeStatus.Live:
      return "Live";
    default:
      return "UNKNOWN STATUS";
  }
};

export interface SweepstakePresenter extends Sweep {
  displayStatus: string;
  forDateMsg?: string;
}

export const sweepPresenter = (sweep: Sweep): SweepstakePresenter => {
  return {
    ...sweep,
    displayStatus: sweepstakeStatusPresenter(sweep.status),
    forDateMsg: formatForDateMsgField(sweep),
  };
};

export const formatDateSweep = (date: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    timeZone: "UTC",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

export const formatDateYYYYMM = (sweep: Sweep): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    timeZone: "UTC",
  };
  return new Date(sweep.target_month)
    .toLocaleDateString("en-US", options)
    .split("/")
    .reverse()
    .join("-");
};

export const formatForDateMsgField = (sweep: Sweep): string => {
  if (sweep.status === SweepstakeStatus.Live) {
    return `for ${sweep.start_date}`;
  }
  return `for ${formatDateSweep(sweep.target_month)}`;
};

type SweepLinkProps = {
  sweep: SweepstakePresenter;
  path: string;
  collapsed?: boolean;
};

interface SweepDetailsProps {
  sweep: SweepstakePresenter;
}

const SweepBrands = ({ sweep }: SweepDetailsProps): JSX.Element => {
  return (
    <ul className="sweep-link__brands">
      {sweep.confirmed_interested_brands?.map((brand) => (
        <li key={brand.id} className="sweep-link__brand bold">
          <a
            href={brand.url}
            rel="noreferrer"
            target="_blank"
            className="sweep-link__brand-name"
          >
            {brand.name}
          </a>
          <ul className="sweep-link__brand-detail">
            <li className="sweep-link__brand-detail__item">
              {brand.sales_blurb_description}
            </li>
          </ul>
        </li>
      ))}
    </ul>
  );
};

const SweepDetails = ({ sweep }: SweepDetailsProps): JSX.Element => {
  return (
    !sweep.information_blacklisted && (
      <ul className="sweep-link__details">
        <li>
          <span className="bold">Target Audience: </span>
          {sweep.target_demographic}
        </li>
        <li>
          <span className="bold">Estimated Launch: </span>
          {formatDateYYYYMM(sweep)}
        </li>
        <li className="sweep-link__details-item">
          <span className="bold">Total Estimated Reach:</span>{" "}
          {sweep.estimated_reach_range}
        </li>
        <li>
          <span className="bold">Confirmed/Interested brands:</span>
          <SweepBrands sweep={sweep} />
        </li>
        <p className="sweep-link__message">
          *Launch timing and themes subject to change based on brand grouping.
          We optimize for reach and brand alignment.
        </p>
      </ul>
    )
  );
};

export const SweepLink = ({
  sweep,
  path,
  collapsed,
}: SweepLinkProps): JSX.Element => {
  const { setSweep } = useContext(SweepContext);
  const [isCollapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    setCollapsed(!!collapsed);
  }, [collapsed]);

  const handleClick = () => setCollapsed(!isCollapsed);

  return (
    <div className="sweep-link">
      <Link
        to={{
          pathname: path,
          search: `?slot_id=${sweep.slot_id}`,
        }}
        onClick={() => setSweep(sweep)}
      >
        <span className="bold">Theme: </span>
        <span className="sweep-link__link">
          {sweep.name} is currently {sweep.displayStatus}{" "}
          {sweep.bp_completed && (
            <FontAwesomeIcon
              className="complete-assets__icon"
              icon={faCheckCircle}
            />
          )}
        </span>
      </Link>
      <button onClick={handleClick}>
        <FontAwesomeIcon
          className={`collapse-button ${
            isCollapsed ? "" : "collapse-button--collapse"
          }`}
          icon={faCaretRight}
        />
      </button>
      {!isCollapsed && <SweepDetails sweep={sweep} />}
    </div>
  );
};
