import React, { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import StateIcon from "../../StateIcon/StateIcon";
import "./StepItem.scss";

interface ChecklistItemProps {
  path?: string;
  complete?: boolean;
  active?: boolean;
  title: string;
}

const StepItem: FC<ChecklistItemProps> = ({
  path,
  active,
  complete,
  title,
}) => {
  const content = (
    <>
      {complete && <StateIcon type="valid" />}
      {!complete && path && <StateIcon type="active" />}
      {!complete && !path && <StateIcon />}
      <span className="steps__step-item__title">{title}</span>
    </>
  );

  return (
    <li
      className={clsx(
        "steps__step-item",
        active && "steps__step-item--selected"
      )}
    >
      {path ? (
        <Link className="steps__step-item__link" to={path}>
          {content}
        </Link>
      ) : (
        content
      )}
    </li>
  );
};

export default StepItem;
