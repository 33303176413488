import React from "react";
import { ErrorMessage, Formik, Form, Field } from "formik";
import validationSchema from "./validations";

export interface Values {
  password: string;
  confirmPassword: string;
}

type Props = {
  error?: string;
  onSubmit: (values: Values) => void;
};

const ResetPasswordForm = ({ error, onSubmit }: Props): JSX.Element => {
  const initialValues: Values = {
    password: "",
    confirmPassword: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className="reset-password-form__form">
        <label className="reset-password-form__form-group">
          <span className="reset-password-form__form-label">
            Create Password:
          </span>
          <Field name="password" type="password" aria-label="password" />
          <ErrorMessage
            name="password"
            component="div"
            className="form__control--error"
          />
        </label>
        <label className="reset-password-form__form-group">
          <span className="reset-password-form__form-label">
            Re-type Password:
          </span>
          <Field
            name="confirmPassword"
            type="password"
            aria-label="re-type password"
          />
          <ErrorMessage
            name="confirmPassword"
            component="div"
            className="form__control--error"
          />
        </label>

        {error && (
          <div className="capitalize-first-letter warning">{error}</div>
        )}

        <button
          className="btn btn--submit reset-password-form__button"
          type="submit"
        >
          Get Started
        </button>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
