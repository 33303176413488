import React, { ReactNode, useState } from "react";
import { User } from "../../apiClient/data-contracts";

import UserContext from "../contexts/user";

interface UserProviderProps {
  children?: ReactNode;
}

const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const [user, setUser] = useState<User>(null);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
