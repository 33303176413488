import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { CplReport, Sweep, SweepReport } from "../apiClient/data-contracts";
import { Reports } from "../apiClient/Report";
import { Company } from "../DataTypes";
import queryKeys from "../reactQuery/queryKeys";
import { useAuth } from "../state/auth";
import { useLoading } from "../state/loader";
import { CustomError, handleError } from "../utils/errorHandler";
import { errorToast } from "../utils/toast";

interface IUseSweepsReport {
  data: SweepReport;
  error: unknown;
  isLoading: boolean;
}

export function useSweepsReport(sweep: Sweep): IUseSweepsReport {
  const { loginInfo } = useAuth();

  const fetchReport = async (): Promise<SweepReport> => {
    if (sweep) {
      const { data } = await new Reports().getSweepReportApi(sweep.slot_id, {
        headers: {
          Authorization: loginInfo.token,
        },
      });
      return data;
    }
  };
  const { data, error, isLoading } = useQuery(
    [queryKeys.CURRENT_SWEEPS_REPORT, sweep?.slot_id],
    fetchReport
  );
  return { data, error, isLoading };
}

interface IUsePastSweepsReport {
  data: SweepReport[];
  error: unknown;
  isLoading: boolean;
}

export function usePastSweepsReport(sweeps: Sweep[]): IUsePastSweepsReport {
  const { loginInfo } = useAuth();

  const fetchReport = async (): Promise<SweepReport[]> => {
    if (sweeps.length) {
      const slotIds = sweeps.map((sweep) => sweep.slot_id);
      return await Promise.all(
        slotIds.map(async (slotId) => {
          const { data } = await new Reports().getSweepReportApi(slotId, {
            headers: { Authorization: loginInfo.token },
          });
          return data;
        })
      );
    }
  };

  const { data, error, isLoading } = useQuery(
    [queryKeys.PAST_SWEEPS_REPORT, sweeps.length],
    fetchReport
  );
  return { data, error, isLoading };
}

// TODO: refactor this function to use the query client
export const useCplReport = (company: Company): CplReport => {
  const [cplReport, setCplReport] = useState<CplReport>(null);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  useEffect(() => {
    if (!company) {
      return;
    }
    const fetchReport = async () => {
      try {
        setLoading(true);
        const { data } = await new Reports().getCplReportApi(company.id, {
          headers: {
            Authorization: loginInfo.token,
          },
        });
        setCplReport(data);
      } catch (error) {
        errorToast(handleError(error as CustomError).message);
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [company, loginInfo, setLoading]);

  return cplReport;
};
