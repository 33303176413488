import { Payment } from "apiClient/data-contracts";
import { BrandSigning } from ".";

export enum ProcessPaymentState {
  NotProcessing = 1,
  Processing,
  Success,
  Failure,
}

export interface ParsedPayment {
  number: number;
  expiration_date: string;
  code: string;
  billing_zip_code: string;
}

export interface CreditCardValues {
  credit_card_number: string;
  cvv: string;
  expiration_date: string;
  billing_zip_code: string;
  sow_signed: boolean;
}

export interface ProcessPaymentArgs {
  brandSigning: BrandSigning;
  creditCardValues: CreditCardValues;
  onSuccess: (payment: Payment) => void;
  onErrors: (errors: string[]) => void;
  onAnyCase?: () => void;
  token: string;
}

export interface CreditCardErrors {
  number: string[];
  code: string[];
  expiration_date: string[];
  transaction_errors: string[];
}

export interface PaymentStatusInfo {
  title: string;
  paragraph: JSX.Element;
}
