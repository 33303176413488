import { createContext } from "react";
import { ProcessPaymentState } from "../../DataTypes";

type CheckoutContextValue = {
  paymentState: ProcessPaymentState;
  setPaymentState: (state: ProcessPaymentState) => void;
};

const CheckoutContext = createContext<CheckoutContextValue>({
  paymentState: ProcessPaymentState.NotProcessing,
  setPaymentState: null,
});

export default CheckoutContext;
