import { useEffect, useState } from "react";

import { Auth } from "../apiClient/Auth";
import { User } from "../apiClient/data-contracts";

import { useAuth } from "../state/auth";
import { useLoading } from "../state/loader";
import { CustomError, handleError } from "../utils/errorHandler";

export const useUser = (): User => {
  const [user, setUser] = useState<User>(null);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const { data } = await new Auth().getGetUser({
          headers: {
            Authorization: loginInfo.token,
          },
        });
        setUser(data);
      } catch (error) {
        console.error(handleError(error as CustomError).message);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, [loginInfo, setLoading]);

  return user;
};
