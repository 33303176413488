import React, { ReactNode, useState } from "react";

import SlotContext from "../contexts/slot";
import { Slot } from "../../DataTypes";

interface SlotProviderProps {
  children?: ReactNode;
}

const SlotProvider = ({ children }: SlotProviderProps): JSX.Element => {
  const [slot, setSlot] = useState<Slot>(null);
  return (
    <SlotContext.Provider value={{ slot, setSlot }}>
      {children}
    </SlotContext.Provider>
  );
};

export default SlotProvider;
