import { BrandSigning, Company } from "../../DataTypes";

export const getCurrentBrandSigning = (
  company: Company
): BrandSigning | null => {
  if (!company || company.brand_signings.length === 0) {
    return null;
  }
  return company.brand_signings.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];
};
