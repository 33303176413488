import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { useChecklistValidationSchema } from "./ChecklistValidation";
import SpinnerButton from "../../SpinnerButton/SpinnerButton";
import OnboardingCheckbox from "../Checkbox/OnboardingCheckbox";
import {
  getFlowType,
  OnboardingFlowType,
  OnboardingStatus,
  useOnboardingFlow,
} from "../../../pages/Onboarding/OnboardingStatus";
import paths from "../../../Paths";
import CompanyContext from "../../../state/contexts/company";
import { getCurrentBrandSigning } from "../../StatementOfWork/utils";
import { Companies } from "../../../apiClient/Company";
import { CustomError, handleError } from "../../../utils/errorHandler";
import { useLoading } from "../../../state/loader";
import { useAuth } from "../../../state/auth";
import {
  getAcceptedATC,
  getAvailableATC,
  isChecklistComplete,
} from "./ChecklistUtils";
import ErrorFormDisplay from "../../ErrorFormDisplay/ErrorFormDisplay";
import { isDefined } from "../../../utils";
import Loader from "../BillingDetails/BillingDetails";
import "./Checklist.scss";
import { getCompany } from "../../../utils/getCompany";
import redirectionTrigger from "../../../utils/redirectionTrigger";
import { useUser } from "../../../hooks/useUser";
import { errorToast } from "../../../utils/toast";
import { hasToPay } from "../Checkout/Checkout";
import { bpContactEmail } from "utils/constants";

interface ChecklistFormValues {
  readonly tcGroup: string[];
  readonly terms: boolean;
  readonly sow: boolean;
}

const Checklist = (): JSX.Element => {
  const { company, setCompany } = useContext(CompanyContext);
  const user = useUser();
  const { setLoading, loading } = useLoading();
  const { loginInfo } = useAuth();
  const navigate = useNavigate();
  const [termsCondDownloaded, setTermsCondDownloaded] =
    useState<boolean>(false);
  const [sowDownloaded, setSowDownloaded] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const flowType = getFlowType(company, user);

  useOnboardingFlow(OnboardingStatus.CHECKLIST);

  const nextPage = useCallback(() => {
    const nextUrl =
      flowType === OnboardingFlowType.JUST_SIGNING
        ? paths.home.url
        : paths.checkout.url;
    redirectionTrigger(navigate, nextUrl);
  }, [navigate, flowType]);

  const brandSigning = useMemo(() => {
    return company && getCurrentBrandSigning(company);
  }, [company]);

  const tcCheckboxValues = useMemo(
    () => ({
      ...getAvailableATC(company),
      ...getAcceptedATC(company),
    }),
    [company]
  );

  const listOfTC = useMemo(
    () => Object.keys(tcCheckboxValues),
    [tcCheckboxValues]
  );

  const isSoWSigned = useMemo(() => {
    return brandSigning && isDefined(brandSigning.date_agreement);
  }, [brandSigning]);

  const initialValues = useMemo(() => {
    return {
      tcGroup: isDisabled
        ? listOfTC
        : listOfTC.filter((tc) => tcCheckboxValues[tc]),
      terms: isDisabled,
      sow: isSoWSigned,
    };
  }, [listOfTC, tcCheckboxValues, isDisabled, isSoWSigned]);

  const validationSchema = useChecklistValidationSchema(listOfTC);

  useEffect(() => {
    setIsDisabled(isChecklistComplete(company));
  }, [company]);

  const onSubmit = async (values: ChecklistFormValues) => {
    if (isDisabled && isSoWSigned) {
      return nextPage();
    }

    setLoading(true);
    const headers = { Authorization: loginInfo.token };
    const companyClient = new Companies();

    if (isDisabled && !isSoWSigned) {
      await companyClient
        .postCompanySowSigningApi(
          brandSigning.id,
          company.id,
          { is_downloaded: sowDownloaded },
          { headers }
        )
        .catch((error) =>
          errorToast(handleError(error as CustomError).message)
        );
      return nextPage();
    }

    await Promise.all([
      companyClient
        .postCompanyMsaSigningApi(
          company.id,
          {
            is_downloaded: termsCondDownloaded,
            acknowledged_checks: JSON.stringify(values.tcGroup),
          },
          { headers }
        )
        .catch((error) =>
          errorToast(handleError(error as CustomError).message)
        ),
      companyClient
        .postCompanySowSigningApi(
          brandSigning.id,
          company.id,
          { is_downloaded: sowDownloaded },
          { headers }
        )
        .catch((error) =>
          errorToast(handleError(error as CustomError).message)
        ),
    ]);

    // We need to force-fetch the updated company, because the signing API doesn't return it
    const companyResponse = await getCompany(loginInfo.token);
    if (isDefined(companyResponse)) {
      setCompany(companyResponse);
    }
    setLoading(false);
    nextPage();
  };

  const areAllChecksComplete = useCallback(
    (values: ChecklistFormValues) => {
      return isDisabled || values.tcGroup.length === listOfTC.length;
    },
    [isDisabled, listOfTC]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="onboarding-checklist onboarding-form-container">
      {listOfTC.length > 0 ? (
        <>
          <h1 className="onboarding-title">
            On behalf of {company?.name}, you agree:
          </h1>
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, errors, isSubmitting, submitCount, isValid }) => (
              <Form>
                {listOfTC.map((tc) => {
                  const id = tc.replace(/\W/g, "");
                  return (
                    <OnboardingCheckbox
                      key={id}
                      id={id}
                      name="tcGroup"
                      value={tc}
                      disabled={isDisabled}
                    >
                      {tc}
                    </OnboardingCheckbox>
                  );
                })}
                {areAllChecksComplete(values) && (
                  <>
                    <OnboardingCheckbox
                      id="terms"
                      name="terms"
                      disabled={isDisabled}
                    >
                      I agree to the FindKeep.Love{" "}
                      {isDisabled ? (
                        <span className="checkbox-link">
                          Advertiser Terms & Conditions
                        </span>
                      ) : (
                        <a
                          className="checkbox-link"
                          href={company && company.msa_document_full_path}
                          onClick={() => setTermsCondDownloaded(true)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Advertiser Terms & Conditions
                        </a>
                      )}
                    </OnboardingCheckbox>
                    <OnboardingCheckbox
                      id="sow"
                      name="sow"
                      disabled={isSoWSigned}
                    >
                      I agree to the FindKeep.Love{" "}
                      {isSoWSigned ? (
                        <span className="checkbox-link">Statement of Work</span>
                      ) : (
                        <a
                          className="checkbox-link"
                          href={
                            brandSigning && brandSigning.sow_document_full_path
                          }
                          onClick={() => setSowDownloaded(true)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Statement of Work
                        </a>
                      )}
                    </OnboardingCheckbox>
                  </>
                )}
                <SpinnerButton
                  label={
                    hasToPay(brandSigning)
                      ? "Proceed to Checkout"
                      : "Proceed to Home"
                  }
                  className="onboarding-button"
                />
                <ErrorFormDisplay
                  errors={errors}
                  isSubmitting={isSubmitting}
                  submitCount={submitCount}
                  isValid={isValid}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <h1 className="onboarding-title no-tc-title">
          {`Looks like we’re still preparing your plan. Please reach out to us at
          ${bpContactEmail} for more information.`}
        </h1>
      )}
      <Link className="onboarding-back-btn" to={paths.companyInformation.url}>
        Back
      </Link>
    </div>
  );
};

export default Checklist;
