import React, { ReactNode, useState, useEffect } from "react";

import { CPL } from "../../DataTypes";
import { useCpl } from "../../hooks/useCpl";
import CplContext from "../contexts/cpl";

interface CplProverProps {
  children?: ReactNode;
}

const CplProvider = ({ children }: CplProverProps): JSX.Element => {
  const [cpl, setCpl] = useState<CPL>(null);
  const cplFromApi = useCpl();

  useEffect(() => {
    cplFromApi && setCpl(cplFromApi);
  }, [cplFromApi]);

  return (
    <CplContext.Provider value={{ cpl, setCpl }}>
      {children}
    </CplContext.Provider>
  );
};

export default CplProvider;
