/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CplReport, SweepReport } from "./data-contracts";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Reports extends HttpClient {
  /**
   * No description
   *
   * @tags report
   * @name GetCplReportApi
   * @summary Get all CPLs Optins data from a Company
   * @request GET:/report/cpls/{company_id}/
   * @secure
   */
  getCplReportApi = (company_id: number, params?: RequestParams) =>
    this.request<CplReport, any>(
      `/report/cpls/${company_id}/`,
      "GET",
      params,
      null,
      BodyType.Json,
      true
    );
  /**
   * No description
   *
   * @tags report
   * @name GetCplOptinsDownloadApi
   * @summary Download CPLs optins file
   * @request GET:/report/cpls/{cpl_id}/optins-download
   * @secure
   */
  getCplOptinsDownloadApi = (cpl_id: number, params?: RequestParams) =>
    this.request<any, any>(
      `/report/cpls/${cpl_id}/optins-download`,
      "GET",
      params,
      null,
      BodyType.Json,
      true
    );
  /**
   * No description
   *
   * @tags report
   * @name GetSweepReportApi
   * @summary Get optins data
   * @request GET:/report/sweepstakes/{slot_id}
   * @secure
   */
  getSweepReportApi = (slot_id: number, params?: RequestParams) =>
    this.request<SweepReport, any>(
      `/report/sweepstakes/${slot_id}`,
      "GET",
      params,
      null,
      BodyType.Json,
      true
    );
  /**
   * No description
   *
   * @tags report
   * @name GetSweepOptinsDownloadApi
   * @summary Download optins file
   * @request GET:/report/sweepstakes/{sweep_id}/optins-download
   * @secure
   */
  getSweepOptinsDownloadApi = (sweep_id: number, params?: RequestParams) =>
    this.request<any, any>(
      `/report/sweepstakes/${sweep_id}/optins-download`,
      "GET",
      params,
      null,
      BodyType.Json,
      true
    );
}
