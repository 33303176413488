import React, { useContext, useState } from "react";

import EspForm from "./Form";
import Loader from "../Loader/Loader";

import { Cpl } from "../../apiClient/Cpl";

import CplContext from "../../state/contexts/cpl";
import { EspPayload } from "../../DataTypes";
import { useLoading } from "../../state/loader";
import { useAuth } from "../../state/auth";
import { CustomError, handleError } from "../../utils/errorHandler";
import { isESPComplete } from "../../pages/CostPerLead/utils";
import { useNavigate } from "react-router-dom";
import paths from "../../Paths";
import { bpContactEmail } from "../../utils/constants";
import { errorToast } from "../../utils/toast";

const CplEsp = (): JSX.Element => {
  const { cpl, setCpl } = useContext(CplContext);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [err, setErr] = useState("Error");
  const [supportedEsp, setSupportedESP] = useState(false);
  const navigate = useNavigate();

  if (!cpl) {
    navigate(paths.home.url);
    return null;
  }

  const onSubmit = async (values: EspPayload): Promise<void> => {
    const cplClient = new Cpl();

    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure the information is correct?")) {
        setLoading(true);
        const { data } = await cplClient.patchEspApi(
          cpl.id,
          { ...values, esp_integration_enable: true },
          {
            headers: {
              Authorization: loginInfo.token,
            },
          }
        );
        setSubmitted(true);
        setCpl(data);
        setValid(true);

        if (data.esp_supported) {
          setSupportedESP(true);
        }
        // TODO: Add nextIncompleteSection utils
      }
    } catch (error) {
      const customError = error as CustomError;
      if (customError.status === 417) {
        setErr(handleError(customError).message);
        setSubmitted(true);
        setValid(false);
      } else {
        errorToast(handleError(error as CustomError).message);
      }
    } finally {
      setLoading(false);
    }
  };

  return cpl ? (
    <div>
      <h1 className="heading-primary heading__font">Email Integration</h1>
      <article>
        <p>
          Use our easy API integration to set up a real-time sync of your CPL
          entries to your email provider. Immediate email sync and welcome flows
          are proven to increase conversion and open rates.
        </p>
        <p>
          Confused? Please reach out to{" "}
          <a href={`mailto:${bpContactEmail}`} className="link">
            {bpContactEmail}
          </a>{" "}
          for help
        </p>
      </article>
      <EspForm
        onSubmit={onSubmit}
        dataSource={cpl}
        isComplete={isESPComplete}
        valid={valid}
        err={err}
        supportedEsp={supportedEsp}
        submitted={submitted}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default CplEsp;
