import React from "react";
import paths from "../../Paths";
import { bpContactEmail } from "../../utils/constants";

import UpcomingSweeps from "../UpcomingSweeps/UpcomingSweeps";

import "./SponsorChecklist.scss";

const SponsorChecklist = (): JSX.Element => {
  return (
    <div className="sponsor-checklist">
      <h1 className="heading-primary sponsor-checklist__title">
        FindKeep.Love Brand Portal
      </h1>
      <p className="sponsor-checklist__subtitle">
        The FindKeep.Love Brand Portal is your one-stop destination for
        providing assets and preparing for your upcoming sweepstakes!
      </p>
      <div className="section">
        <h3 className="section__title">Upcoming sweeps</h3>
        <p className="section__text">
          Each sweepstakes requires brand logo, lifestyle images and other
          assets to participate. Please click on each sweepstakes link below to
          upload the required assets and information needed.
        </p>
        <UpcomingSweeps redirectionPath={paths.sweepstakeDeliverables.url} />
      </div>
      <div className="section">
        <h3 className="section__title">Interested in an upcoming sweeps?</h3>
        <p className="section__text">
          Sweepstakes are a great way to grow your email and SMS lists and
          acquire more customers!
        </p>
        <div className="section__box">
          <p className="section__text">
            {" "}
            To book a future sweepstakes, please contact your booking manager or{" "}
            <a href={`mailto:${bpContactEmail}`}>{bpContactEmail}</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SponsorChecklist;
