import { Field, useField } from "formik";
import React, { FC, useMemo } from "react";
import FileInput from "../../../../../../../Components/v2/FileInput/FileInput";
import UploadPreview from "../../../../../../../Components/v2/UploadPreview/UploadPreview";

const VideoInput: FC = () => {
  const [noVideoField] = useField<boolean>("no_cpl_videos");
  const [offerVideo, { error, touched }, { setValue: setVideo }] =
    useField<File>("offer_video");
  const preview = useMemo(() => {
    if (offerVideo?.value) {
      return typeof offerVideo?.value === "string"
        ? offerVideo?.value
        : URL.createObjectURL(offerVideo?.value);
    }
    return null;
  }, [offerVideo?.value]);

  const showPreview = useMemo(() => {
    if (noVideoField.value === true) {
      return false;
    }
    if (!!error && touched) {
      return false;
    }
    return !!preview;
  }, [preview, error, touched, noVideoField.value]);

  return (
    <>
      <FileInput
        name="offer_video"
        accept="video/*,.mov,.mp4"
        label="Video"
        hideInput={noVideoField.value === true || showPreview}
        instructions={
          <>
            <p>
              Please upload <strong>a promo video</strong> for us to use for
              promoting your offer/products. It is recommended to use a video{" "}
              <strong>with horizontal orientation</strong> and one that
              represents your brand, The volume will be defaulted to off, please
              use a video that provides clear visuals and does not depend on
              sound. If you opt to not use video, we will use one of the images
              instead.
            </p>
            <p>
              Recommended formats: MOV or MP4. If your video exceeds 15MB,
              please compress it{" "}
              <a
                href="https://www.youcompress.com/"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <div className="u-mb-medium">
              <label>
                <Field type="checkbox" name={noVideoField.name} />
                Use images instead of a video in my offer
              </label>
            </div>
          </>
        }
      />
      {showPreview && (
        <UploadPreview
          type="video"
          value={preview}
          onRemove={() => {
            setVideo(undefined);
          }}
        />
      )}
    </>
  );
};

export default VideoInput;
