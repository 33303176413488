import { OfferType } from "../../../../DataTypes";
import { OfferPromoValues } from "../../OfferPromoCodeForm/OfferPromoCodeForm";
import { getDateAsISOString } from "../../../../utils";

export const cleanValuesOnOfferSelection = (
  values: OfferPromoValues
): OfferPromoValues | unknown => {
  const OFFER_TYPE = {
    [OfferType.OffOffer]: {
      ...values,
      free_gift: "",
      free_gift_min_purchase_qualifications: 0,
      expiration: getDateAsISOString(values.expiration),
    },
    [OfferType.FreeGift]: {
      ...values,
      off_amount: 0,
      off_amount_min_purchase_amount: 0,
      expiration: getDateAsISOString(values.expiration),
    },
  };

  return OFFER_TYPE[values.offer_type] || new Error("Invalid offer type");
};
