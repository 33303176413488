import React from "react";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MenuButton.scss";

interface MenuButtonProps {
  onClick: () => void;
}

const MenuButton = ({ onClick }: MenuButtonProps): JSX.Element => {
  return (
    <button onClick={onClick} className="menu-button">
      <FontAwesomeIcon icon={faBars} size="2x" />
    </button>
  );
};

export default MenuButton;
