import React from "react";
import { ErrorMessage } from "formik";
import clsx from "clsx";
import "./ValidationMessage.scss";

interface ValidationMessageProps {
  name: string;
  componentType: "div" | "span";
  customClassName?: string[];
}

const ValidationMessage = ({
  name,
  componentType = "div",
  customClassName,
}: ValidationMessageProps): JSX.Element => {
  return (
    <ErrorMessage
      name={name}
      component={componentType}
      className={clsx("validation-message", customClassName)}
    />
  );
};

export default ValidationMessage;
