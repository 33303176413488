import { createContext } from "react";
import { Slot } from "../../DataTypes";

interface SlotContextValue {
  slot: Slot;
  setSlot: (slot: Slot) => void;
}

const SlotContext = createContext<SlotContextValue>({
  slot: null,
  setSlot: null,
});

export default SlotContext;
