import React from "react";
import { Form, Formik } from "formik";

import "./CompanyInformation.scss";
import {
  BrandInformationPayload,
  Company,
  States,
} from "../../../DataTypes";
import OnboardingInput from "../Input/OnboardingInput";
import companyInformationValidationSchema from "./CompanyInformationValidation";
import { useCompanyInformationInitialValues } from "./CompanyInformationInitialValues";
import { User } from "../../../apiClient/data-contracts";
import SpinnerButton from "../../SpinnerButton/SpinnerButton";
import ErrorFormDisplay from "../../ErrorFormDisplay/ErrorFormDisplay";

interface Props {
  company: Company;
  user: User;
  onSubmit: (values: BrandInformationPayload) => Promise<void>;
}

const CompanyInformationForm = ({ onSubmit, company, user }: Props): JSX.Element => {
  const initialValues = useCompanyInformationInitialValues(company, user);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={companyInformationValidationSchema}
    >
      {({ isValid, errors, isSubmitting, submitCount }) => (
        <Form>
          <article className="onboarding-form-block">
            <h1 className="onboarding-title">Your Company Information</h1>
            <div className="onboarding-form-row">
              <OnboardingInput name="parent_company" label="Parent Company Name" />
              <OnboardingInput name="name" label="Brand Name" disabled />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="street_address_1" label="Street Address 1*" />
              <OnboardingInput name="street_address_2" label="Street Address 2" />
            </div>
            <div className="onboarding-form-row location-row">
              <OnboardingInput name="city" label="City*" fieldClass="input-city" />
              <OnboardingInput select name="state" label="State*" fieldClass="input-state">
                {Object.entries(States).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </OnboardingInput>
              <OnboardingInput name="zip_code" label="Zip Code*" fieldClass="input-zip" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="phone" label="Work Phone Number*" />
              <OnboardingInput name="phone_extension" label="Extension" fieldClass="input-extension" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="url" label="Website*" fieldClass="input-website" />
            </div>
          </article>

          <article className="onboarding-form-block">
            <h1 className="onboarding-title">Your Contact Information</h1>
            <div className="onboarding-form-row">
              <OnboardingInput name="first_name" label="First Name*" />
              <OnboardingInput name="last_name" label="Last Name*" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="position_title" label="Job Title*" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput type="email" name="email_address_1" label="Email*" disabled />
              <OnboardingInput type="email" name="email_address_2" label="Alternative Email" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="phone_1_number" label="Work Phone Number*" />
              <OnboardingInput name="phone_1_extension" label="Extension" fieldClass="input-extension" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="phone_2_number" label="Mobile Phone*" />
            </div>
            <p className="onboarding-info">
              FindKeep.Love may communicate via SMS for time-sensitive information if contact is unavailable by email
            </p>
          </article>

          <article className="onboarding-form-block">
            <h1 className="onboarding-title">Alternative Contact Information</h1>
            <p className="onboarding-info">
              Provide an additional contact within your company for communication
            </p>
            <div className="onboarding-form-row">
              <OnboardingInput name="secondary_first_name" label="First Name*" />
              <OnboardingInput name="secondary_last_name" label="Last Name*" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="secondary_position_title" label="Job Title*" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput type="email" name="secondary_email_address_1" label="Email*" />
              <OnboardingInput type="email" name="secondary_email_address_2" label="Alternative Email" />
            </div>
            <div className="onboarding-form-row">
              <OnboardingInput name="secondary_phone_1_number" label="Work Phone Number*" />
            </div>
            <p className="onboarding-info">
              FindKeep.Love may communicate via SMS for time-sensitive information if contact is unavailable by email
            </p>
          </article>
          <SpinnerButton label="Next" className="onboarding-button" />
          <ErrorFormDisplay
            errors={errors}
            isSubmitting={isSubmitting}
            submitCount={submitCount}
            isValid={isValid}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CompanyInformationForm;
