/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CompanyPayload, ContactPayload, PrizingOptions } from "../DataTypes";
import { Company } from "../DataTypes";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Companies extends HttpClient {
  /**
   * No description
   *
   * @tags company
   * @name GetCompanyListApi
   * @summary List all brands for the logged in contact
   * @request GET:/company/
   * @secure
   */
  getCompanyListApi = (params?: RequestParams) =>
    this.request<Company[], any>(
      `/company/`,
      "GET",
      params,
      null,
      BodyType.Json,
      true
    );
  /**
   * No description
   *
   * @tags company
   * @name PatchCompanyApi
   * @summary Update the company
   * @request PATCH:/company/{company_id}
   * @secure
   */
  patchCompanyApi = (
    company_id: number,
    data: CompanyPayload,
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/company/${company_id}`,
      "PATCH",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags company
   * @name PostCompanyContactApi
   * @summary Add a new Contact to a Company
   * @request POST:/company/{company_id}/create_contact
   * @secure
   */
  postCompanyContactApi = (
    company_id: number,
    payload: ContactPayload,
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/company/${company_id}/create_contact`,
      "POST",
      params,
      payload,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags company
   * @name PostCompanyMsaSigningApi
   * @summary Current user signs a MSA for a Company
   * @request POST:/company/{company_id}/msa_signing
   * @secure
   */
  postCompanyMsaSigningApi = (
    company_id: number,
    data: { is_downloaded?: boolean; acknowledged_checks?: string },
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/company/${company_id}/msa_signing`,
      "POST",
      params,
      data,
      BodyType.FormData,
      true
    );
  /**
   * No description
   *
   * @tags company
   * @name PostCompanySowSigningApi
   * @summary Current user signs a SOW for a Company
   * @request POST:/company/{company_id}/sow_signing/{brand_signing_id}
   * @secure
   */
  postCompanySowSigningApi = (
    brand_signing_id: number,
    company_id: number,
    data: { is_downloaded?: boolean; prizing_option?: PrizingOptions },
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/company/${company_id}/sow_signing/${brand_signing_id}`,
      "POST",
      params,
      data,
      BodyType.FormData,
      true
    );
}
