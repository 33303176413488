import "./CompleteModal.scss";
import React, { useState, useEffect } from "react";

import Modal from "../../../Components/Modal/Modal";
import { Slot, Sweep } from "../../../DataTypes";

import { isSponsorChecklistComplete } from "../../SponsorChecklist/utils";
import { bpContactEmail } from "../../../utils/constants";

interface CompleteModalProps {
  slot: Slot;
  sweep: Sweep;
}

const ThanksComp = (): JSX.Element => {
  return (
    <div className="complete-modal">
      <h1 className="heading-primary heading__font">Thank You!</h1>
      <h2 className="heading-subtitle">You have now completed the form</h2>
      <p>
        FindKeep.Love Team will reach out if any additional infromation is
        needed
      </p>
      <p className="heading-subtitle__no-bold">Have Questions?</p>
      <span>
        Please contact the team directly at{" "}
        <a href={`mailto:${bpContactEmail}`}>{bpContactEmail}</a>
      </span>
    </div>
  );
};

const CompleteModal = ({ slot, sweep }: CompleteModalProps): JSX.Element => {
  const [isModalShow, setIsModalShow] = useState<boolean>(false);

  useEffect(() => {
    setIsModalShow(isSponsorChecklistComplete(slot, sweep));
  }, [slot, sweep]);

  return (
    <Modal
      childComp={<ThanksComp />}
      show={isModalShow}
      onClose={() => {
        setIsModalShow(!isModalShow);
      }}
    />
  );
};

export default CompleteModal;
