import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { SlotPayload } from "../../DataTypes";
import { Slots } from "../../apiClient/Slot";

import { useLoading } from "../../state/loader";
import { useAuth } from "../../state/auth";
import SlotContext from "../../state/contexts/slot";
import SweepContext from "../../state/contexts/sweep";

import PromotionForm from "./Form";

import nextIncompleteSection from "../../utils/nextSectionRedirection";
import { sponsorChecklistSectionOrder } from "../../pages/SponsorChecklist/utils";

import paths from "../../Paths";

import "./SweepstakePromotion.scss";
import { errorToast } from "../../utils/toast";
import { CustomError, handleError } from "../../utils/errorHandler";

const slotClient = new Slots();

const SweepstakePromotion = (): JSX.Element => {
  const { slot, setSlot } = useContext(SlotContext);
  const { sweep } = useContext(SweepContext);
  const { loginInfo } = useAuth();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  if (!slot) {
    navigate(paths.sponsorChecklist.url);
    return null;
  }

  const onSubmit = async (values: SlotPayload) => {
    try {
      setLoading(true);
      const response = await slotClient.patchSlotApi(slot.id, values, {
        headers: { Authorization: loginInfo.token },
      });
      setLoading(false);
      setSlot(response.data);
      alert("Successfully updated");
      nextIncompleteSection(
        navigate,
        sponsorChecklistSectionOrder(slot, sweep)
      );
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="promotion">
      <h3 className="promotion__thanks-msg heading-tertiary">
        Thanks for booking your next sweepstakes!
      </h3>
      <h1 className="promotion__title heading-primary">Booking Confirmed</h1>
      <p className="promotion__description">
        We're so excited for your upcoming sweepstake! Please complete the
        fields below to share the dates you plan to promote the sweepstakes to
        your email base. Remember: as a co-sponsor, you are required to announce
        the sweepstakes to your email base within the first two weeks of the
        start date.
      </p>
      <PromotionForm slot={slot} sweep={sweep} onSubmit={onSubmit} />
    </div>
  );
};

export default SweepstakePromotion;
