import React from "react";
import clsx from "clsx";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "../../../../../state/loader";
import "./SubmitButton.scss";

type Props = {
  label?: string;
  className?: string;
  loadingLabel?: string;
};

const SubmitButton = ({
  label = "Submit",
  loadingLabel,
  className,
}: Props): JSX.Element => {
  const { loading } = useLoading();
  return (
    <button
      className={clsx("submit-button", className)}
      type="submit"
      disabled={loading}
    >
      {loading && (
        <span className="submit-button__spinner">
          <FontAwesomeIcon className="fa-pulse" icon={faSpinner} />
        </span>
      )}
      {loadingLabel && loading ? loadingLabel : label}
    </button>
  );
};

export default SubmitButton;
