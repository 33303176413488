import "./TextArea.scss";

import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useState,
} from "react";
import clsx from "clsx";

type TextareaProps = {
  cols?: number;
  maxLength: number;
  defaultValue?: string;
  name: string;
  id: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
};

const TextArea: FC<TextareaProps> = ({
  defaultValue,
  maxLength,
  className,
  hasError,
  ...props
}) => {
  const [characters, setCharacters] = useState<string>(defaultValue || "");
  const [hasWarning, setHasWarning] = useState<boolean>(false);

  const onKeyUp = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>): void => {
      const value = (event.target as HTMLTextAreaElement).value;
      setCharacters(value);
      setHasWarning(value.length >= maxLength);
    },
    [maxLength]
  );

  return (
    <div className="textarea__wrapper">
      <textarea
        className={clsx("textarea", hasError && "textarea--error", className)}
        onKeyUp={onKeyUp}
        defaultValue={defaultValue}
        maxLength={maxLength}
        {...props}
      />
      <div
        className={clsx(
          "textarea__characters",
          hasWarning && "textarea__characters--warning"
        )}
      >
        {characters.length}/{maxLength}
      </div>
    </div>
  );
};

export default TextArea;
