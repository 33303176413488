/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContactPayload } from "../DataTypes";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Contacts extends HttpClient {
  /**
   * No description
   *
   * @tags contact
   * @name PatchContactApi
   * @summary Update the contact
   * @request PATCH:/contact/{contact_id}
   * @secure
   */
  patchContactApi = (
    contact_id: number,
    payload: ContactPayload,
    params?: RequestParams
  ) =>
    this.request<any, any>(
      `/contact/${contact_id}`,
      "PATCH",
      params,
      payload,
      BodyType.FormData,
      true
    );
}
