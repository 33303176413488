import { createContext } from "react";
import { Sweep } from "../../apiClient/data-contracts";

type SweepContextValue = {
  sweep: Sweep;
  setSweep: (sweep: Sweep) => void;
};

const SweepContext = createContext<SweepContextValue>({
  sweep: null,
  setSweep: null,
});

export default SweepContext;
