import React, { FC } from "react";
import { Field, FieldAttributes } from "formik";
import clsx from "clsx";
import "./Select.scss";

interface Props {
  showEmptyOption?: boolean;
  className?: string[];
}

type SelectProps = FieldAttributes<unknown> & Props;

const Select: FC<SelectProps> = ({
  className,
  children,
  showEmptyOption,
  name,
  ...selectProps
}): JSX.Element => (
  <Field
    as="select"
    className={clsx("form-field", className)}
    id={name}
    name={name}
    {...selectProps}
    data-testid="test_select_option"
  >
    {showEmptyOption ? (
      <option value="" defaultChecked>
        Select
      </option>
    ) : null}
    {children}
  </Field>
);
export default Select;
