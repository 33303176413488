export const email = {
  isRequired: "Email is required",
  wrongFormat: "Wrong email format",
};

export const password = {
  isRequired: "Password is required",
  confirm: "It's required to confirm the password",
  mustMatch: "Passwords must match",
};

export const requiredField = "Field is required";
