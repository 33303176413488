import React, { useState } from "react";

import { ErrorMessage, Field, Form, Formik, FormikConfig } from "formik";
import { Slot, SmsName, SmsPayload, SweepstakeStatus } from "../../DataTypes";

import DisplayIntegrationFields from "../DisplayIntegrationFields/DisplayIntegrationFields";
import ErrorFormDisplay from "../ErrorFormDisplay/ErrorFormDisplay";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import { isSMSComplete } from "../../pages/SponsorChecklist/utils";
import validationSchema from "./validations";

import "./Form.scss";

const noSmsValue: SmsName[] = ["No SMS", "None"];

type Props = {
  slot: Slot;
  sweepstatus: number;
  onSubmit: (values: SmsPayload) => void;
};

const SmsForm = ({ onSubmit, slot, sweepstatus }: Props): JSX.Element => {
  const [smsAccepted, setSmsAccepted] = useState<boolean>(
    slot.sms_wanted_by_brand
  );

  const baseFormikProps: FormikConfig<SmsPayload> = {
    onSubmit,
    validationSchema: validationSchema(slot.sms_setup.choices),
    initialValues: {
      sms_terms_and_conditions: slot.sms_terms_and_conditions || "",
      sms_privacy_policy: slot.sms_privacy_policy || "",
      sms_unsubscribe_code: slot.sms_unsubscribe_code || "",
      sms_unsubscribe_number: slot.sms_unsubscribe_number || "",
      sms_max_messages_month: slot.sms_max_messages_month || 0,
      sms_integration_enable: !!slot.sms_setup.is_enabled,
      sms_wanted_by_brand: slot.sms_wanted_by_brand,
    },
  };
  const formikProps = !noSmsValue.includes(slot.sms_setup.name)
    ? {
        ...baseFormikProps,
        initialValues: {
          ...baseFormikProps.initialValues,
          sms_provider_name: slot.sms_setup.name,
        },
      }
    : baseFormikProps;

  const disabled = sweepstatus === SweepstakeStatus.Live || isSMSComplete(slot);

  return (
    <div className="sms-form">
      <Formik {...formikProps}>
        {({
          values,
          handleChange,
          errors,
          submitCount,
          isSubmitting,
          isValid,
          setFieldValue,
        }) => (
          <Form data-testid="form">
            <div className="form-field">
              <label>
                Would you like to take advantage of SMS Integration?
              </label>
              <label>
                <input
                  type="radio"
                  name="sms_wanted_by_brand"
                  defaultValue={1}
                  disabled={disabled}
                  onChange={() => {
                    setSmsAccepted(true);
                    setFieldValue("sms_wanted_by_brand", true);
                  }}
                  defaultChecked={smsAccepted}
                />
                Yes, Please
              </label>
              <label>
                <input
                  type="radio"
                  name="sms_wanted_by_brand"
                  defaultValue={0}
                  disabled={disabled}
                  onChange={() => {
                    setSmsAccepted(false);
                    setFieldValue("sms_wanted_by_brand", false);
                  }}
                  defaultChecked={!smsAccepted}
                />
                No, Thank You
              </label>
            </div>
            {smsAccepted && (
              <>
                <article>
                  <h3 className="heading-tertiary">
                    1. Terms and Conditions and your company's Privacy Policy
                  </h3>
                  <p className="paragraph-secondary">
                    In order to support SMS opt-ins from your sweepstakes, the
                    cell phone carriers require that you identify on your
                    website the way you will use consumer cell phone numbers
                    through an explicit terms and conditions (in addition to
                    your existing privacy policy). We work with Trumpia for
                    sending SMS messages. Trumpia has provided guidance on best
                    practices for terms and conditions, as well as a sample
                    terms and conditions, in the attached document.{" "}
                    <a
                      href="https://cdn.shopify.com/s/files/1/0265/0062/8528/files/Opt-In-Compliance-Requirements.pdf?v=1614703921"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      (Click here for a sample preview).
                    </a>
                  </p>
                  <p className="paragraph-secondary">
                    While this is a useful guide, we recommend that you consult
                    with your attorney to make sure that you are legally
                    compliant and abide by the industry guidelines in how you
                    use SMS messages and what you say in your terms and
                    conditions.
                  </p>
                  <div className="form__control">
                    <div>
                      <label htmlFor="sms_terms_and_conditions">
                        Your company Terms and Conditions
                      </label>
                    </div>
                    <ErrorMessage
                      name="sms_terms_and_conditions"
                      component="div"
                      className="form__control--error"
                    />
                    <Field
                      name="sms_terms_and_conditions"
                      id="sms_terms_and_conditions"
                      className="form__input"
                      disabled={disabled}
                      placeholder="https://"
                    />
                  </div>
                  <div className="form__control">
                    <div>
                      <label htmlFor="sms_privacy_policy">
                        Your company Privacy Policy
                      </label>
                    </div>
                    <ErrorMessage
                      name="sms_privacy_policy"
                      component="div"
                      className="form__control--error"
                    />
                    <Field
                      name="sms_privacy_policy"
                      id="sms_privacy_policy"
                      className="form__input"
                      disabled={disabled}
                      placeholder="https://"
                    />
                  </div>
                </article>

                <article>
                  <h3 className="heading-tertiary">
                    2. What to expect and how to opt-out
                  </h3>
                  <p className="paragraph-secondary">
                    We also need to collect information to set expectations with
                    your subscribers so that they understand what to expect, and
                    how to opt-out of your list if needed.
                  </p>
                  <div className="form__control">
                    <div>
                      <label>Unsubscribe instructions</label>
                    </div>
                    <ErrorMessage
                      name="sms_unsubscribe_code"
                      component="div"
                      className="form__control--error"
                    />
                    <ErrorMessage
                      name="sms_unsubscribe_number"
                      component="div"
                      className="form__control--error"
                    />
                    <div className="form__unsubscribe">
                      <div>Text</div>
                      <Field
                        name="sms_unsubscribe_code"
                        id="sms_unsubscribe_code"
                        className="form__input"
                        disabled={disabled}
                        placeholder="CODE"
                      />
                      <div>To</div>
                      <Field
                        type="number"
                        min="0"
                        name="sms_unsubscribe_number"
                        id="sms_unsubscribe_number"
                        className="form__input"
                        disabled={disabled}
                        placeholder="123456"
                      />
                    </div>
                  </div>
                  <div className="form__control">
                    <div>
                      <label htmlFor="sms_max_messages_month">
                        Maximum number of messages sent per month
                      </label>
                    </div>
                    <ErrorMessage
                      name="sms_max_messages_month"
                      component="div"
                      className="form__control--error"
                    />
                    <Field
                      type="number"
                      min="0"
                      name="sms_max_messages_month"
                      id="sms_max_messages_month"
                      className="form__input"
                      disabled={disabled}
                    />
                  </div>
                </article>

                <article>
                  <h3 className="heading-tertiary">3. SMS Provider</h3>
                  <p className="paragraph-secondary">
                    FindKeep.Love supports direct integration with some SMS
                    providers. Please choose your provider below.
                  </p>

                  <div className="form__control">
                    <div>
                      <label htmlFor="sms_provider_name">
                        SMS Service Provider
                      </label>
                    </div>
                    {errors?.sms_provider_name && (
                      <div className="form__control--error">
                        {errors?.sms_provider_name}
                      </div>
                    )}
                    <Field
                      as="select"
                      name="sms_provider_name"
                      id="sms_provider_name"
                      className="form__input"
                      disabled={disabled}
                    >
                      {/* Attentive integration has been removed from options until the integration is complete Address in FKL-406 */}
                      <option value="">Select a provider</option>
                      {slot.sms_setup.choices.map(({ name, value }, idx) => ((name !== "Attentive") ?
                        <option key={idx} value={value}>
                          {name}
                        </option>: null
                      ))}
                    </Field>
                    <div className="message">
                      *Attentive is not a supported integration.
                    </div>
                  </div>
                  <DisplayIntegrationFields
                    choices={slot.sms_setup.choices}
                    currentIntegrationName={values.sms_provider_name}
                    slotIntegrationName={slot.sms_setup.name}
                    keys={slot.sms_setup.keys}
                    handleChange={handleChange}
                    disabled={disabled}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </article>
              </>
            )}
            {!disabled && (
              <div className="form__control">
                <SpinnerButton />
              </div>
            )}
            <ErrorFormDisplay
              errors={errors}
              isSubmitting={isSubmitting}
              submitCount={submitCount}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SmsForm;
