import { createContext } from "react";
import { Company } from "../../DataTypes";

type CompanyContextValue = {
  company: Company;
  setCompany: (company: Company) => void;
};

const CompanyContext = createContext<CompanyContextValue>({
  company: null,
  setCompany: null,
});

export default CompanyContext;
