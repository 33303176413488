import React, { useState, useEffect, useContext } from "react";

import CompanyContext from "../../state/contexts/company";
import { CplData, CplReport } from "../../apiClient/data-contracts";
import { useCplReport } from "../../hooks/useReport";
import { handleCplReportDownload } from "../../pages/Reports/utils";
import { useLoading } from "../../state/loader";

import BarChart from "../Charts/BarChart/BarChart";
import FileDownloader from "../FileDownloader/FileDownloader";
import Loader from "../Loader/Loader";
import NavigationArrows from "../NavigationArrows/NavigationArrows";

import "./CPLAnalytics.scss";
import { getStartDate } from "../Charts/utils";

const CPLAnalytics = (): JSX.Element => {
  const [cpls, setCpls] = useState<CplData[]>([]);
  const [currentCpl, setCurrentCpl] = useState<CplData>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const { company } = useContext(CompanyContext);
  const [cplReport, setCplReport] = useState<CplReport>();

  const { loading } = useLoading();

  const cplReportFromApi = useCplReport(company);

  useEffect(() => {
    cplReportFromApi && setCplReport(cplReportFromApi);
  }, [cplReportFromApi]);

  useEffect(() => {
    cplReport && setCpls(cplReport.cpls);
  }, [cplReport]);

  useEffect(() => {
    setCurrentCpl(cpls[currentIndex]);
  }, [cpls, currentIndex]);

  const startDate =
    currentCpl &&
    getStartDate(currentCpl.created_at_date, currentCpl.opt_ins_data);

  const changeCurrentCpl = (index: number) => {
    setCurrentIndex(index);
    setCurrentCpl(cpls[currentIndex]);
  };

  const NoCpls = (): JSX.Element => (
    <h2 className="heading-secondary">No cpls available</h2>
  );

  const Cpl = (): JSX.Element => (
    <section className="cpl-analytics">
      <h1 className="heading-primary cpl-analytics__title">
        Reporting &amp; Analytics
      </h1>
      <h3 className="cpl-analytics__subtitle">Opt-in report</h3>

      <article className="chart">
        <header className="chart__heading">
          <div className="chart__left-col">
            <h1 className="chart__title">{currentCpl.name}</h1>
          </div>
          <div className="chart__right-col">
            <p className="chart__totals">
              Total opt-ins to date:{" "}
              <span className="bold uppercase">{currentCpl.total_opt_ins}</span>
            </p>
            <FileDownloader
              report={currentCpl}
              downloadHandler={() => handleCplReportDownload(currentCpl)}
            />
          </div>
        </header>

        <div className="chart__viewer">
          <BarChart
            report={{
              startDate,
              endDate: new Date().toISOString(),
              optInsData: currentCpl.opt_ins_data,
              totalOptInsData: currentCpl.opt_ins_data,
              isCpl: true,
            }}
          />

          <footer className="chart__footer">
            <div className="chart__message">
              {currentCpl.esp_provider &&
                `We are currently feeding your opt-ins via API into your ${currentCpl.esp_provider} account`}
            </div>
          </footer>
        </div>
      </article>

      <article className="cpl-bidding">
        <h1 className="cpl-bidding__title">CPL Bidding</h1>
        <p className="cpl-bidding__paragraph">
          Please see below to view how many leads are left in your current plan.
        </p>
        <ul className="cpl-bidding__grid">
          <li className="cpl-bidding__grid-item">
            <h3 className="uppercase">Opt-in Count</h3>
            <p>
              <span className="bold">{currentCpl.total_opt_ins}</span>
              <br />
              Total Leads Purchased:{" "}
              <span className="bold">{currentCpl.budget}</span>
              <br />
              Total Leads Unfulfilled:{" "}
              <span className="bold">{Math.max(currentCpl.balance, 0)}</span>
              <br />
            </p>
          </li>
        </ul>
      </article>

      {cpls.length > 1 && (
        <NavigationArrows
          current={currentIndex}
          size={cpls.length}
          changeCurrentItemPosition={changeCurrentCpl}
        />
      )}
    </section>
  );

  const CPLReport = (): JSX.Element => {
    return cpls.length > 0 && currentCpl ? <Cpl /> : <NoCpls />;
  };

  return loading ? <Loader /> : <CPLReport />;
};

export default CPLAnalytics;
