import React from "react";
import { Link } from "react-router-dom";

import "./Explainer.scss";
import paths from "../../../Paths";
import { OnboardingStatus, useOnboardingFlow } from "../../../pages/Onboarding/OnboardingStatus";

const Explainer = (): JSX.Element => {
  useOnboardingFlow(OnboardingStatus.STARTED);

  return (
    <div className="explainer">
      <h1 className="onboarding-title">We’re excited to work with you!</h1>
      <p className="onboarding-paragraph">
        Let’s complete your brand profile in just a few quick steps.
      </p>
      <Link
        to={paths.companyInformation.url}
        className="onboarding-button"
        data-testid="get-started-btn"
      >
        Get Started
      </Link>
    </div>
  );
};

export default Explainer;
