export interface BareLoginInformation {
  token: string | null;
  expiresIn: Date | null;
}

interface IAuthStore {
  getLoginInfo: () => BareLoginInformation;
  setLoginInfo: (loginInfo: BareLoginInformation) => void;
  removeLoginInfo: () => void;
}

const LOGIN_INFO = "LOGIN_INFO";
const AuthStore: IAuthStore = {
  getLoginInfo: () => {
    const loginInfo = JSON.parse(localStorage.getItem(LOGIN_INFO));
    if (loginInfo) {
      return {
        token: loginInfo.token,
        expiresIn: new Date(loginInfo.expiresIn),
      };
    }
    return {
      token: null,
      expiresIn: null,
    };
  },
  setLoginInfo: (loginInfo: BareLoginInformation) =>
    localStorage.setItem(LOGIN_INFO, JSON.stringify(loginInfo)),
  removeLoginInfo: () => localStorage.removeItem(LOGIN_INFO),
};

export default AuthStore;
