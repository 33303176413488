import { Slot as BaseSlot } from "../apiClient/data-contracts";
import { Company } from "./company";
import { EspName } from "./esp";
import { IntegrationProvider } from "./integrationProvider";
import { PrizingOptions } from "./prizingOptions";
import { SmsName } from "./sms";

export enum PaymentStatusValues {
  PAYMENT_NONE,
  AUGMENTER,
  RECURRING,
  ONE_OFF,
  COMPLIMENTARY,
}

export interface Slot extends Omit<BaseSlot, "id"> {
  id?: number;
  company?: Company;
  api_setup?: IntegrationProvider<EspName>;
  sms_setup?: IntegrationProvider<SmsName>;
  sweeps_prize_option?: PrizingOptions;
}

export interface SlotPayload {
  brand_description?: string;
  short_brand_description?: string;
  tagline?: string;
  image?: File;
  social_promotion_date?: string;
  email_promotion_date?: string;
  blog_promotion_date?: string;
  offer_type?: "FREE_GIFT" | "OFF_AMOUNT";
  free_gift?: string;
  free_gift_min_purchase_qualifications?: number;
  promo_code?: string;
  expiration?: string;
  off_amount?: number;
  off_amount_min_purchase_amount?: number;
  exclusions_notes?: string;
  is_test_email_sent?: boolean;
  no_sweep_videos?: boolean;
  sweeps_prize_code?: string;
  sweeps_prize_value?: number;
  sweeps_prize_description?: string;
  sweeps_prize_option?: PrizingOptions;
}
