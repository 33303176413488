import React from "react";
import clsx from "clsx";
import "./FormErrors.scss";

interface Props {
  errors: Record<string, unknown>;
  className?: string;
  isSubmitting: boolean;
  isValid?: boolean;
  submitCount: number;
}

const FormErrors = ({
  errors,
  className,
  submitCount,
  isSubmitting,
  isValid,
}: Props): JSX.Element => {
  return (
    submitCount > 0 &&
    !isSubmitting &&
    !isValid && (
      <>
        {Object.keys(errors).length > 0 && (
          <div className={clsx("form-errors", className)}>
            Error(s) found. Please correct and try again.
          </div>
        )}
      </>
    )
  );
};

export default FormErrors;
