import * as yup from "yup";

const validationSchemaFn = (sowSweep: boolean): yup.AnyObjectSchema => {
  const extras = sowSweep
    ? {
        prizing_option: yup.string().required("Required"),
      }
    : {};
  return yup.object().shape({
    statement_of_work: yup.bool().oneOf([true], "Field must be checked"),
    ...extras,
  });
};

export default validationSchemaFn;
