import React, { FC, useState } from "react";
import { useNavigate } from "react-router";

import { Auth } from "../../apiClient/Auth";
import { useAuth, login } from "../../state/auth";

import ResetPasswordForm, { Values } from "./Form";
import formImage from "../../assets/images/thought-catalog.jpg";
import logo from "!file-loader!../../assets/images/bp-logo.svg";

import { CustomError, handleError } from "../../utils/errorHandler";

import { getFlowPath } from "../Onboarding/OnboardingStatus";
import { getCompany } from "../../utils/getCompany";
import "./RestPassword.scss";
import { getUser } from "../../utils/getUser";
import { useParams } from "react-router-dom";

const ResetPassword: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const { token } = useParams();

  const { setLoginInfo } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (values: Values) => {
    try {
      const { data } = await new Auth().postResetUserPassword({
        token,
        password: values.password,
      });
      login({ ...data, setLoginInfo });
      const accessToken = data.access_token;
      const [company, user] = await Promise.all([
        getCompany(accessToken),
        getUser(accessToken),
      ]);
      const path = getFlowPath(company, user);
      navigate(path, { replace: true });
    } catch (err) {
      const { error } = err as CustomError;
      if (error?.message) {
        setError(error.message);
      } else {
        console.error(handleError(err as CustomError));
      }
    }
  };

  return (
    <div className="reset-password">
      <div className="reset-password-form">
        <figure className="reset-password-form__logo">
          <img src={logo} alt="logo" />
        </figure>
        <div className="reset-password-form__text">
          <p className="paragraph-primary">Welcome!</p>
          <p className="paragraph-primary">Create password to get started:</p>
        </div>
        <ResetPasswordForm onSubmit={handleSubmit} error={error} />
      </div>
      <figure className="reset-password-image">
        <img src={formImage} alt="side banner" />
      </figure>
    </div>
  );
};

export default ResetPassword;
