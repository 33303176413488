import React, { FC } from "react";
import CloseIcon from "../../../assets/images/close-icon.svg";
import "./UploadPreview.scss";

type ImageUploadPreviewProps = {
  onRemove: () => void;
  type?: "image" | "video";
  value: string;
};

const ImageUploadPreview: FC<ImageUploadPreviewProps> = ({
  value,
  type = "image",
  onRemove,
}) =>
  value ? (
    <>
      <div className="custom-upload-preview" data-testid="preview">
        <div className="custom-upload-preview__border">
          <img
            src={CloseIcon}
            className="custom-upload-preview__close-icon"
            alt="remove"
            onClick={onRemove}
          />
          {type === "image" && (
            <img
              className="custom-upload-preview__preview"
              src={value}
              alt="preview"
            />
          )}
          {type === "video" && (
            <video
              controls
              muted
              autoPlay
              loop
              src={value}
              className="custom-upload-preview__preview"
            />
          )}
        </div>
      </div>
    </>
  ) : null;

export default ImageUploadPreview;
