import React from "react";
import "./Link.scss";

interface Props {
  url: string;
  type: "link" | "mail";
}

const Link = ({ url, type }: Props): JSX.Element => {
  return type === "mail" ? (
    <a className={"link"} href={`mailto:${url}`}>
      {url}
    </a>
  ) : (
    <a className={"link"} href={url} target="_blank" rel="noopener noreferrer">
      {url}
    </a>
  );
};

export default Link;
