import React from "react";

import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./NavigationArrows.scss";

interface NavigationArrowsProps {
  current: number;
  size: number;
  changeCurrentItemPosition: (index: number) => void;
}

const NavigationArrows = ({
  current,
  size,
  changeCurrentItemPosition,
}: NavigationArrowsProps): JSX.Element => {
  const prev = (index: number) => {
    if (index >= 0) {
      changeCurrentItemPosition(index);
    }
  };

  const next = (index: number) => {
    if (index < size) {
      changeCurrentItemPosition(index);
    }
  };

  return (
    <nav className="navigation-arrows">
      <button
        aria-label="previous-sweepstake"
        onClick={() => prev(current - 1)}
      >
        <FontAwesomeIcon
          className="navigation-arrows--left"
          icon={faCaretLeft}
          size="2x"
        />
      </button>

      <button aria-label="next-sweepstake" onClick={() => next(current + 1)}>
        <FontAwesomeIcon
          className="navigation-arrows--right"
          icon={faCaretRight}
          size="2x"
        />
      </button>

      <h4>
        Showing {current + 1} of {size}
      </h4>
    </nav>
  );
};

export default NavigationArrows;
