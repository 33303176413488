import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useCplV2 } from "../../../../../hooks/useCpl";

import AcceptanceForm from "./components/AcceptanceForm";
import Loader from "../../../../../Components/Loader/Loader";
import paths from "../../../../../Paths";
import Paragraph from "../../../../../Components/v2/UI/Paragraphs/Paragraph";

const OfferPromoCode: FC<{ next: string }> = ({ next }) => {
  const { data: cpl, isLoading, error } = useCplV2();
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <p>There was an error fetching the CPL: {error.toString()}</p>;
  }

  if (!cpl) {
    navigate(paths.home.url);
    return null;
  }

  return (
    <section className="offer-promo-code">
      <Paragraph type="primary" customClassName={["u-mb-extra-large"]}>
        Offers are required to be a free gift with a purchase with a retail
        value of $20 or more, or $20 or more off a purchase.{" "}
        <span className="heavy">We do not allow % off offers</span>. It is
        recommended to make your offer as aggressive as possible.
      </Paragraph>

      <AcceptanceForm cpl={cpl} next={next} />
    </section>
  );
};

export default OfferPromoCode;
