import React, { ReactNode, useState } from "react";
import SweepContext from "../contexts/sweep";
import { Sweep } from "../../apiClient/data-contracts";

interface SweepProviderProps {
  children?: ReactNode;
}

const SweepProvider = ({ children }: SweepProviderProps): JSX.Element => {
  const [sweep, setSweep] = useState<Sweep>(null);
  return (
    <SweepContext.Provider value={{ sweep, setSweep }}>
      {children}
    </SweepContext.Provider>
  );
};

export default SweepProvider;
