import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import CompanyContext from "../../state/contexts/company";
import UserContext from "../../state/contexts/user";
import { useAuth } from "../../state/auth";
import { useLoading } from "../../state/loader";

import BrandInformationForm from "./Form";
import {
  BrandInformationPayload,
  CompanyPayload,
  ContactPayload,
} from "../../DataTypes";
import { Companies } from "../../apiClient/Company";
import { Contacts } from "../../apiClient/Contact";

import { CustomError, handleError } from "../../utils/errorHandler";

import { getCurrentContact, getSecondaryContact } from "./utils";
import Loader from "../Loader/Loader";
import nextIncompleteSection from "../../utils/nextSectionRedirection";
import { getStartedSectionOrder } from "../../pages/GetStartedCheckList/GetStartedChecklist";
import { errorToast } from "../../utils/toast";

const BrandInformation = (): JSX.Element => {
  const { company, setCompany } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const navigate = useNavigate();

  const generateSecondaryContactPayload = (
    values: BrandInformationPayload
  ): ContactPayload => ({
    first_name: values.secondary_first_name,
    last_name: values.secondary_last_name,
    position_title: values.secondary_position_title,
    email_address_1: values.secondary_email_address_1,
    email_address_2: values.secondary_email_address_2,
    phone_1_number: values.secondary_phone_1_number,
  });

  const generatePrimaryContactPayload = (
    values: BrandInformationPayload
  ): ContactPayload => ({
    first_name: values.first_name,
    last_name: values.last_name,
    position_title: values.position_title,
    email_address_1: values.email_address_1,
    email_address_2: values.email_address_2,
    phone_1_number: values.phone_1_number,
    phone_1_extension: values.phone_1_extension,
    phone_2_number: values.phone_2_number,
  });

  const generateCompanyPayload = (
    values: BrandInformationPayload
  ): CompanyPayload => ({
    parent_company: values.parent_company,
    street_address_1: values.street_address_1,
    street_address_2: values.street_address_2,
    city: values.city,
    state: values.state,
    zip_code: values.zip_code,
    phone: values.phone,
    phone_extension: values.phone_extension,
    esp: values.esp,
    esp_other_details: values.esp_other_details,
    url: values.url,
  });

  const onSubmit = async (values: BrandInformationPayload) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure the information is correct?")) {
        setLoading(true);

        const contactClient = new Contacts();
        const companyClient = new Companies();

        const secondaryContactPayload = generateSecondaryContactPayload(values);
        const primaryContactPayload = generatePrimaryContactPayload(values);
        const companyPayload = generateCompanyPayload(values);

        const secondaryContactId = getSecondaryContact(company, user)?.id;
        if (secondaryContactId) {
          await contactClient.patchContactApi(
            secondaryContactId,
            secondaryContactPayload,
            {
              headers: {
                Authorization: loginInfo.token,
              },
            }
          );
        } else {
          await companyClient.postCompanyContactApi(
            company.id,
            secondaryContactPayload,
            {
              headers: { Authorization: loginInfo.token },
            }
          );
        }

        const contactId = getCurrentContact(company, user)?.id;

        await contactClient.patchContactApi(contactId, primaryContactPayload, {
          headers: { Authorization: loginInfo.token },
        });

        const response = await companyClient.patchCompanyApi(
          company.id,
          companyPayload,
          {
            headers: { Authorization: loginInfo.token },
          }
        );

        setCompany(response.data);
        alert("Successfully updated");
        nextIncompleteSection(
          navigate,
          getStartedSectionOrder(response.data, user)
        );
      }
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  return company && user ? (
    <BrandInformationForm
      company={company}
      contact={getCurrentContact(company, user)}
      secondaryContact={getSecondaryContact(company, user)}
      onSubmit={onSubmit}
    />
  ) : (
    <Loader />
  );
};

export default BrandInformation;
