import React, { Children, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { Location } from "history";

import Checkbox from "../../Components/Checkbox/Checkbox";

import "./Sidebar.scss";

type Props = {
  logo?: string;
  title?: string;
  children?: ReactNode;
};

const Sidebar = ({ title, logo, children }: Props): JSX.Element => {
  return (
    <aside className="sidebar">
      <div className="sidebar__brand">
        <div className="sidebar__login-msg">
          {title && "You're logged in as"}
        </div>
        {logo ? (
          <img className="brand__image" src={logo} alt={`${title} logo`} />
        ) : (
          title
        )}
      </div>
      <ul className="sidebar__items">
        {Children.map(children, (child) => (
          <>{child}</>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;

type SideBarItemProps = {
  path: string;
  name: string;
  checked?: boolean;
  hasCheck?: boolean;
};

export const SidebarItem = ({
  path,
  name,
  checked,
  hasCheck,
}: SideBarItemProps): JSX.Element => {
  const location = useLocation();

  return (
    <li className={getSideBarItemClassName(path, location)}>
      {hasCheck && <Checkbox checked={checked} />}
      <h3
        className={`heading-tertiary ${
          hasCheck ? "" : "sidebar__item--no-child"
        }`}
      >
        <Link to={path}>{name}</Link>
      </h3>
    </li>
  );
};

const getSideBarItemClassName = (path: string, location: Location) => {
  if (location.pathname === path) {
    return "sidebar__item sidebar__item--selected";
  }
  return "sidebar__item";
};
