import React from "react";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Loader.scss";

interface LoaderProps {
  size?: SizeProp;
}

const Loader = ({ size = "5x" }: LoaderProps): JSX.Element => {
  return (
    <div className="loader-wrapper">
      <FontAwesomeIcon data-testid="loader" size={size} className="fa-pulse" icon={faCircleNotch} />
    </div>
  );
};

export default Loader;
