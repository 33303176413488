import React, { useContext, useEffect, useState } from "react";

import { Cpl } from "../../../apiClient/Cpl";
import { OfferType } from "../../../DataTypes";
import {
  cplSectionOrder,
  isOfferPromoCodeComplete,
} from "../../../pages/CostPerLead/utils";
import {
  validationSchemaGiftOffer,
  validationSchemaOffOffer,
} from "../../../pages/OnSiteOffer/formValidation";
import CplContext from "../../../state/contexts/cpl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../state/auth";
import { useLoading } from "../../../state/loader";
import { CustomError, handleError } from "../../../utils/errorHandler";
import OfferPromoCodeForm, {
  OfferFormProps,
  OfferPromoValues,
} from "../OfferPromoCodeForm/OfferPromoCodeForm";
import { cleanValuesOnOfferSelection } from "./utils";
import nextIncompleteSection from "../../../utils/nextSectionRedirection";
import { errorToast } from "../../../utils/toast";

interface OfferTypeObject {
  [key: string]: OfferFormProps;
}

const OfferObject = (): OfferTypeObject => {
  const purchaseQualificationsNote =
    "If there is no minimum order required please type 0";
  return {
    [OfferType.OffOffer]: {
      label: `"$ Off" Offer`,
      name: "off_amount",
      type: "number",
      placeholder: "$20",
      offer_type: OfferType.OffOffer,
      purchase_qualifications: "off_amount_min_purchase_amount",
      purchase_qualifications_label: "Minimum Purchase amount for $ Off",
      purchase_qualifications_note: purchaseQualificationsNote,
      validationSchema: validationSchemaOffOffer(),
    },
    [OfferType.FreeGift]: {
      label: "Free Gift",
      name: "free_gift",
      type: "text",
      placeholder: "Product/Service",
      offer_type: OfferType.FreeGift,
      purchase_qualifications: "free_gift_min_purchase_qualifications",
      purchase_qualifications_label:
        "Minimum Purchase qualifications for free gift ($)",
      purchase_qualifications_note: purchaseQualificationsNote,
      validationSchema: validationSchemaGiftOffer(),
    },
  };
};

const OfferAcceptanceForm = (): JSX.Element => {
  const [offerType, setOfferType] = useState<string>(OfferType.OffOffer);
  const { cpl, setCpl } = useContext(CplContext);

  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    cpl && cpl.offer_type && setOfferType(cpl.offer_type);
  }, [cpl]);

  const disabled = isOfferPromoCodeComplete(cpl);

  const onSubmit = async (values: OfferPromoValues) => {
    const cleanValues = cleanValuesOnOfferSelection(values);
    try {
      setLoading(true);
      const { data } = await new Cpl().patchCplBrandPortalApi(
        cpl.id,
        cleanValues,
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      );
      setCpl(data);
      alert("Data Saved!");
      nextIncompleteSection(navigate, cplSectionOrder(data));
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h3 className="heading-tertiary">1. SUBMIT YOUR OFFER</h3>
      <p className="paragraph-secondary">
        Offers are required to be $15 or more off a purchase, although it is
        recommended to make your offer as aggressive as possible. Offer can be a
        free gift with purchase, with a recommended value of $20+.
      </p>
      <div className="form-field">
        <h3 className="heading-tertiary u-mb-medium">Offer</h3>
        <label className="form-field__label">
          <input
            type="radio"
            className="form-field__input"
            name="offer_type"
            onChange={() => setOfferType(OfferType.OffOffer)}
            checked={offerType === OfferType.OffOffer}
            disabled={disabled}
          />
          "$ Off" offer
        </label>
        <label className="form-field__label">
          <input
            type="radio"
            className="form-field__input"
            name="offer_type"
            onChange={() => setOfferType(OfferType.FreeGift)}
            checked={offerType === OfferType.FreeGift}
            disabled={disabled}
          />
          Free Gift
        </label>
      </div>

      <OfferPromoCodeForm
        offerFormProps={OfferObject()[offerType]}
        dataSourceType={cpl}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default OfferAcceptanceForm;
