import { createContext } from "react";
import { SweepReport } from "../../apiClient/data-contracts";

interface ReportContextValue {
  sweepReport: SweepReport;
  setSweepReport: (sweepReport: SweepReport) => void;
}

const ReportContext = createContext<ReportContextValue>({
  sweepReport: null,
  setSweepReport: null,
});

export default ReportContext;
