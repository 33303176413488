import React, { FC } from "react";
import "./Footer.scss";

const Footer: FC = (): JSX.Element => (
  <footer className="footer2">
    &copy; {new Date().getFullYear()} FindKeep.Love
  </footer>
);

export default Footer;
