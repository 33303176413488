import React, { FC } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./StateIcon.scss";
import clsx from "clsx";

interface StatusIconProps {
  type?: "blank" | "active" | "valid" | "invalid";
}

const StatusIcon: FC<StatusIconProps> = ({ type = "blank" }) => (
  <div
    className={clsx("status-icon", {
      "status-icon--active": type === "active",
      "status-icon--valid": type === "valid",
      "status-icon--invalid": type === "invalid",
    })}
  >
    {type === "valid" && (
      <FontAwesomeIcon className={"status-icon__icon"} icon={faCheck} />
    )}
    {type === "invalid" && (
      <FontAwesomeIcon className={"status-icon__icon"} icon={faTimes} />
    )}
  </div>
);

export default StatusIcon;
