import React, { ComponentPropsWithRef, FC } from "react";

const CombineProviders = (...providers: FC[]): FC => {
  return providers.reduce(
    (AccComponents, CurrentComponent) => {
      // eslint-disable-next-line
      const Provider = ({
        children,
      }: ComponentPropsWithRef<FC>): JSX.Element => {
        return (
          <AccComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccComponents>
        );
      };
      return Provider;
    },
    ({ children }) => <>{children}</>
  );
};

export default CombineProviders;
