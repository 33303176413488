/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Sweep, UpcomingSweep } from "./data-contracts";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Sweeps extends HttpClient {
  /**
   * @description It includes booked and live sweeps with confirmed slots.
   *
   * @tags sweep
   * @name GetSweepApi
   * @summary List available sweepstakes for the current user's company
   * @request GET:/sweep/
   * @secure
   */
  getSweepApi = (params?: RequestParams) =>
    this.request<Sweep[], any>(`/sweep/`, "GET", params, null, BodyType.Json, true);

  /**
   * @description This uses the existing "Best Matches" logic.
   *
   * @tags sweep
   * @name GetUpcomingSweepsApi
   * @summary List upcoming sweepstakes for the current user's company
   * @request GET:/sweep/upcoming
   * @secure
   */
  getUpcomingSweepsApi = (params?: RequestParams) =>
    this.request<UpcomingSweep[], any>(`/sweep/upcoming`, "GET", params, null, BodyType.Json, true);
}
