import React, { FC } from "react";
import { FieldAttributes } from "formik";
import clsx from "clsx";
import Label from "../Label/Label";
import Select from "../Select/Select";
import "./Dropdown.scss";

interface Props {
  showEmptyOption?: boolean;
  className?: string[];
  label: string;
  name: string;
  disabled?: boolean;
}

type DropdownProps = FieldAttributes<unknown> & Props;

const Dropdown: FC<DropdownProps> = ({
  children,
  className,
  disabled,
  showEmptyOption,
  label,
  name,
  ...selectProps
}): JSX.Element => {
  return (
    <div
      className={clsx("dropdown", className)}
      data-testid="test_dropdown_selected"
    >
      <Label label={label} name={name} />
      <Select
        name={name}
        disabled={disabled}
        showEmptyOption={showEmptyOption}
        {...selectProps}
      >
        {children}
      </Select>
    </div>
  );
};

export default Dropdown;
