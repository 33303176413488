import { BrandSigning, Company, SigningHistory } from "../../../DataTypes";
import { getCurrentBrandSigning } from "../../StatementOfWork/utils";

export type ChecklistType = Record<string, boolean>;

const STD_LABELS: string[] = [];

const STD_SIGNED: SigningHistory[] = [
  {
    acknowledged_checks: JSON.stringify(STD_LABELS),
  },
];

const STD_ATC_CHECKS: BrandSigning = {
  available_atc_checks: STD_LABELS,
};

export const defaultATCChecks: BrandSigning = {
  available_atc_checks: STD_LABELS,
};

export const CHECKLIST_DATE_THRESHOLD: number = Date.parse("2021-07-22 00:00:00");

export function getPreviouslySignedATC(company: Company, ATC: SigningHistory[]): boolean {
  return Date.parse(company?.msa_signed_timestamp) < CHECKLIST_DATE_THRESHOLD &&
    company?.msa_signee_email &&
    ATC.length === 0;
}

export function getAvailableATC(company: Company): ChecklistType {
  const currentSigning = getCurrentBrandSigning(company) || defaultATCChecks;

  return currentSigning.available_atc_checks.reduce((acc, check) => ({ ...acc, [check]: false }), {});
}

export function getAcceptedATC(company: Company): ChecklistType {
  const signingHistory = company?.signing_history || [];

  const allChecks = signingHistory.reduce((acc: string[], signHistory) => {
    const checks = signHistory.acknowledged_checks_list;
    return [...acc, ...(Array.isArray(checks) ? checks : [])];
  }, []);

  if (allChecks.length > 0) {
    return allChecks.reduce((acc, atc) => ({ ...acc, [atc]: true }), {});
  }

  if (getPreviouslySignedATC(company, signingHistory)) {
    const modifiedATC = getAvailableATC(company);
    Object.keys(modifiedATC).forEach(key => {
      modifiedATC[key] = true;
    });

    return modifiedATC;
  }

  return {};
}

export function isChecklistComplete(company: Company): boolean {
  const acceptedTC =
    company?.signing_history.length > 0 ? company?.signing_history : STD_SIGNED;
  const acceptedTCList = acceptedTC.reduce(
    (acc: string[], signHistory) =>
      acc.concat(signHistory.acknowledged_checks_list || []),
    []
  );

  const availableTC = getCurrentBrandSigning(company) || STD_ATC_CHECKS;
  const availableTCList = availableTC.available_atc_checks;

  const completeATC = (): boolean => {
    if (
      Date.parse(company?.msa_signed_timestamp) < CHECKLIST_DATE_THRESHOLD &&
      company?.msa_signee_email
    )
      return true;
    return availableTCList
      .map((check) => acceptedTCList.includes(check))
      .every((i) => i);
  };

  return (
    company &&
    [
      !!company.msa_signed_timestamp,
      company.msa_signee_email,
      company.msa_version,
      completeATC(),
    ].every((i) => i)
  );
}
