import AuthStore from "../store/AuthStore";
import { CustomError, handleError } from "./errorHandler";

export const parseDate = (dateStr: string): Date => {
  return dateStr && new Date(`${dateStr}T00:00:00`);
};

export const formatDateInUTC = (date: Date): string =>
  date
    ? getDateAsISOString(convertToUTC(date))
    : getDateAsISOString(convertToUTC(new Date()));

export const getDateAsISOString = (date?: string | number): string =>
  date
    ? new Date(date).toISOString().slice(0, 10)
    : new Date().toISOString().slice(0, 10);

export const downloadFile = async (
  path: string,
  name: string
): Promise<void> => {
  const token = AuthStore.getLoginInfo().token;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_YENTA_BP_API}/${path}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const blob = await response.blob();
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(handleError(error as CustomError));
  }
};

export const numbersOnlyRegEx = new RegExp("^[0-9]+$");

export const numbersAndLettersRegEx = new RegExp(
  "^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$"
);

export const urlRegEx = new RegExp(
  "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,})"
);

export const convertToUTC = (date: Date): number =>
  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

export const diffBetweenDates = (
  startDate: string,
  endDate: string
): number => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const convertStringDateToUTC = (value: string) =>
    convertToUTC(new Date(value));

  const utcStart = convertStringDateToUTC(startDate);
  const utcEnd = convertStringDateToUTC(endDate);

  return (utcEnd - utcStart) / MS_PER_DAY;
};

export const daysRemainingFromCurrentDate = (
  endDate: string,
  currentDate?: string
): number => {
  const diffDates = diffBetweenDates(
    currentDate
      ? formatDateInUTC(new Date(currentDate))
      : formatDateInUTC(new Date()),
    formatDateInUTC(new Date(endDate))
  );
  return diffDates > 0 ? diffDates : 0;
};

const convertToPercentage =
  (decimals: number) =>
  (value: number): string =>
    `${+(value * 100).toFixed(decimals)}%`;

export const convertToPercentageOneDecimal = convertToPercentage(1);

export const trimString =
  (length: number) =>
  (str: string): string =>
    `...${str.substring(str.length - length)}`;

export const trimStringToLast25Chars = trimString(25);

export function isDefined<T>(value: T | undefined | null): value is T {
  return (value as T) !== undefined && (value as T) !== null;
}
