import * as yup from "yup";
import { numbersAndLettersRegEx } from "../../utils";
import { parse, isDate } from "date-fns";

const parseDateStr = (_: Date, originalValue: string) =>
  isDate(originalValue)
    ? originalValue
    : parse(originalValue, "MM/yy", new Date());

const isRequiredText = "This field is required";

const validationSchema = (today: Date): yup.AnyObjectSchema => {
  const firstMonthDate = new Date(today.getFullYear(), today.getMonth(), 1);
  return yup.object().shape({
    credit_card_number: yup
      .string()
      .min(10, "Min 10 digits")
      .matches(/\d{10,}/, "Make sure to provide only digits")
      .required(isRequiredText),
    cvv: yup
      .string()
      .min(3, "Min 3 digits")
      .max(4, "Max 4 digits")
      .matches(/\d{3,4}/, "Make sure to provide 3 or 4 digits")
      .required(isRequiredText),
    expiration_date: yup
      .date()
      .typeError("Make sure to follow the format MM/YY with valid values")
      .transform(parseDateStr)
      .min(firstMonthDate, "The credit card already expired")
      .required(isRequiredText),
    billing_zip_code: yup
      .string()
      .required(isRequiredText)
      .matches(
        numbersAndLettersRegEx,
        "Must be only digits and uppercase letters"
      )
      .max(10, "Max 10 characters"),
    sow_signed: yup.boolean().oneOf([true], isRequiredText),
  });
};

export default validationSchema;
