/* eslint-disable sonarjs/cognitive-complexity */

import { Company, CompanyDataFetch, ContactPayload, PhoneTypes, SecondaryContact } from "../../../DataTypes";
import { Contact, PhoneNumber, User } from "../../../apiClient/data-contracts";
import { useMemo } from "react";

const urlInitialValue = "https://";

function getCurrentContact(company: Company, user: User): Contact {
  return company.contacts.find(
    contact => contact.contact.email_address === user.email
  )?.contact;
}

function getSecondaryContact(company: Company, user: User): Contact | null {
  const loggedUser = getCurrentContact(company, user);

  return company.contacts.find(
    contact => contact.contact.email_address !== loggedUser.email_address
  )?.contact;
}

function getAlternateEmailAddress(contact: Contact): string {
  return contact?.email_addresses?.length > 0
    ? contact.email_addresses[0].email
    : "";
}

function getPhoneNumber(contact: Contact, type: string): PhoneNumber {
  return contact?.phone_numbers?.find(
    phoneNumber => phoneNumber.number_type === type
  );
}

export function useCompanyInformationInitialValues(
  company: Company,
  user: User,
): CompanyDataFetch & ContactPayload & SecondaryContact {
  return useMemo(() => {
    const contact = getCurrentContact(company, user);
    const secondaryContact = getSecondaryContact(company, user);

    return {
      parent_company: company.parent_company || "",
      name: company.name || "",
      street_address_1: company.street_address_1 || "",
      street_address_2: company.street_address_2 || "",
      city: company.city || "",
      state: company.state || "",
      zip_code: company.zip_code || "",
      phone: company.phone || "",
      phone_extension: company.phone_extension || "",
      url: company.url || urlInitialValue,
      // TODO: this field is hardcoded
      esp: "Moosend",

      first_name: contact.first_name || "",
      last_name: contact.last_name || "",
      position_title: contact.position_title || "",
      email_address_1: contact.email_address || "",
      email_address_2: getAlternateEmailAddress(contact) || "",
      phone_1_number: getPhoneNumber(contact, PhoneTypes.WORK)?.number || "",
      phone_1_extension:
        getPhoneNumber(contact, PhoneTypes.WORK)?.extension || "",
      phone_2_number: getPhoneNumber(contact, PhoneTypes.OTHER)?.number || "",

      secondary_first_name: secondaryContact?.first_name || "",
      secondary_last_name: secondaryContact?.last_name || "",
      secondary_position_title: secondaryContact?.position_title || "",
      secondary_email_address_1: secondaryContact?.email_address || "",
      secondary_email_address_2: getAlternateEmailAddress(secondaryContact) || "",
      secondary_phone_1_number: getPhoneNumber(secondaryContact, PhoneTypes.WORK)?.number || "",
    };
  }, [company, user]);
}
