import * as yup from "yup";
import { emailValidation } from "../../../utils/validations";

import { email, password } from "../../../utils/validations/messages";

const validationSchema = yup.object().shape({
  email: emailValidation().required(email.isRequired),
  password: yup.string().required(password.isRequired),
});

export default validationSchema;
