import React, { FC } from "react";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import { ImageType } from "react-images-uploading";
import Field from "../../../../../../Components/v2/Field/Field";
import ProductImages from "./ProductImages/ProductImages";
import ImageInput from "../../../../../../Components/v2/ImageInput/ImageInput";
import validationSchema, { maxLength } from "./validationSchema";
import "./AssetCollectionForm.scss";
import SubmitButton from "../../../../../../Components/v2/UI/Buttons/SubmitButton/SubmitButton";
import FocusFormikError from "../../../../../../utils/FocusFormikError";
import logoExamples from "./assets/logo-examples.png";
import VideoInput from "./VideoInput/VideoInput";

export interface FormValues {
  short_brand_description?: string;
  brand_description?: string;
  tagline?: string;
  logo?: ImageType;
  offer_image?: ImageType;
  product_images?: Array<{
    image: ImageType;
    title: string;
    description: string;
    isUploaded?: boolean;
  }>;
  no_cpl_videos?: boolean;
  offer_video?: string | File;
}

interface AssetCollectionFormProps {
  initialValues?: Partial<FormValues>;
  onSubmit: (
    values: FormValues,
    formikHelpers?: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}

const defaultValues: FormValues = {
  short_brand_description: "",
  brand_description: "",
  tagline: "",
  product_images: [],
  no_cpl_videos: false,
};

const AssetCollectionForm: FC<AssetCollectionFormProps> = ({
  initialValues = {},
  onSubmit,
}) => (
  <Formik
    initialValues={{
      ...defaultValues,
      ...initialValues,
    }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <FormikForm className="asset-collection-form">
      <Field
        name="short_brand_description"
        type="textarea"
        maxLength={maxLength.short_brand_description}
        label="Short Brand Description"
        description={
          <>
            <p>
              This is a short description used in FindKeep.Love Arcade to
              introduce your brand to users.
            </p>
            <p className="heavy">
              Example: High-quality engineered knives and gadgets at an
              exceptional value.
            </p>
          </>
        }
      />
      <Field
        name="brand_description"
        type="textarea"
        maxLength={maxLength.brand_description}
        label="Long Brand Description"
        description={
          <>
            <p>
              This is a brand description that gives users a deeper
              understanding of your brand.
            </p>
            <p className="heavy">
              Example: The original bamboo sunglasses brand, handcrafting the
              best polarized 100% bamboo sunglasses and watches since 2012.
            </p>
          </>
        }
      />
      <Field
        name="tagline"
        type="text"
        label="Tagline"
        description={
          <>
            <p>This is your brand’s tagline/slogan.</p>
            <p className="heavy">
              Example: Your Most Luxurious Bed and Bath Experience.
            </p>
          </>
        }
      />
      <ImageInput
        name="logo"
        label="Logo"
        instructions={
          <>
            <p>
              Please provide your logo in PNG format with transparent
              background. It is recommended to use logo with{" "}
              <strong className="heavy">
                horizontal orientation and have outlines if the logo is white to
                make sure it’s visible in various placements
              </strong>
              .
            </p>
          </>
        }
        description={
          <p className="heavy">
            Example:
            <br />
            <img
              src={logoExamples}
              alt="logo examples"
              className="asset-collection-form__logo-examples"
            />
          </p>
        }
      />
      <ImageInput
        name="offer_image"
        resolutionType="ratio"
        resolutionHeight={800}
        resolutionWidth={800}
        maxFileSize={200 * 1024}
        imageValidationMessages={{
          maxFileSize: (
            <>
              Looks like your image is too large. Each image can{"\u2019"}t
              exceed 200 KB. You can use{" "}
              <a
                href="https://squoosh.app/"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                this tool
              </a>{" "}
              to quickly compress your files.
            </>
          ),
          resolution:
            "Your image must be 1:1 ratio (square). " +
            "Please edit your image to fit these requirements to avoid cropping and distortion.",
        }}
        label="Offer image"
        instructions={
          <>
            <p>
              Please provide a high-quality image that represents your brand to
              display in our offer pop-ups. Size requirements: 1:1 ratio (800 x
              800px) up to 200 KB each. You can compress your files before
              uploading{" "}
              <a
                href="https://squoosh.app/"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </>
        }
      />
      <ProductImages
        name="product_images"
        label="Product images"
        instructions={
          <>
            <p>
              Please upload <strong>10 product images</strong> for us to use for
              promoting your offer/products.
            </p>
            <ul className="product-images__description-list">
              <li>
                If your company only offers a small amount of products or
                services, consider different lifestyle images representing your
                offer
              </li>
              <li>
                Some of the images will be presented as small icons, make sure
                your images don’t look too similar to each other and are easily
                distinguishable
              </li>
              <li>
                Size requirements: <strong>1:1 ratio</strong> (800 x 800px) up
                to <strong>200 KB</strong> each. You can compress your files
                before uploading{" "}
                <a
                  href="https://squoosh.app/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </li>
            </ul>
          </>
        }
        description={
          <p>
            Size above will be used for some of the placements. Make sure you
            can clearly see your product and images don’t look too similar to
            each other. You can replace any images above.
          </p>
        }
      />
      <VideoInput />

      <FocusFormikError />
      <SubmitButton label="Next" loadingLabel="Uploading" />
    </FormikForm>
  </Formik>
);

export default AssetCollectionForm;
