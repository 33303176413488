import React, { ReactNode, useState, useEffect } from "react";
import { Payment } from "apiClient/data-contracts";
import useLastPayment from "Components/PaymentReminder/hooks/useLastPayment";
import LastRecurringPaymentContext from "../contexts/lastRecurringPayment";

interface LastRecurringPaymentProviderProps {
  children?: ReactNode;
}

const LastRecurringPaymentProvider = ({
  children,
}: LastRecurringPaymentProviderProps): JSX.Element => {
  const [lastRecurringPayment, setLastRecurringPayment] =
    useState<Payment>(null);
  const lastPayment = useLastPayment();

  useEffect(() => {
    lastPayment && setLastRecurringPayment(lastPayment);
  }, [lastPayment]);

  return (
    <LastRecurringPaymentContext.Provider
      value={{ lastRecurringPayment, setLastRecurringPayment }}
    >
      {children}
    </LastRecurringPaymentContext.Provider>
  );
};

export default LastRecurringPaymentProvider;
