import React from "react";
import { Link } from "react-router-dom";

import "./Tab.scss";

type Props = {
  title: string;
  path: string;
  selected: boolean;
  disabled: boolean;
};

const Tab = ({ title, path, selected, disabled }: Props): JSX.Element => (
  <div
    className={`
    tab
    ${selected ? "tab--selected" : "tab--not-selected"}
    ${disabled ? "tab--disabled" : ""}
  `}
  >
    <span className="tab__title">
      <Link to={path}>{title}</Link>
    </span>
  </div>
);

export default Tab;
