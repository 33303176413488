import React, { FC } from "react";

import { bpContactEmail } from "../../utils/constants";

import "./Home.scss";

const Home: FC = () => (
  <section className="home-page">
    <h1 className="heading-primary home-page__heading">
      Welcome to the FindKeep.Love Brand Portal!
    </h1>
    <p className="home-page__paragraph">
      FindKeep.Love has created this Brand Portal for brands to access and
      upload information all in one place. Below is a guideline on how to use
      this portal.
    </p>

    <ol className="home-page__steps">
      <li>
        <p>
          There are multiple tabs across the top of the portal that may need to
          be completed at different times when working with FindKeep.Love. Your
          FindKeep.Love Account Manager will let you know which tabs need to be
          completed.
        </p>
      </li>
      <li>
        <p>
          You will need to click on the designated tab by the FindKeep.Love team
          at the top of the page. Inside of that tab there may be multiple
          sections on the left hand side that need to be completed.
        </p>
      </li>
      <li>
        <p>
          You will need to start with the top section on the left hand side and
          complete each one.
        </p>
      </li>
      <li>
        <p>
          Once each section inside a tab had been completed, a green checkbox
          will appear next to the section name.
        </p>
      </li>
      <li>
        <p>
          Once you have completed one section, you will then need to click on
          the next section to complete it. So on and so forth.
        </p>
      </li>
      <li>
        <p>
          Once all sections have been completed in the tab, the FindKeep.Love
          team will be notified.
        </p>
      </li>
    </ol>

    <div className="home-page__contact">
      Have QUESTIONS or STUCK? Please contact your Account Manager or{" "}
      <a className="link link--black" href={`mailto:${bpContactEmail}`}>
        {bpContactEmail}
      </a>
    </div>

    <ul className="grid">
      <li className="grid__item">
        <h2 className="grid__item__title">Get Started Tab</h2>
        <br />
        <ul>
          <li>Your Brand Information</li>
          <li>Let's Get Started (Advertiser T/Cs)</li>
          <li>Statement of Work</li>
          <li>Pay Your Balance</li>
        </ul>
      </li>

      <li className="grid__item">
        <h2 className="grid__item__title">Sponsor Checklist</h2>
        <br />
        <ul>
          <li>Sweepstakes Deliverables</li>
          <li>Email Integration</li>
          <li>Prizing Integration</li>
          <li>SMS Integration</li>
          <li>
            Sweepstakes Promotion <br />
            (Only appears when sweeps is ready to launch)
          </li>
        </ul>
      </li>

      <li className="grid__item">
        <h2 className="grid__item__title">Reports</h2>
        <br />
        <ul>
          <li>Current Sweepstakes</li>
          <li>Past Sweepstakes</li>
        </ul>
      </li>
    </ul>
  </section>
);

export default Home;
