import { useEffect, useState } from "react";
import { Slot } from "../DataTypes";
import { Slots } from "../apiClient/Slot";
import { useAuth } from "../state/auth";
import { CustomError, handleError } from "../utils/errorHandler";
import { useLoading } from "../state/loader";
import { errorToast } from "../utils/toast";

export const useSlot = (slotId?: number): Slot => {
  const [slot, setSlot] = useState<Slot>(null);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  useEffect(() => {
    const fetchSlotDetails = async () => {
      try {
        if (slotId) {
          setLoading(true);
          const { data } = await new Slots().getSlotApi(slotId, {
            headers: { Authorization: loginInfo.token },
          });
          setSlot(data);
        }
      } catch (error) {
        errorToast(handleError(error as CustomError).message);
      } finally {
        setLoading(false);
      }
    };

    fetchSlotDetails();
  }, [slotId, loginInfo, setLoading]);

  return slot;
};
