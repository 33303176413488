import useWindowDimensions from "./useWindowDimensions";

export enum Resolution {
  MOBILE,
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
  DESKTOP,
  DESKTOP_LARGE,
}

// This MUST be kept in sync with the _mixins.scss values.
export const TABLET_PORTRAIT_BREAKPOINT = 600;
export const TABLET_LANDSCAPE_BREAKPOINT = 900;
export const DESKTOP_BREAKPOINT = 1200;
export const DESKTOP_LARGE_BREAKPOINT = 1800;

export function useResolution(): Resolution {
  const { width } = useWindowDimensions();

  if (width < TABLET_PORTRAIT_BREAKPOINT) {
    return Resolution.MOBILE;
  }

  if (width >= TABLET_PORTRAIT_BREAKPOINT && width < TABLET_LANDSCAPE_BREAKPOINT) {
    return Resolution.TABLET_PORTRAIT;
  }

  if (width >= TABLET_LANDSCAPE_BREAKPOINT && width < DESKTOP_BREAKPOINT) {
    return Resolution.TABLET_LANDSCAPE;
  }

  if (width >= DESKTOP_BREAKPOINT && width < DESKTOP_LARGE_BREAKPOINT) {
    return Resolution.DESKTOP;
  }

  return Resolution.DESKTOP_LARGE;
}
