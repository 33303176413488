import React from "react";
import clsx from "clsx";

import "./BillingDetails.scss";
import { useBillingDetailsState } from "./BillingDetailsState";
import { isDefined } from "../../../utils";

const BillingDetails = (): JSX.Element => {
  const data = useBillingDetailsState();

  if (!isDefined(data)) {
    return null;
  }

  return (
    <div className="billing-details">
      <h2 className="billing-details__title">Your Subscription Summary</h2>
      {data.blocks.map((block, index) => (
        <div key={index} className="billing-details__summary-block">
          <div className="billing-details__summary-block__label">
            <div>{block.primaryLabel}</div>
            {block.secondaryLabel && <div>{block.secondaryLabel}</div>}
          </div>
          <div
            className={clsx(
              "billing-details__summary-block__price",
              block.price === null && "free"
            )}
          >
            {block.price === null ? "free" : `$${block.price.toLocaleString()}`}
          </div>
        </div>
      ))}
      <div className="billing-details__pricing-block">
        <div className="billing-details__pricing-block__column">
          <div>
            <strong>Due today:</strong>
          </div>
          {data.recurringPaymentInfo && <div>Recurring total:</div>}
        </div>
        <div className="billing-details__pricing-block__column">
          <div>
            <strong>${data.initialPaymentAmount.toLocaleString()}</strong>
          </div>
          <div>
            {data.recurringPaymentInfo?.details && (
              <div className="recurring-payment-details">
                {data.recurringPaymentInfo.details}
              </div>
            )}
          </div>
        </div>
      </div>
      {data.description && (
        <p className="billing-details__explanation">{data.description}</p>
      )}
    </div>
  );
};

export default BillingDetails;
