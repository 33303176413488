import React from "react";

import ImageUploading, { ImageListType } from "react-images-uploading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { trimStringToLast25Chars } from "../../utils";

import "./MultipleImageUploader.scss";

interface MultipleImageUploaderProps {
  maxImageNumber: number;
  setUploadedImages: (count: number) => void;
  imageState: ImageListType;
  setImageState: (images: ImageListType) => void;
  resolutionWidth?: number;
  resolutionHeight?: number;
  disabled?: boolean;
  canDelete?: boolean;
  removeUploadedImage?: (id: number) => void;
}

const MultipleImageUploader = ({
  maxImageNumber,
  setUploadedImages,
  imageState,
  setImageState,
  resolutionWidth,
  resolutionHeight,
  disabled,
  canDelete,
  removeUploadedImage,
}: MultipleImageUploaderProps): JSX.Element => {
  const onChange = (imageList: ImageListType) => {
    setUploadedImages(imageList.length);
    setImageState(imageList);
  };

  const acceptedImageTypes = ["jpg", "png", "jpeg"];

  return (
    <div className="imageUploader">
      <ImageUploading
        multiple
        value={imageState}
        acceptType={acceptedImageTypes}
        onChange={onChange}
        maxNumber={maxImageNumber}
        resolutionType="more"
        resolutionWidth={resolutionWidth || 1}
        resolutionHeight={resolutionHeight || 1}
      >
        {({ imageList, onImageUpload, onImageRemove, errors }) => (
          <>
            <div className="uploadSection">
              {!disabled && (
                <div
                  className="uploadSection__btn btn btn--primary"
                  onClick={onImageUpload}
                >
                  UPLOAD
                </div>
              )}
              {errors && (
                <div className="uploadSection__error">
                  {errors.maxNumber && (
                    <span>Only {maxImageNumber} images can be uploaded</span>
                  )}
                  {errors.acceptType && (
                    <span>
                      Only {acceptedImageTypes.join(", ")} images are accepted
                    </span>
                  )}
                  {errors.maxFileSize && (
                    <span>Selected file size exceed maxFileSize</span>
                  )}
                  {errors.resolution && (
                    <span>
                      Selected file is not match your desired resolution
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className="imagesData">
              {imageList.map((image, index) => (
                <div className="imageData" key={index}>
                  <img
                    className="imageData__thumbnail"
                    src={image.dataURL}
                    alt=""
                  />
                  <div className="imageData__name">
                    {image.file
                      ? image.file.name
                      : trimStringToLast25Chars(image.dataURL)}
                  </div>
                  {!disabled && canDelete && (
                    <button
                      className="imageData__btn"
                      onClick={() => {
                        onImageRemove(index);
                        image.id && removeUploadedImage(image.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </ImageUploading>
    </div>
  );
};

export default MultipleImageUploader;
