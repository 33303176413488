import React, { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "./PDFPreview.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export type PDFPreviewProps = {
  file: string;
  setIsDocRead: (value: boolean) => void;
  options?: Record<string, string>;
};

const PDFPreview = ({
  file,
  setIsDocRead,
  options,
}: PDFPreviewProps): JSX.Element => {
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoad = ({ numPages }: { numPages: number }) =>
    setNumPages(numPages);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const isRead =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (isRead) {
      setIsDocRead(isRead);
    }
  };

  return (
    <div className="container">
      <Document file={file} onLoadSuccess={onDocumentLoad} options={options}>
        <div className="page-container" onScroll={handleScroll}>
          {[...Array(numPages)].map((_el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={750}
            />
          ))}
        </div>
      </Document>
    </div>
  );
};

export default PDFPreview;
