import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import {
  isCPlAssetsComplete,
  isESPComplete,
  isOfferPromoCodeComplete,
} from "./utils";

import CplContext from "../../state/contexts/cpl";

import Content from "../../Layout/Content/Content";
import Main from "../../Layout/Main/Main";
import NavBar from "../../Layout/NavBar/NavBar";
import Page from "../../Layout/Page/Page";
import Sidebar, { SidebarItem } from "../../Layout/Sidebar/Sidebar";

import paths from "../../Paths";
import OfferPromoCode from "Components/OfferPromoCode/OfferPromoCode";
import CplAssetsCollection from "Components/CplAssetsCollection/CplAssetsCollection";
import CplEsp from "Components/Esp/CplEsp";

const CostPerLead = (): JSX.Element => {
  const { cpl } = useContext(CplContext);

  return (
    <Page>
      <Sidebar>
        <SidebarItem
          path={paths.cplOfferPromoCode.url}
          name="Offer/Promo Code"
          hasCheck
          checked={isOfferPromoCodeComplete(cpl)}
        />
        <SidebarItem
          path={paths.cplAssetsCollection.url}
          name="Assets Collection"
          hasCheck
          checked={isCPlAssetsComplete(cpl)}
        />
        <SidebarItem
          path={paths.cplEspIntegration.url}
          name="ESP Integration"
          hasCheck
          checked={isESPComplete(cpl)}
        />
      </Sidebar>
      <Content>
        <NavBar />
        <Main>
          <Routes>
            <Route path="offer-promo-code" element={<OfferPromoCode />} />
            <Route path="assets-collection" element={<CplAssetsCollection />} />
            <Route path="esp-integration" element={<CplEsp />} />
          </Routes>
        </Main>
      </Content>
    </Page>
  );
};

export default CostPerLead;
