import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

// === FLAGS - Edit this ===
const flags = {};

// Types
type Flags = typeof flags;
type FlagValue = Flags[keyof Flags]["defaultValue"];
type FlagValueType = boolean | string | number;
type FlagType<T extends FlagValueType = FlagValueType> = {
  name?: string;
  defaultValue: T;
  options?: T[];
  description?: string;
};
type FlagValues = {
  [Property in keyof Flags]: Flags[Property]["defaultValue"];
};
type FlagAnyValues = Record<keyof Flags, FlagValueType>;
type SetFlag<T extends keyof Flags = keyof Flags> = (
  flag: T,
  value: Flags[T]["defaultValue"]
) => void;
type SetAnyFlag = (flag: string, value: FlagValueType) => void;
type ResetFlag = (flag: keyof Flags) => void;

// Utilitites
const typedFlags: Readonly<Record<keyof Flags, Readonly<FlagType>>> = flags; // Ensure type safety

const localStoreKey = "FEATURE_FLAGS_VALUES";
const localStore = {
  get: (): FlagValues =>
    JSON.parse(localStorage.getItem(localStoreKey) || "{}"),
  set: (value: FlagValues) =>
    localStorage.setItem(localStoreKey, JSON.stringify(value)),
};

const FeatureFlagsContext =
  createContext<[FlagValues, SetFlag, ResetFlag, boolean]>(null);

// Exported values
export const FeatureFlagsProvider: FC<{ blockRendering?: boolean }> = ({
  children,
  blockRendering,
}) => {
  const [storedValues, setStoredValues] = useState<FlagValues>(
    Object.fromEntries(
      Object.entries(flags).map(([key, value]) => [
        key,
        (value as FlagType).defaultValue,
      ])
    ) as FlagValues
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    try {
      setStoredValues((oldValues) => ({ ...oldValues, ...localStore.get() }));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const setFlag = useCallback<SetFlag>((flag, value) => {
    const { defaultValue, options } = typedFlags[flag];

    if (options) {
      if (!(options as FlagType["options"]).includes(value)) {
        console.warn(`Invalid value option for flag "${flag}": ${value}`);
      }
    } else {
      if (typeof value !== typeof defaultValue) {
        console.warn(`Invalid value type for flag "${flag}": ${typeof value}`);
      }
    }

    setStoredValues((oldValues) => {
      const newValues = {
        ...oldValues,
        [flag]: value !== undefined ? value : (defaultValue as typeof value),
      };
      for (const key in newValues) {
        if (!Object.keys(typedFlags).includes(key)) {
          delete newValues[key as keyof typeof newValues];
        }
      }
      localStore.set(newValues);
      return newValues;
    });
  }, []);

  const resetFlag = useCallback<ResetFlag>((flag) => {
    setStoredValues((oldValues) => {
      const newValues = {
        ...oldValues,
        [flag]: (flags[flag] as FlagType).defaultValue,
      };
      for (const key in newValues) {
        if (!Object.keys(typedFlags).includes(key)) {
          delete newValues[key as keyof typeof newValues];
        }
      }
      localStore.set(newValues);
      return newValues;
    });
  }, []);

  return (
    <FeatureFlagsContext.Provider
      value={[storedValues, setFlag, resetFlag, isLoading]}
    >
      {blockRendering && isLoading ? null : children}
    </FeatureFlagsContext.Provider>
  );
};

/**
 * For single check import `useFlag(flag)` instead
 * @use
 * ```ts
 * const [{ yourFlag }, _setFlag, _resetFlag, isLoading] = useFeatureFlags();
 * ```
 */
export const useFeatureFlags = (): [FlagValues, SetFlag, ResetFlag, boolean] =>
  useContext(FeatureFlagsContext);

export const useFlag = <T extends keyof Flags = keyof Flags>(
  flag: T
): Flags[T]["defaultValue"] | undefined => {
  const context = useFeatureFlags();
  if (!context) {
    return undefined;
  }

  return context[0][flag];
};

export const FeatureFlagsDashboard: FC = () => {
  const [flagValues, setFlagValue, resetFlagValue, isLoading] =
    useFeatureFlags();
  const values = flagValues as FlagAnyValues;
  const setFlag = setFlagValue as SetAnyFlag;

  if (isLoading) {
    return null;
  }

  return (
    <div className="container">
      <h1 className="u-mb-large">Feature Flags</h1>
      {Object.entries(typedFlags).map(([key, value]) => {
        const { name, description, defaultValue, options } = value as FlagType;
        const flag = key as keyof Flags;
        const label = (
          <>
            {name ? (
              <>
                {name} &ndash; <code className="warning">{key}</code>
              </>
            ) : (
              <code className="warning">{key}</code>
            )}
          </>
        );
        return (
          <div key={key} className="u-mb-large">
            {options && options.length ? (
              <>
                <label htmlFor={key}>{label}</label>
                <div>
                  <select
                    className="custom-field__input"
                    onChange={(event) => {
                      setFlagValue(
                        flag,
                        options[parseFloat(event.target.value)] as FlagValue
                      );
                    }}
                    value={`${options.findIndex(
                      (value) => value === flagValues[flag]
                    )}`}
                    id={key}
                  >
                    {options.map((option, index) => (
                      <option key={`${option}`} value={`${index}`}>
                        {`${option}`}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            ) : (
              <>
                {typeof defaultValue === "boolean" && (
                  <div>
                    <input
                      type="checkbox"
                      checked={flagValues[flag] as boolean}
                      onChange={(event) => {
                        setFlag(flag, event.target.checked);
                      }}
                      id={key}
                    />
                    <label htmlFor={key}>{label}</label>
                  </div>
                )}
                {typeof defaultValue === "string" && (
                  <>
                    <label htmlFor={key}>{label}</label>
                    <div>
                      <input
                        className="custom-field__input"
                        type="text"
                        value={values[flag] as string}
                        onChange={(event) => {
                          setFlag(flag, event.target.value);
                        }}
                        id={key}
                      />
                    </div>
                  </>
                )}
                {typeof defaultValue === "number" && (
                  <>
                    <label htmlFor={key}>{label}</label>
                    <div>
                      <input
                        className="custom-field__input"
                        type="number"
                        value={parseFloat(`${flagValues[flag]}`)}
                        onChange={(event) => {
                          setFlag(flag, parseFloat(event.target.value));
                        }}
                        id={key}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            <p className="paragraph-tertiary">{description}</p>
            <button
              type="button"
              onClick={() => resetFlagValue(flag)}
              title={`${defaultValue}`}
              className="btn btn--primary btn--small"
            >
              Reset
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default flags;
