import React from "react";

import "./Modal.scss";

interface ModalProps {
  show: boolean;
  childComp: React.ReactNode;
  onClose: () => void;
}

const Modal = ({ show, childComp, onClose }: ModalProps): JSX.Element => {
  return show ? (
    <div onClick={onClose} className="modal">
      <div className="modal-content">{childComp}</div>
    </div>
  ) : null;
};

export default Modal;
