import React, { useState } from "react";

import { Formik, Form, ErrorMessage } from "formik";

import { ImageListType } from "react-images-uploading";
import validationSchema from "./validationSchema";
import {
  CustomImageTypeList,
  dataSourceType,
  patchCplBrandPortalApi,
} from "../../DataTypes";

import MultipleImageUploader from "../MultipleImageUploader/MultipleImageUploader";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import MultipleImageUploaderWithDescription from "../MultipleImageUploaderWithDescription/MultipleImageUploaderWithDescription";
import TextArea from "../TextArea/TextArea";

import { trimStringToLast25Chars } from "../../utils";
import {
  maxLifestyleImages,
  maxProductShotImages,
} from "../../pages/CostPerLead/utils";

interface CplAssetsCollectionFormProps {
  dataSource: dataSourceType;
  onSubmit: (values: patchCplBrandPortalApi) => void;
  companyLogo: ImageListType;
  setCompanyLogo: (images: ImageListType) => void;
  lifestyleImages: ImageListType;
  setLifestyleImages: (images: ImageListType) => void;
  removeUploadedLifestyleImage: (imageId: number) => Promise<void>;
  productShotImages: CustomImageTypeList;
  setProductShotImages: (images: CustomImageTypeList) => void;
  removeUploadedProductShotImage: (imageId: number) => Promise<void>;
  cplVideo?: File;
  setCplVideo: (video: File) => void;
  videoPreview?: string;
  setVideoPreview: (video: string) => void;
  disabled: boolean;
  canDelete: boolean;
}

const CplAssetsCollectionForm = ({
  dataSource,
  onSubmit,
  companyLogo,
  setCompanyLogo,
  lifestyleImages,
  setLifestyleImages,
  removeUploadedLifestyleImage,
  productShotImages,
  setProductShotImages,
  removeUploadedProductShotImage,
  setCplVideo,
  videoPreview,
  setVideoPreview,
  disabled,
  canDelete,
}: CplAssetsCollectionFormProps): JSX.Element => {
  const [uploadedCplCompanyLogo, setUploadedCplCompanyLogo] = useState<number>(
    dataSource.company?.image ? 1 : 0
  );
  const [uploadedLifestyleImages, setUploadedLifestyleImages] =
    useState<number>(dataSource.brand_assets?.lifestyle_images?.length);
  const [uploadedProductShotImages, setUploadedProductShotImages] =
    useState<number>(dataSource.brand_assets?.product_shot_images?.length);

  const maxCplCompanyLogo = 1;
  const minimumLifestyleImages = 5;

  const initialValues = {
    brand_description: dataSource.company.brand_description || "",
    no_cpl_videos: dataSource.no_cpl_videos || false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, setFieldValue, values }) => (
        <Form className="form sweepstakes-deliverables">
          <h2 className="heading-secondary">Upload Your Assets</h2>
          <article>
            <h3 className="heading-tertiary u-mb-medium">
              1. Provide a description of your brand
            </h3>
            <TextArea
              cols={40}
              maxLength={350}
              name="brand_description"
              id="brand_description"
              onChange={handleChange}
              defaultValue={values.brand_description}
              disabled={disabled}
              data-testid="test_textbox"
            />
            <ErrorMessage
              name="brand_description"
              component="div"
              className="form__control--error"
            />
          </article>

          <article>
            <h3 className="heading-tertiary u-mb-medium">
              2. Upload your logo ({uploadedCplCompanyLogo} /{" "}
              {maxCplCompanyLogo})
            </h3>
            <p className="paragraph-secondary">
              Please provide your logo in PNG format with transparent
              background.
            </p>

            <MultipleImageUploader
              maxImageNumber={maxCplCompanyLogo}
              setUploadedImages={setUploadedCplCompanyLogo}
              imageState={companyLogo}
              setImageState={setCompanyLogo}
              disabled={disabled}
              canDelete={canDelete}
            />
          </article>

          <article>
            <h3 className="heading-tertiary">
              3. Upload your lifestyle images ({uploadedLifestyleImages} /{" "}
              {maxLifestyleImages})
            </h3>
            <p className="paragraph-secondary">
              Please upload {maxLifestyleImages} lifestyle images for us to use
              for promoting your offer/product(s). We recommend a minimum of{" "}
              {minimumLifestyleImages} images.
            </p>
            <p className="paragraph-secondary">
              Size requirements: 1:1 ratio (800 x 800px) up to 200KB each.  You
              can compress your files before uploading{" "}
              <a
                href="https://squoosh.app/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                here
              </a>
            </p>

            <MultipleImageUploader
              maxImageNumber={maxLifestyleImages}
              setUploadedImages={setUploadedLifestyleImages}
              imageState={lifestyleImages}
              setImageState={setLifestyleImages}
              disabled={disabled}
              canDelete={canDelete}
              removeUploadedImage={removeUploadedLifestyleImage}
            />
          </article>

          <article>
            <h3 className="heading-tertiary">
              4. Upload your product shot images ({uploadedProductShotImages} /{" "}
              {maxProductShotImages})
            </h3>
            <p className="paragraph-secondary">
              Please upload{" "}
              <span className="bold">
                {maxProductShotImages} product images
              </span>{" "}
              for us to use for promoting your offer/products.
            </p>
            <p className="paragraph-secondary">
              If your company only offers a small amount of products or
              services, consider different lifestyle images representing your
              offer
            </p>
            <p className="paragraph-secondary">
              Some of the images will be presented as small icons, make sure
              your images don't look too similar to each other and are easily
              distinguishable
            </p>
            <p className="paragraph-secondary">
              Size requirements: 1:1 ratio (800 x 800px) up to 200KB each. You
              can compress your files before uploading{" "}
              <a
                href="http://squoosh.app/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                here
              </a>
            </p>
            <MultipleImageUploaderWithDescription
              maxImageNumber={maxProductShotImages}
              setUploadedImages={setUploadedProductShotImages}
              imageState={productShotImages}
              setImageState={setProductShotImages}
              disabled={disabled}
              canDelete={canDelete}
              removeUploadedImage={removeUploadedProductShotImage}
            />
          </article>

          <article>
            <h3 className="heading-tertiary">5. Upload your video</h3>
            <p className="paragraph-secondary">
              Recommended video specifications: MOV or MP4 formats up to 15MB in
              size.
            </p>
            <p className="paragraph-secondary">
              The volume will be defaulted to off, please use a video that does
              not depend on sound. If your video exceeds 15MB, you can compress
              your file before uploading{" "}
              <a
                href="http://www.youcompress.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                here
              </a>
              .
            </p>
            <p className="paragraph-secondary">
              If you don't have a video, we will use lifestyle images instead
            </p>

            {!values.no_cpl_videos && (
              <input
                type="file"
                className="form__control form__control__video-upload"
                accept="video/*,.mov,.mp4"
                disabled={disabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCplVideo(event.currentTarget.files[0]);
                  setVideoPreview(
                    URL.createObjectURL(event.currentTarget.files[0])
                  );
                }}
              />
            )}
            {videoPreview && (
              <div className="imageData video-preview">
                <video controls width="250" src={videoPreview} />
                <div>{trimStringToLast25Chars(videoPreview)}</div>
              </div>
            )}

            <label className="form__control">
              <input
                type="checkbox"
                id="no_cpl_video"
                name="no_cpl_video"
                defaultChecked={values.no_cpl_videos}
                onChange={(event) => {
                  setFieldValue("no_cpl_videos", event.target.checked);
                  setCplVideo(null);
                  setVideoPreview(null);
                  handleChange(event);
                }}
                disabled={disabled}
              />
              I don't want to use a video for my CPL. Use lifestyle images
              instead
            </label>
          </article>

          {!disabled && <SpinnerButton />}
        </Form>
      )}
    </Formik>
  );
};

export default CplAssetsCollectionForm;
