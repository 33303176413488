import { Contact, PhoneNumber, User } from "../../apiClient/data-contracts";
import { Company } from "../../DataTypes";

export const getCurrentContact = (
  company: Company,
  user: User
): Contact | undefined =>
  company.contacts?.find((contact) => contact.contact?.user_id === user.id)
    ?.contact;

export const getSecondaryContact = (
  company: Company,
  user: User
): Contact | undefined => {
  const loggedUser = getCurrentContact(company, user);
  return company.contacts?.find(
    (contact) => contact.contact?.user_id !== loggedUser?.user_id
  )?.contact;
};

// TODO: add tests to these functions

// fetch the first available alternate email address
export const getAlternateEmailAddress = (
  contact: Contact
): string | undefined => {
  return contact?.email_addresses && contact?.email_addresses?.length > 0
    ? contact?.email_addresses[0].email
    : "";
};

export const getPhoneNumber = (
  contact: Contact,
  type: string
): PhoneNumber | undefined =>
  contact?.phone_numbers?.find(
    (phoneNumber) => phoneNumber.number_type === type
  );
