import {
  BrandSigning as BaseBrandSigning,
  SigningHistory as BaseSigningHistory,
  Payment as BasePayment,
} from "../apiClient/data-contracts";
import { PrizingOptions } from "../DataTypes";

export enum RecurringFrequency {
  "Monthly" = 1,
  "EOM",
  "2x per quarter",
  "Quarterly",
  "Every 6 months",
  "Multi",
  "Annual",
}

export enum TypeOfAgreement {
  complementaryParticipating = 1,
  complementaryLead,
  participatingAugmenter,
  leadAugmenter,
  recurring,
  oneOff,
  extraServices,
}

export enum PaymentStatus {
  pending,
  success,
  failure,
  onHold,
  declined,
  voided,
  refunded,
}

export interface Payment extends BasePayment {
  status: PaymentStatus;
  amount: number;
}

export interface BrandSigning extends BaseBrandSigning {
  prizing_option?: PrizingOptions;
  sow_payment?: Payment;
}

export interface SigningHistory extends BaseSigningHistory {
  prizing_option?: PrizingOptions;
}
