enum DayColor {
  MONDAY = "rgba(153, 120, 180, 1)",
  TUESDAY = "rgba(245, 151, 171, 1)",
  WEDNESDAY = "rgba(249, 181, 139, 1)",
  THURSDAY = "rgba(255, 247, 144, 1)",
  FRIDAY = "rgba(182, 216, 133, 1)",
  SATURDAY = "rgba(107, 202, 223, 1)",
  SUNDAY = "rgba(113, 172, 221, 1)",
}

export const Colors = [
  DayColor.MONDAY,
  DayColor.TUESDAY,
  DayColor.WEDNESDAY,
  DayColor.THURSDAY,
  DayColor.FRIDAY,
  DayColor.SATURDAY,
  DayColor.SUNDAY,
];
