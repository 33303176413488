import React, { FC, useContext, useMemo } from "react";

import CheckoutForm from "./CheckoutForm";
import CompanyContext from "../../../state/contexts/company";
import {
  BrandSigning,
  CreditCardValues,
  ProcessPaymentArgs,
  ProcessPaymentState,
  PaymentStatus,
} from "../../../DataTypes";
import { getCurrentBrandSigning } from "../../StatementOfWork/utils";
import { Companies } from "../../../apiClient/Company";
import { Payments } from "../../../apiClient/Payment";
import { useAuth } from "../../../state/auth";
import american from "../../../assets/images/card-american.png";
import discover from "../../../assets/images/card-discover.png";
import dinners from "../../../assets/images/card-dinners.png";
import jcb from "../../../assets/images/card-jcb.png";
import mastercard from "../../../assets/images/card-mastercard.png";
import visa from "../../../assets/images/card-visa.png";
import { combineErrors, parsePayment } from "./CheckoutUtils";
import { Resolution, useResolution } from "../../../hooks/useResolution";
import BillingDetails from "../BillingDetails/BillingDetails";
import { handleError } from "../../../utils/errorHandler";
import { isDefined } from "../../../utils";
import CheckoutContext from "../../../state/contexts/checkout";
import PaymentProcessing from "./PaymentStates/PaymentProcessing";
import PaymentSuccessful from "./PaymentStates/PaymentSuccessful";
import PaymentError from "./PaymentStates/PaymentError";
import "./Checkout.scss";
import Loader from "../../Loader/Loader";
import {
  OnboardingStatus,
  useOnboardingFlow,
} from "../../../pages/Onboarding/OnboardingStatus";
import PaymentReminder from "../../PaymentReminder/PaymentReminder";
import { useNavigate } from "react-router-dom";
import paths from "../../../Paths";
import { Payment } from "apiClient/data-contracts";
import LastRecurringPaymentContext from "state/contexts/lastRecurringPayment";

async function processPayment({
  brandSigning,
  creditCardValues,
  token,
  onSuccess,
  onAnyCase,
  onErrors,
}: ProcessPaymentArgs) {
  try {
    const { data } = await new Payments().postBrandSigningPaymentApi(
      brandSigning.id,
      parsePayment(creditCardValues),
      {
        headers: {
          Authorization: token,
        },
      }
    );
    onSuccess(data);
  } catch (data) {
    // TODO: find the correct typing for this module
    // eslint-disable-next-line
    const requestErrors = combineErrors((data as any)?.error?.errors) || [
      "Error on request. Please contact support team.",
    ];
    console.error(requestErrors);
    onErrors(requestErrors);
  } finally {
    onAnyCase && onAnyCase();
  }
}

const creditCards = [
  { image: american, altText: "American logo" },
  { image: discover, altText: "Discover logo" },
  { image: dinners, altText: "Dinners logo" },
  { image: jcb, altText: "JCB logo" },
  { image: visa, altText: "Visa logo" },
  { image: mastercard, altText: "Mastercard logo" },
];

export const hasToPay = (brandSigning: BrandSigning): boolean =>
  !!brandSigning && !!brandSigning.sow_total_to_be_paid;

export const isLastPaymentFailed = (lastPayment: Payment): boolean =>
  !!lastPayment && lastPayment.status === PaymentStatus.failure;

const Checkout: FC = () => {
  const { loginInfo } = useAuth();
  const navigate = useNavigate();
  const { company, setCompany } = useContext(CompanyContext);
  const { paymentState, setPaymentState } = useContext(CheckoutContext);
  const { lastRecurringPayment } = useContext(LastRecurringPaymentContext);
  const brandSigning = useMemo(
    () => getCurrentBrandSigning(company),
    [company]
  );

  useOnboardingFlow(
    OnboardingStatus.CHECKOUT,
    isLastPaymentFailed(lastRecurringPayment)
  );

  const resolution = useResolution();
  const isSmallScreen =
    resolution === Resolution.MOBILE ||
    resolution === Resolution.TABLET_PORTRAIT;
  const shouldRenderBilling =
    isSmallScreen && paymentState === ProcessPaymentState.NotProcessing;
  const handleSubmit = async (creditCardValues: CreditCardValues) => {
    setPaymentState(ProcessPaymentState.Processing);
    await processPayment({
      brandSigning,
      creditCardValues,
      onSuccess: (payment: Payment) => {
        // We need to force-fetch the updated company, because the signing API doesn't return it
        const companyClient = new Companies();
        companyClient
          .getCompanyListApi({ headers: { Authorization: loginInfo.token } })
          .then((res) => {
            if (isDefined(res)) {
              setCompany(res.data[0]);
            }
          })
          .catch((error) => {
            console.error(handleError(error));
            return null;
          });
        isLastPaymentFailed(payment)
          ? setPaymentState(ProcessPaymentState.Failure)
          : setPaymentState(ProcessPaymentState.Success);
      },
      onErrors: () => {
        setPaymentState(ProcessPaymentState.Failure);
      },
      token: loginInfo.token,
    });
  };

  if (!brandSigning) {
    return (
      <section className="checkout onboarding-form-container">
        <Loader />
      </section>
    );
  }

  if (!hasToPay(brandSigning) && !isLastPaymentFailed(lastRecurringPayment)) {
    navigate(paths.home.url, { replace: true });
    return null;
  }

  if (paymentState !== ProcessPaymentState.NotProcessing) {
    return (
      <section className="checkout-state onboarding-form-container">
        {paymentState === ProcessPaymentState.Processing && (
          <PaymentProcessing />
        )}
        {paymentState === ProcessPaymentState.Success && <PaymentSuccessful />}
        {paymentState === ProcessPaymentState.Failure && <PaymentError />}
      </section>
    );
  }

  return (
    <section className="checkout onboarding-form-container">
      <h1 className="onboarding-title">Checkout</h1>
      <div className="credit-card-form">
        <PaymentReminder hideButton />
      </div>
      {shouldRenderBilling ? (
        <BillingDetails />
      ) : (
        <div className="card-logos">
          {creditCards.map((card) => (
            <img key={card.image} src={card.image} alt={card.altText} />
          ))}
        </div>
      )}
      <CheckoutForm onSubmit={handleSubmit} />
    </section>
  );
};

export default Checkout;
