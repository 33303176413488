import * as yup from "yup";
import {
  urlRegEx,
  numbersOnlyRegEx,
  numbersAndLettersRegEx,
} from "../../utils";
import { emailValidation } from "../../utils/validations";

const stateMaxLength = 4;
const charMaxLength = 255;
const zipCodeLengthValue = 10;
const phoneLength = 15;
const phoneExtensionLength = 5;

const charMaxLengthMessage = `Must be ${charMaxLength} characters or less`;
const requiredFieldMessage = "Field is required";
const zipCodeMessage = `Must be max. ${zipCodeLengthValue} digits`;
const numbersOnlyMessage = "Must be only digits";
const numbersAndLettersMessage = "Must be only digits and uppercase letters";
const urlMessage = "Must match URL format, https://www.example.com";
const phoneMessage = `Must be at most ${phoneLength} digits`;
const phoneExtensionMessage = `Must be at most ${phoneExtensionLength} digits`;

const emailIsUniqueMessage =
  "Alternative email must be different from main email";
const secondaryEmailIsUniqueMessage =
  "Alternative contact email can't be the same as your contact email";

const nameValidation = () =>
  yup.string().max(charMaxLength, charMaxLengthMessage);

const streetValidation = nameValidation;
const cityValidation = nameValidation;
const positionValidation = nameValidation;

const phoneValidation = () =>
  yup
    .string()
    .matches(numbersOnlyRegEx, numbersOnlyMessage)
    .max(phoneLength, phoneMessage);

const phoneExtensionValidation = () =>
  yup
    .string()
    .matches(numbersOnlyRegEx, numbersOnlyMessage)
    .max(phoneExtensionLength, phoneExtensionMessage);

const companyInformation = {
  parent_company: nameValidation().optional(),
  street_address_1: streetValidation().required(requiredFieldMessage),
  street_address_2: streetValidation().optional(),
  city: cityValidation().required(requiredFieldMessage),
  state: yup.string().required(requiredFieldMessage).max(stateMaxLength),
  zip_code: yup
    .string()
    .required(requiredFieldMessage)
    .matches(numbersAndLettersRegEx, numbersAndLettersMessage)
    .max(zipCodeLengthValue, zipCodeMessage),
  phone: phoneValidation().required(requiredFieldMessage),
  phone_extension: phoneExtensionValidation().optional(),
  url: yup
    .string()
    .required(requiredFieldMessage)
    .matches(urlRegEx, urlMessage),
};

const primaryContactInformation = {
  first_name: nameValidation().required(requiredFieldMessage),
  last_name: nameValidation().required(requiredFieldMessage),
  position_title: positionValidation().required(requiredFieldMessage),
  email_address_1: emailValidation().required(requiredFieldMessage),
  email_address_2: emailValidation()
    .optional()
    .notOneOf([yup.ref("email_address_1")], emailIsUniqueMessage),
  phone_1_number: phoneValidation().required(requiredFieldMessage),
  phone_1_extension: phoneExtensionValidation().optional(),
  phone_2_number: phoneValidation().required(requiredFieldMessage),
};

const secondaryContactInformation = {
  secondary_first_name: nameValidation().required(requiredFieldMessage),
  secondary_last_name: nameValidation().required(requiredFieldMessage),
  secondary_position_title: positionValidation().required(requiredFieldMessage),
  secondary_email_address_1: emailValidation()
    .notOneOf([yup.ref("email_address_1")], secondaryEmailIsUniqueMessage)
    .required(requiredFieldMessage),
  secondary_email_address_2: emailValidation()
    .optional()
    .notOneOf([yup.ref("secondary_email_address_1")], emailIsUniqueMessage),
  secondary_phone_1_number: phoneValidation().required(requiredFieldMessage),
};

const validationSchema = yup.object().shape({
  ...companyInformation,
  ...primaryContactInformation,
  ...secondaryContactInformation,
});

export default validationSchema;
