import React, { FC, useState } from "react";
import { useNavigate } from "react-router";

import ForgotPasswordForm from "./Form";

import { Auth } from "../../apiClient/Auth";
import { useLoading } from "../../state/loader";

import formImage from "../../assets/images/thought-catalog.jpg";
import logo from "!file-loader!../../assets/images/bp-logo.svg";

import { CustomError } from "../../utils/errorHandler";
import redirectionTrigger from "../../utils/redirectionTrigger";

import paths from "../../Paths";

import "./ForgotPassword.scss";
import { successToast } from "utils/toast";

const ForgotPassword: FC = () => {
  const { setLoading } = useLoading();
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const onSubmit = async (values: { email: string }) => {
    try {
      setLoading(true);
      const { data } = await new Auth().postForgotUserPassword(values);
      successToast(data.message);
      redirectionTrigger(navigate, paths.login.url);
    } catch (error) {
      setError((error as CustomError).error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password">
      <div className="forgot-password-form">
        <figure className="forgot-password-form__logo">
          <img src={logo} alt="logo" />
        </figure>
        <div className="forgot-password-form__text">
          <p className="paragraph-primary">Welcome!</p>
          <p className="paragraph-primary">Please enter your email address:</p>
        </div>
        <ForgotPasswordForm error={error} onSubmit={onSubmit} />
      </div>
      <figure className="forgot-password-image">
        <img src={formImage} alt="side banner" />
      </figure>
    </div>
  );
};

export default ForgotPassword;
