import React, { ReactNode } from "react";

import "./Body.scss";

type Props = {
  children?: ReactNode;
};

const Body = ({ children }: Props): JSX.Element => {
  return <div className="body">{children}</div>;
};

export default Body;
