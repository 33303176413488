import { FC, useEffect } from "react";
import {
  getOnboardingStatus,
  OnboardingStatus,
} from "../pages/Onboarding/OnboardingStatus";
import { useAuth } from "../state/auth";
import { loadZendesk, unloadZendesk } from "./zendeskLoader";

const Zendesk: FC = () => {
  const onboardingStatus = getOnboardingStatus();
  const { loginInfo } = useAuth();

  const loggedInAndOnboardingIncomplete =
    loginInfo.token &&
    (onboardingStatus as OnboardingStatus) !== OnboardingStatus.FINISHED;

  useEffect(() => {
    if (process.env.REACT_APP_ZENDESK_DISABLED === "true") {
      return;
    }

    if (!loginInfo.token || loggedInAndOnboardingIncomplete) {
      loadZendesk();
      return;
    }

    if (loginInfo.token) {
      unloadZendesk();
    }
  }, [loggedInAndOnboardingIncomplete, loginInfo]);

  return null;
};

export default Zendesk;
