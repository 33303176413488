import React from "react";
import { Route, Routes } from "react-router-dom";
import CPLAnalytics from "Components/CPLAnalytics/CPLAnalytics";
import CurrentSweepstakes from "Components/CurrentSweepstakes/CurrentSweepstakes";
import PastSweepstakes from "Components/PastSweepstakes/PastSweepstakes";

import Content from "Layout/Content/Content";
import Main from "Layout/Main/Main";
import NavBar from "Layout/NavBar/NavBar";
import Page from "Layout/Page/Page";
import Sidebar, { SidebarItem } from "Layout/Sidebar/Sidebar";

import paths from "Paths";

const Reports = (): JSX.Element => {
  return (
    <Page>
      <Sidebar>
        <SidebarItem
          path={paths.currentSweepstakes.url}
          name="Current Sweepstakes"
        />
        <SidebarItem path={paths.pastSweepstakes.url} name="Past Sweepstakes" />

        <SidebarItem
          path={paths.cplAnalytics.url}
          name="Cost Per Lead Analytics"
        />
      </Sidebar>
      <Content>
        <NavBar />
        <Main>
          <Routes>
            <Route
              path="current-sweepstakes"
              element={<CurrentSweepstakes />}
            />
            <Route path="past-sweepstakes" element={<PastSweepstakes />} />
            <Route path="cpl-analytics" element={<CPLAnalytics />} />
          </Routes>
        </Main>
      </Content>
    </Page>
  );
};

export default Reports;
