import React from "react";

import DatePicker from "react-datepicker";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

import ErrorFormDisplay from "../../../Components/ErrorFormDisplay/ErrorFormDisplay";
import SpinnerButton from "../../../Components/SpinnerButton/SpinnerButton";
import Loader from "../../Loader/Loader";

import { formatDateInUTC, getDateAsISOString, parseDate } from "../../../utils";
import { dataSourceType, OfferType } from "../../../DataTypes";

import "./OfferPromoCodeForm.scss";

export interface OfferFormProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  offer_type: OfferType;
  purchase_qualifications: string;
  purchase_qualifications_label: string;
  purchase_qualifications_note?: string;
  validationSchema: yup.AnyObjectSchema;
}

export interface OfferPromoValues {
  offer_type: OfferType;
  off_amount: number;
  off_amount_min_purchase_amount: number;
  free_gift: string;
  free_gift_min_purchase_qualifications: number;
  promo_code: string;
  exclusions_notes: string;
  /**
   * @deprecated
   */
  expiration?: string;
}

export interface OfferPromoCodeFormProps {
  offerFormProps: OfferFormProps;
  disabled: boolean;
  dataSourceType: dataSourceType;
  onSubmit: (values: OfferPromoValues) => void;
}

const OfferPromoCodeForm = ({
  offerFormProps,
  disabled,
  dataSourceType,
  onSubmit,
}: OfferPromoCodeFormProps): JSX.Element => {
  if (!dataSourceType) {
    return <Loader />;
  }

  const formInitialValues: OfferPromoValues = {
    offer_type: offerFormProps.offer_type,
    off_amount: dataSourceType.off_amount ?? ("" as unknown as number),
    off_amount_min_purchase_amount:
      dataSourceType.off_amount_min_purchase_amount ??
      ("" as unknown as number),
    free_gift: dataSourceType.free_gift || "",
    free_gift_min_purchase_qualifications:
      dataSourceType.free_gift_min_purchase_qualifications ??
      ("" as unknown as number),
    promo_code: dataSourceType.promo_code || "",
    exclusions_notes: dataSourceType.exclusions_notes || "",
    expiration:
      getDateAsISOString(dataSourceType.expiration) || getDateAsISOString(),
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={offerFormProps.validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const {
          values,
          setFieldValue,
          errors,
          isSubmitting,
          isValid,
          submitCount,
        } = formProps;
        return (
          <Form className="form__offer-detail">
            <label className="form-field">
              {offerFormProps.label}
              <Field
                type={offerFormProps.type}
                name={offerFormProps.name}
                className="form__input"
                placeholder={offerFormProps.placeholder}
                disabled={disabled}
              />
              <ErrorMessage
                name={offerFormProps.name}
                component="span"
                className="form-question__error"
              />
            </label>

            <label className="form-field">
              {offerFormProps.purchase_qualifications_label}
              <Field
                type="number"
                name={offerFormProps.purchase_qualifications}
                className="form__input"
                placeholder="$60"
                disabled={disabled}
              />
              <div className="paragraph-secondary">
                {offerFormProps.purchase_qualifications_note}
              </div>
              <ErrorMessage
                name={offerFormProps.purchase_qualifications}
                component="span"
                className="form-question__error"
              />
            </label>

            <label className="form-field">
              Promo Code{" "}
              <Field
                type="text"
                name="promo_code"
                className="form__input"
                placeholder="FKLFREE5"
                disabled={disabled}
              />
              <ErrorMessage
                name="promo_code"
                component="span"
                className="form-question__error"
              />
            </label>

            <label className="form-field">
              Expiration{" "}
              <DatePicker
                selected={parseDate(values.expiration)}
                name="expiration"
                onChange={(date) =>
                  setFieldValue("expiration", formatDateInUTC(date as Date))
                }
                className="form__input"
                placeholderText={new Date().toDateString()}
                dateFormat="EEE, MMM dd yyyy"
                disabled={disabled}
              />
              <ErrorMessage
                name="expiration"
                component="span"
                className="form-question__error"
              />
            </label>

            <label className="form-field form__textarea">
              Terms/Conditions of your offer{" "}
              <p className="paragraph-tertiary">
                Input any terms of the offer. Fine print, exclusions, apply,
                limit 1 per household, can't combine with other offers, etc.
              </p>
              <Field
                as="textarea"
                name="exclusions_notes"
                className="form__input"
                placeholder="Terms/Conditions of your offer"
                disabled={disabled}
              />
              <ErrorMessage
                name="exclusions_notes"
                component="span"
                className="form-question__error"
              />
            </label>

            {!disabled && <SpinnerButton />}

            <div className="form__errors">
              <ErrorFormDisplay
                errors={errors}
                isSubmitting={isSubmitting}
                submitCount={submitCount}
                isValid={isValid}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OfferPromoCodeForm;
