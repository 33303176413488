import React, { useRef } from "react";

import "./CopyBox.scss";

interface Props {
  value: string;
  alt?: string;
}

const CopyBox = ({ value, alt }: Props): JSX.Element => {
  const inputElem = useRef<HTMLInputElement>(null);
  const buttonElem = useRef<HTMLButtonElement>(null);

  const copyToClipboard = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    inputElem.current.focus();
    inputElem.current.select();
    inputElem.current.setSelectionRange(0, 9999);
    document.execCommand("copy");
    buttonElem.current.textContent = "Copied!";
  };

  return (
    <>
      <input
        type="text"
        className="copy-box__content"
        ref={inputElem}
        defaultValue={value}
        alt={alt}
      />
      <button
        className="copy-box__button"
        ref={buttonElem}
        onClick={copyToClipboard}
      >
        Copy
      </button>
    </>
  );
};

export default CopyBox;
