import React, { useState, useEffect } from "react";

type itemType = {
  name: string;
  key: number;
  label: string;
  isVisible: boolean;
  isCompleted: boolean;
  handler: (idx: number, state: boolean) => void;
};

interface props {
  key: number;
  item: itemType;
  isDisable: boolean;
  setFieldValue: (field: string, value: unknown) => void;
}

const TermCondCheckbox = ({
  item,
  isDisable,
  setFieldValue,
}: props): JSX.Element => {
  const [isBlur, setIsBlur] = useState<boolean>(item.isCompleted);
  const [checked, setChecked] = useState<boolean>(item.isCompleted);

  useEffect(() => {
    setChecked(isDisable || item.isCompleted);
  }, [item, isDisable]);

  const handleBlur = () => setIsBlur(!isBlur);

  return (
    item.isVisible && (
      <div key={item.key} className={isBlur ? "blur" : ""}>
        <input
          type="checkbox"
          className="spacing-checkbox"
          name={item.name}
          id={item.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              String(item.key),
              event.currentTarget.checked || item.isCompleted
            );
            handleBlur();
            item.handler(
              item.key,
              event.currentTarget.checked || item.isCompleted
            );
          }}
          disabled={isDisable || item.isCompleted}
          defaultChecked={checked}
        />
        <label htmlFor={item.name}>{item.label}</label>
      </div>
    )
  );
};

export default TermCondCheckbox;
