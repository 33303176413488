import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Cpl } from "../apiClient/Cpl";
import { CPL } from "../DataTypes";
import { useAuth } from "../state/auth";
import { useLoading } from "../state/loader";
import { CustomError, handleError } from "../utils/errorHandler";

interface IUseCpl {
  data: CPL;
  isLoading: boolean;
  error: unknown;
}

// TODO: replace the useCpl hook with this one after new UI is ready
export function useCplV2(): IUseCpl {
  const { loginInfo } = useAuth();

  const fetchCpl = async () => {
    const { data } = await new Cpl().getCplBrandPortalApi({
      headers: { Authorization: loginInfo.token },
    });
    return data;
  };
  const { data, isLoading, error } = useQuery("cpl", fetchCpl);
  return { data, isLoading, error };
}

export const useCpl = (): CPL => {
  const [cpl, setCpl] = useState<CPL>(null);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  useEffect(() => {
    const fetchCpl = async () => {
      try {
        setLoading(true);
        const { data } = await new Cpl().getCplBrandPortalApi({
          headers: { Authorization: loginInfo.token },
        });
        setCpl(data);
      } catch (error) {
        console.error(handleError(error as CustomError).message);
      } finally {
        setLoading(false);
      }
    };
    fetchCpl();
  }, [loginInfo, setLoading]);

  return cpl;
};
