import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router";

import "./CompanyInformation.scss";
import { BrandInformationPayload } from "../../../DataTypes";
import CompanyContext from "../../../state/contexts/company";
import { useLoading } from "../../../state/loader";
import { Contacts } from "../../../apiClient/Contact";
import { Companies } from "../../../apiClient/Company";
import { useAuth } from "../../../state/auth";
import {
  getCurrentContact,
  getSecondaryContact,
} from "../../BrandInformation/utils";
import {
  generateCompanyPayload,
  generatePrimaryContactPayload,
  generateSecondaryContactPayload,
} from "./CompanyInformationUtils";
import { isDefined } from "../../../utils";
import { CustomError, handleError } from "../../../utils/errorHandler";
import redirectionTrigger from "../../../utils/redirectionTrigger";
import paths from "../../../Paths";
import CompanyInformationForm from "./CompanyInformationForm";
import Loader from "../../Loader/Loader";
import { useUser } from "../../../hooks/useUser";
import {
  getFlowType,
  OnboardingFlowType,
  OnboardingStatus,
  useOnboardingFlow,
} from "../../../pages/Onboarding/OnboardingStatus";
import { errorToast } from "../../../utils/toast";

const CompanyInformation = (): JSX.Element => {
  const { company, setCompany } = useContext(CompanyContext);
  const user = useUser();
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const navigate = useNavigate();
  const flowType = getFlowType(company, user);

  useOnboardingFlow(OnboardingStatus.COMPANY_INFORMATION);

  const onSubmit = useCallback(
    async (values: BrandInformationPayload) => {
      setLoading(true);

      try {
        const contactClient = new Contacts();
        const companyClient = new Companies();
        const apiCallParams = {
          headers: { Authorization: loginInfo.token },
        };
        
        const secondaryContactPayload = generateSecondaryContactPayload(values);
        const primaryContactPayload = generatePrimaryContactPayload(values);
        const companyPayload = generateCompanyPayload(values);
        const contactId = getCurrentContact(company, user)?.id;
        const secondaryContactId = getSecondaryContact(company, user)?.id;
        const isSecondaryContactUpdated = isDefined(secondaryContactId);

        const secondaryContactApiCall = isSecondaryContactUpdated
          ? contactClient.patchContactApi(
              secondaryContactId,
              secondaryContactPayload,
              apiCallParams
            )
          : companyClient.postCompanyContactApi(
              company.id,
              secondaryContactPayload,
              apiCallParams
            );

        await Promise.all([
          secondaryContactApiCall,
          contactClient.patchContactApi(
            contactId,
            primaryContactPayload,
            apiCallParams
          ),
        ]);
        const companyResponse = await companyClient.patchCompanyApi(
          company.id,
          companyPayload,
          apiCallParams
        );

        setCompany(companyResponse.data);
        const nextUrl =
          flowType === OnboardingFlowType.JUST_COMPANY
            ? paths.home.url
            : paths.checklist.url;
        redirectionTrigger(navigate, nextUrl);
      } catch (error) {
        errorToast(handleError(error as CustomError).message);
      } finally {
        setLoading(false);
      }
    },
    [company, user, loginInfo, setCompany, setLoading, navigate, flowType]
  );

  return (
    <div className="company-information onboarding-form-container">
      {company && user ? (
        <CompanyInformationForm
          company={company}
          user={user}
          onSubmit={onSubmit}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CompanyInformation;
