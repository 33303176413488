import * as yup from "yup";
import { Choice, SmsName } from "../../DataTypes";

const validationSchema = (choices: Choice<SmsName>[]): yup.AnyObjectSchema => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFields = (choices: Choice<SmsName>[]): any => {
    return choices.reduce(
      (previousChoices, integration) => ({
        ...previousChoices,
        ...integration.fields.reduce(
          (previousFields, field) => ({
            ...previousFields,
            [`${integration.value}-${field.name}`]: yup
              .string()
              .when(["sms_provider_name", "sms_wanted_by_brand"], {
                is: (
                  sms_provider_name: SmsName,
                  sms_wanted_by_brand: boolean
                ) =>
                  sms_provider_name === integration.value &&
                  !!sms_wanted_by_brand,
                then: yup.string().required("Required"),
              }),
          }),
          {}
        ),
      }),
      {}
    );
  };

  return yup.object().shape({
    sms_terms_and_conditions: yup
      .string()
      .max(200, "Max 100")
      .nullable()
      .url("Please enter a valid URL address, e.g. https://www.example.com"),
    sms_privacy_policy: yup
      .string()
      .max(200, "Max 200")
      .nullable()
      .url("Please enter a valid URL address, e.g. https://www.example.com"),
    sms_unsubscribe_code: yup
      .string()
      .max(140, "Maximum characters exceeded")
      .nullable(),
    sms_unsubscribe_number: yup
      .string()
      .max(15, "Max 15 characters")
      .nullable(),
    sms_max_messages_month: yup.number().when("sms_wanted_by_brand", {
      is: true,
      then: yup.number().max(100, "Max 100").min(1, "Min 1"),
    }),
    sms_provider_name: yup.string().when("sms_wanted_by_brand", {
      is: true,
      then: yup.string().required("Please select a SMS Provider"),
    }),
    ...getFields(choices),
  });
};

export default validationSchema;
