import React, { FC } from "react";
import { bpBillingEmail } from "../../../utils/constants";
import CreditCardForm, { CreditCardValues } from "../Form";
import american from "../../../assets/images/american.png";
import discover from "../../../assets/images/discover.png";
import dinners from "../../../assets/images/dinners.png";
import jcb from "../../../assets/images/jcb.png";
import mastercard from "../../../assets/images/mastercard.png";
import visa from "../../../assets/images/visa.png";
import EmailLink from "./EmailLink";

const PaymentCardLogo: FC<{
  logo: string;
  altText: string;
}> = ({ logo, altText }) => (
  <img className="card-logo" src={logo} alt={altText} height="30px" />
);

export const PaymentBox = ({
  amount,
  onSubmit,
  errors,
}: {
  amount: number;
  onSubmit: (creditCardValues: CreditCardValues) => void;
  errors: string[];
}): JSX.Element => {
  return (
    <>
      <div>
        <p className="amount-due">
          <strong className="amount-due__title">Amount Due:</strong>
          <strong className="amount-due__total">${amount}</strong>
        </p>
      </div>
      <div>
        <h2>Payment Information</h2>
        <div>
          <PaymentCardLogo logo={american} altText="American Logo" />
          <PaymentCardLogo logo={discover} altText="Discover Logo" />
          <PaymentCardLogo logo={dinners} altText="Dinners Logo" />
          <PaymentCardLogo logo={jcb} altText="JCB Logo" />
          <PaymentCardLogo logo={mastercard} altText="Mastercard Logo" />
          <PaymentCardLogo logo={visa} altText="Visa Logo" />
        </div>
        <CreditCardForm onSubmit={onSubmit} />
      </div>
      {errors.length > 0 &&
        errors.map((error, idx) => (
          <p className="form-question__error" key={idx}>
            {error}
          </p>
        ))}
      <p>
        Questions on Payment? Contact you FindKeep.Love Account Manager or{" "}
        <EmailLink>{bpBillingEmail}</EmailLink>.
      </p>
    </>
  );
};
