import React from "react";
import { ErrorMessage, Field, useField } from "formik";
import clsx from "clsx";

import "./OnboardingInput.scss";
import { isDefined } from "../../../utils";

interface Props {
  name: string;
  label: string;
  type?: string;
  select?: boolean;
  id?: string;
  containerClass?: string;
  fieldClass?: string;
  labelClass?: string;
  disabled?: boolean;
}

const OnboardingInput: React.FC<Props> = (props) => {
  const {
    name,
    label,
    id,
    containerClass,
    fieldClass,
    labelClass,
    select,
    children,
    type = "text",
    disabled = false,
  } = props;
  const [, meta] = useField(props);
  const hasError = meta.touched && isDefined(meta.error);

  return (
    <div className={clsx("onboarding-text-input", containerClass)}>
      <label
        htmlFor={id || name}
        className={clsx("onboarding-text-input__label", labelClass)}
      >
        {label}
      </label>
      {select ? (
        <Field
          as="select"
          id={id || name}
          name={name}
          type="text"
          disabled={disabled}
          className={clsx("onboarding-text-input__field", fieldClass)}
        >
          <option value="" defaultChecked>
            Select
          </option>
          {children}
        </Field>
      ) : (
        <Field
          id={id || name}
          name={name}
          type={type}
          disabled={disabled}
          className={clsx(
            "onboarding-text-input__field",
            hasError && "has-error",
            fieldClass
          )}
        />
      )}
      <ErrorMessage
        name={name}
        component="span"
        className="onboarding-text-input__error"
      />
    </div>
  );
};

export default OnboardingInput;
