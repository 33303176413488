import React, { useContext, useEffect, useState } from "react";

import { ImageListType } from "react-images-uploading";
import { Cpl } from "../../apiClient/Cpl";

import {
  CPL,
  cplAssetFieldType,
  cplStatus,
  CustomImageTypeList,
  patchCplBrandPortalApi,
} from "../../DataTypes";
import CplContext from "../../state/contexts/cpl";
import { useAuth } from "../../state/auth";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../state/loader";
import { CustomError, handleError } from "../../utils/errorHandler";

import CplAssetsCollectionForm from "./CplAssetsCollectionForm";
import {
  cplSectionOrder,
  isCPlAssetsComplete,
} from "../../pages/CostPerLead/utils";
import nextIncompleteSection from "../../utils/nextSectionRedirection";

import "./CplAssetsCollection.scss";
import { bpContactEmail } from "../../utils/constants";
import { errorToast } from "../../utils/toast";

const CplAssetsCollection = (): JSX.Element => {
  const [companyLogo, setCompanyLogo] = useState<ImageListType>([]);
  const [lifestyleImages, setLifestyleImages] = useState<CustomImageTypeList>(
    []
  );
  const [productShotImages, setProductShotImages] =
    useState<CustomImageTypeList>([]);
  const [cplVideo, setCplVideo] = useState<File>();
  const [videoPreview, setVideoPreview] = useState<string>("");

  const { cpl, setCpl } = useContext(CplContext);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (cpl) {
      cpl.company.image &&
        setCompanyLogo([
          {
            dataURL: cpl.company.image,
          },
        ]);

      setLifestyleImages(
        cpl.brand_assets?.lifestyle_images.map(({ filename, id }) => ({
          id,
          dataURL: filename,
        }))
      );

      setProductShotImages(
        cpl.brand_assets?.product_shot_images?.map(
          ({ id, description, filename, title }) => ({
            id,
            dataURL: filename,
            description: description,
            title: title,
          })
        )
      );

      cpl.brand_assets?.cpl_videos?.length > 0 &&
        setVideoPreview(cpl.brand_assets.cpl_videos[0].filename);
    }
  }, [cpl]);

  const disabled = isCPlAssetsComplete(cpl);
  const canDelete = cpl?.status !== cplStatus.ACTIVE;

  const submitFormFields = async (
    cplId: number,
    values: patchCplBrandPortalApi
  ) => {
    const payload = companyLogo.every((image) => image.file)
      ? {
          ...values,
          image: companyLogo[0].file,
        }
      : values;

    const { data } = await new Cpl().patchCplBrandPortalApi(cplId, payload, {
      headers: {
        Authorization: loginInfo.token,
      },
    });
    return data;
  };

  const removeUploadedImage =
    (assetFieldType: cplAssetFieldType, cplId = cpl?.id) =>
    async (imageId: number) => {
      await new Cpl().deleteAssetsUploadApi(
        cplId,
        { asset_id: imageId, field: assetFieldType },
        { headers: { Authorization: loginInfo.token } }
      );
    };

  const submitImages =
    (assetFieldType: cplAssetFieldType) =>
    async (cplId: number, images: CustomImageTypeList): Promise<void> => {
      if (images.some((image) => image.file)) {
        await Promise.all(
          images
            .filter((image) => image.file)
            .map(
              async (image) =>
                await new Cpl().postAssetsUploadApi(
                  cplId,
                  {
                    field: assetFieldType,
                    filename: image.file,
                    description: image.description || "",
                    title: image.title || "",
                  },
                  {
                    headers: {
                      Authorization: loginInfo.token,
                    },
                  }
                )
            )
        );
      }
    };

  const submitVideo = async (cpl: CPL, cplVideo: File): Promise<void> => {
    const cplClient = new Cpl();

    if (cplVideo) {
      if (cpl.brand_assets.cpl_videos.length === 0) {
        await cplClient.postAssetsUploadApi(
          cpl.id,
          {
            field: cplAssetFieldType.CPL_VIDEOS,
            filename: cplVideo,
          },
          {
            headers: {
              Authorization: loginInfo.token,
            },
          }
        );
      } else {
        await cplClient.putAssetsUploadApi(
          cpl.id,
          {
            field: cplAssetFieldType.CPL_VIDEOS,
            filename: cplVideo,
            asset_id: cpl.brand_assets.cpl_videos[0].id,
          },
          {
            headers: {
              Authorization: loginInfo.token,
            },
          }
        );
      }
    }
    setCplVideo(null);
  };

  const submitLifestyleImages = submitImages(
    cplAssetFieldType.LIFESTYLE_IMAGES
  );

  const submitProductShotImages = submitImages(
    cplAssetFieldType.PRODUCT_SHOT_IMAGES
  );

  const removeUploadedLifestyleImage = removeUploadedImage(
    cplAssetFieldType.LIFESTYLE_IMAGES
  );

  const removeUploadedProductShotImage = removeUploadedImage(
    cplAssetFieldType.PRODUCT_SHOT_IMAGES
  );

  const onSubmit = async (values: patchCplBrandPortalApi): Promise<void> => {
    try {
      setLoading(true);

      await submitLifestyleImages(cpl.id, lifestyleImages);
      await submitProductShotImages(cpl.id, productShotImages);
      await submitVideo(cpl, cplVideo);

      const data = await submitFormFields(cpl.id, values);

      setCpl(data);
      alert("Data Saved!");
      nextIncompleteSection(navigate, cplSectionOrder(data));
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  const props = {
    dataSource: cpl,
    onSubmit,
    companyLogo,
    setCompanyLogo,
    lifestyleImages,
    setLifestyleImages,
    removeUploadedLifestyleImage,
    productShotImages,
    setProductShotImages,
    removeUploadedProductShotImage,
    cplVideo,
    setCplVideo,
    videoPreview,
    setVideoPreview,
    disabled,
    canDelete,
  };

  return (
    cpl && (
      <section className="cpl-assets">
        <h1 className="heading-primary">CPL Assets Collection</h1>
        <CplAssetsCollectionForm {...props} />
        {disabled ||
          (!canDelete && (
            <p className="cpl-assets__contact">
              Please contact your Account Manager or{" "}
              <a className="link link--black" href={`mailto:${bpContactEmail}`}>
                {bpContactEmail}
              </a>{" "}
              if you want to delete current assets
            </p>
          ))}
      </section>
    )
  );
};

export default CplAssetsCollection;
