import { useCallback } from "react";
import { Cpl } from "../../../../../../apiClient/Cpl";
import { HttpResponse } from "../../../../../../apiClient/http-client";
import { CPL } from "../../../../../../DataTypes";
import { useAuth } from "../../../../../../state/auth";

const useVideoUpload = (
  cpl: CPL
): ((filename: File) => Promise<HttpResponse<CPL, unknown>>) => {
  const { loginInfo } = useAuth();

  return useCallback(
    async (filename: File) =>
      new Cpl().postAssetsUploadApi(
        cpl?.id,
        {
          field: "cpl_videos",
          filename,
        },
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      ),
    [cpl?.id, loginInfo?.token]
  );
};

export default useVideoUpload;
