import React from "react";
import { Field } from "formik";
import clsx from "clsx";

import "./OnboardingCheckbox.scss";

interface Props {
  id: string;
  name: string;
  value?: string;
  containerClass?: string;
  labelClass?: string;
  disabled?: boolean;
}

const OnboardingCheckbox: React.FC<Props> = ({
  id,
  name,
  containerClass,
  labelClass,
  children,
  value,
  disabled,
}) => (
  <div
    className={clsx(
      "onboarding-checkbox",
      containerClass,
      disabled && "onboarding-checkbox__disabled"
    )}
  >
    <Field
      type="checkbox"
      name={name}
      id={id}
      value={value}
      disabled={disabled}
    />
    <label
      htmlFor={id}
      className={clsx("onboarding-checkbox__label", labelClass)}
    >
      {children}
    </label>
  </div>
);

export default OnboardingCheckbox;
