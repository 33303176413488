import { Companies } from "../apiClient/Company";
import { Company } from "../DataTypes";

const getContactCompany = (companies: Company[]): Company => companies[0];

export async function getCompany(token: string): Promise<Company> {
  const { data } = await new Companies().getCompanyListApi({
    headers: { Authorization: token },
  });

  return getContactCompany(data);
}
