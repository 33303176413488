import React, { useEffect, useState } from "react";
import { useIsFetching } from "react-query";

import BarChart from "../Charts/BarChart/BarChart";
import Loader from "../Loader/Loader";

import { useSweepsReport } from "../../hooks/useReport";
import useSweeps from "../../hooks/useSweeps";

import {
  convertToPercentageOneDecimal,
  daysRemainingFromCurrentDate,
} from "../../utils";
import { handleSweepReportDownload } from "../../pages/Reports/utils";

import { SweepstakeStatus } from "../../DataTypes";
import NavigationArrows from "../NavigationArrows/NavigationArrows";
import FileDownloader from "../FileDownloader/FileDownloader";
import { Sweep, SweepReport } from "../../apiClient/data-contracts";

import "./CurrentSweepstakes.scss";

const NoSweeps = ({
  message = "No live sweepstakes available",
}: {
  message?: string;
}): JSX.Element => <h2 className="heading-secondary">{message}</h2>;

const SweepReportChart = ({
  sweepReport,
  currentSweep,
  sweeps,
  currentIndex,
  changeCurrentSweep,
}: {
  sweepReport: SweepReport;
  currentSweep: Sweep;
  sweeps: Sweep[];
  currentIndex: number;
  changeCurrentSweep: (index: number) => void;
}): JSX.Element => (
  <section className="current-sweepstakes">
    <h1 className="heading-primary current-sweepstakes__title">
      Reporting &amp; Analytics
    </h1>
    <h3 className="current-sweepstakes__subtitle">Opt-in report</h3>

    <article className="chart">
      <header className="chart__heading">
        <div className="chart__left-col">
          <h1 className="chart__title">{sweepReport.name}</h1>
          <p className="chart__date">
            {sweepReport.start_date} - {sweepReport.end_date} (
            {daysRemainingFromCurrentDate(sweepReport.end_date)} days remaining)
          </p>
        </div>
        <div className="chart__right-col">
          <p className="chart__totals">
            Total opt-ins to date:{" "}
            <span className="bold uppercase">{sweepReport.total_opt_ins}</span>
          </p>
          <FileDownloader
            report={currentSweep}
            downloadHandler={() => handleSweepReportDownload(currentSweep)}
          />
        </div>
      </header>

      <BarChart
        report={{
          startDate: sweepReport.start_date,
          endDate: sweepReport.end_date,
          optInsData: sweepReport.opt_ins_data,
          totalOptInsData: sweepReport.total_opt_ins_data,
        }}
        companyName={currentSweep.company_name}
      />

      <footer className="chart__footer">
        <div className="chart__message">
          {currentSweep.esp &&
            `We are currently feeding your opt-ins via API into your ${currentSweep.esp} account`}
        </div>
        <div className="chart__summary">
          <ul className="chart__list">
            <li className="chart__list-item">
              {currentSweep.company_name} opt-ins to date:{" "}
              <span className="uppercase bold">{sweepReport.opt_ins}</span>
            </li>
            <li className="chart__list-item">
              {currentSweep.company_name} email list size:{" "}
              <span className="uppercase bold">{sweepReport.list_size}</span>
            </li>
            <li className="chart__list-item">
              {currentSweep.company_name} opt-in rate to date:{" "}
              <span
                className={`uppercase bold ${
                  sweepReport.opt_in_rate < 0.005 && "warning"
                } `}
              >
                {convertToPercentageOneDecimal(sweepReport.opt_in_rate)}
              </span>
            </li>
          </ul>
        </div>
      </footer>

      <div className="chart__notice">
        If you do not achieve your opt-in minimum, you are required to send a
        second email blast
      </div>
    </article>

    {sweeps.length > 1 ? (
      <NavigationArrows
        current={currentIndex}
        size={sweeps.length}
        changeCurrentItemPosition={changeCurrentSweep}
      />
    ) : null}
  </section>
);

const CurrentSweepstakes = (): JSX.Element => {
  const [sweeps, setSweeps] = useState<Sweep[]>([]);
  const [currentSweep, setCurrentSweep] = useState<Sweep>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const isFetching = useIsFetching();

  const { data: sweepsFromApi = [], error: sweepError } = useSweeps();
  const { data: reportFromApi = {}, error: reportError } =
    useSweepsReport(currentSweep);

  useEffect(() => {
    if (sweepsFromApi.length) {
      const liveSweeps = sweepsFromApi.filter(
        (sweep) => sweep.status === SweepstakeStatus.Live
      );
      setSweeps(liveSweeps);
      setCurrentSweep(liveSweeps[currentIndex]);
    }
  }, [sweepsFromApi, currentIndex]);

  const changeCurrentSweep = (index: number) => {
    setCurrentIndex(index);
    setCurrentSweep(sweeps[currentIndex]);
  };

  if (isFetching) {
    return <Loader />;
  }

  if (sweepError || reportError) {
    return <NoSweeps message="There was an error loading sweeps" />;
  }

  if (sweeps.length === 0 || !reportFromApi) {
    return <NoSweeps />;
  }

  const sweepReportProps = {
    sweepReport: reportFromApi,
    currentSweep,
    sweeps,
    currentIndex,
    changeCurrentSweep,
  };

  return <SweepReportChart {...sweepReportProps} />;
};

export default CurrentSweepstakes;
