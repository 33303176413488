import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../state/auth";

import paths from "../Paths";
import { AppContextProvider } from "state/providers/AppProvider";

const PrivateRoute: FC = ({ children }) => {
  const { loginInfo } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loginInfo.hasValidLogin()) {
      navigate(paths.login.url);
      return null;
    }
  }, [loginInfo, navigate]);

  return <AppContextProvider>{children}</AppContextProvider>;
};

export default PrivateRoute;
