import React, { ReactNode } from "react";

import "./Content.scss";

type Props = {
  children?: ReactNode
};

const Content = ({ children }: Props): JSX.Element => (
  <div className="content">{children}</div>
);

export default Content;
