import React, { useEffect, useState } from "react";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";

import Logo from "../../../../assets/images/bp-logo-white.svg";
import MenuButton from "../Buttons/MenuButton/MenuButton";
import Sidebar from "../Sidebar/Sidebar";
import { TABLET_LANDSCAPE_BREAKPOINT } from "../../../../hooks/useResolution";

import "./Navbar.scss";

const Navbar = (): JSX.Element => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > TABLET_LANDSCAPE_BREAKPOINT) {
      setToggleMenu(true);
    } else {
      setToggleMenu(false);
    }
  }, [width]);

  return (
    <aside className="navbar-v2">
      <figure className="brand-logo">
        <img src={Logo} alt="brand portal logo" />
      </figure>

      <MenuButton onClick={() => setToggleMenu(!toggleMenu)} />
      {toggleMenu && <Sidebar />}
    </aside>
  );
};

export default Navbar;
