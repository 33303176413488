import React, { createContext, ReactNode, useContext, useState } from "react";

type LoadingProvided = {
  loading: boolean;
  setLoading: (slot: boolean) => void;
};

const LoadingContext = createContext<LoadingProvided>({
  loading: false,
  setLoading: null,
});

const ProvideLoading = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingProvided {
  return useContext(LoadingContext);
}

export { useLoading, LoadingContext, ProvideLoading };
