import * as yup from "yup";

import { parseDate } from "../../utils";

interface formDates {
  startDate: string;
  weeks: number;
}

export const validateFutureDate = ({ startDate, weeks }: formDates): Date => {
  if (!startDate) {
    return;
  }
  const currentDate = parseDate(startDate);
  return new Date(
    currentDate.setDate(currentDate.getDate() + weeks * 7)
  );
};

const validationSchema = (formDates: formDates): yup.AnyObjectSchema => {
  const dateErrorMessage = `Date should be within 2 weeks of the sweep start date: ${formDates.startDate}`;
  return yup.object().shape({
    email_promotion_date: yup
      .date()
      .required(dateErrorMessage)
      .nullable()
      .max(validateFutureDate(formDates), dateErrorMessage),
    social_promotion_date: yup
      .date()
      .required(dateErrorMessage)
      .nullable()
      .max(validateFutureDate(formDates), dateErrorMessage),
    is_test_email_sent: yup.boolean(),
  });
};

export default validationSchema;
