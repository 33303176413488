import React, { useState, useContext, FC } from "react";
import { useNavigate } from "react-router-dom";

import { PrizingOptions, Slot, SlotPayload } from "../../DataTypes";
import { CreditCardForm, GiftCardForm, PhysicalForm } from "./Forms";

import { useAuth } from "../../state/auth";
import SlotContext from "../../state/contexts/slot";
import SweepContext from "../../state/contexts/sweep";
import { useLoading } from "../../state/loader";

import { Slots } from "../../apiClient/Slot";

import nextIncompleteSection from "../../utils/nextSectionRedirection";
import {
  isPrizingListComplete,
  sponsorChecklistSectionOrder,
} from "../../pages/SponsorChecklist/utils";

import paths from "../../Paths";
import { bpContactEmail } from "../../utils/constants";
import { errorToast } from "../../utils/toast";
import { CustomError, handleError } from "../../utils/errorHandler";

type SelectedPriceFormProps = {
  prizingOption: string;
  slot: Slot;
  onSubmit: (values: SlotPayload) => void;
  disabled: boolean;
};

const SelectedPriceForm = ({
  prizingOption,
  slot,
  onSubmit,
  disabled,
}: SelectedPriceFormProps): JSX.Element => {
  const props = {
    onSubmit,
    slot,
    disabled,
  };
  const prizeForm: Record<string, JSX.Element> = {
    [PrizingOptions.CreditCard]: <CreditCardForm {...props} />,
    [PrizingOptions.GiftCard]: <GiftCardForm {...props} />,
    [PrizingOptions.Physical]: <PhysicalForm {...props} />,
  };
  return prizeForm[prizingOption] || null;
};

const PrizingList: FC = () => {
  const { slot, setSlot } = useContext(SlotContext);
  const { sweep } = useContext(SweepContext);

  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();

  const [prizingOption, setPrizingOption] = useState<string>(
    slot?.sweeps_prize_option || PrizingOptions.GiftCard
  );

  if (!slot) {
    navigate(paths.sponsorChecklist.url);
    return null;
  }

  const disabled = isPrizingListComplete(slot);

  const onSubmit = async (values: SlotPayload) => {
    try {
      setLoading(true);
      const slotClient = new Slots();
      const response = await slotClient.patchSlotApi(slot.id, values, {
        headers: {
          Authorization: loginInfo.token,
        },
      });
      setSlot(response.data);
      alert("Data Saved!");
      nextIncompleteSection(
        navigate,
        sponsorChecklistSectionOrder(response.data, sweep)
      );
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setPrizingOption(event.target.value);
  };

  return (
    <div>
      <h1 className="heading-primary heading__font">Prizing Details</h1>
      <article>
        <p>All prizing requirements have a minimum of $400 value</p>
        <p>
          If this is different than what you selected, additional charges may
          apply. Please reach out to{" "}
          <a className="link" href={`mailto:${bpContactEmail}`}>
            {bpContactEmail}
          </a>{" "}
          for more details
        </p>
        <select
          onChange={handleChange}
          className="form__input"
          value={prizingOption}
          disabled={disabled}
          data-testid="test_prizing_options"
        >
          <option value={PrizingOptions.GiftCard}>
            OPTION 1: DIGITAL GIFT CARD TO COMPANY SITE (STRONGLY RECOMMENDED)
          </option>
          <option value={PrizingOptions.CreditCard}>
            OPTION 2: VISA OR AMEX GIFT CARD
          </option>
          <option value={PrizingOptions.Physical}>
            OPTION 3: PHYSICAL PRIZE
          </option>
        </select>
        <SelectedPriceForm
          prizingOption={prizingOption}
          slot={slot}
          onSubmit={onSubmit}
          disabled={disabled}
        />
      </article>
    </div>
  );
};

export default PrizingList;
