import { Report, ReportType } from "../../../DataTypes";
import { downloadFile, formatDateInUTC } from "../../../utils";

const getPath =
  (type: ReportType) =>
  (report: Report): string => {
    switch (type) {
      case ReportType.SWEEP:
        return `report/sweepstakes/${report.id}/optins-download`;
      case ReportType.CPL:
        return `report/cpls/${report.id}/optins-download`;
      default:
        throw new Error("invalid report type");
    }
  };

const getFileName = (report: Report): string =>
  `${report.name} ${formatDateInUTC(new Date())}.xlsx`;

const handleDownload =
  (type: ReportType) =>
  (report: Report): void => {
    const path = getPath(type)(report);
    const filename = getFileName(report);
    downloadFile(path, filename);
  };

export const handleCplReportDownload = handleDownload(ReportType.CPL);
export const handleSweepReportDownload = handleDownload(ReportType.SWEEP);
