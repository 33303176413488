import React from "react";

import toast, { Toast } from "react-hot-toast";
import { bpContactEmail } from "./constants";

const ToastCloseButton = ({ toastReference }: { toastReference: Toast }) => (
  <button
    style={{
      position: "absolute",
      top: "-10px",
      right: "-18px",
      border: "1px solid",
      width: "1.2rem",
      height: "1.2rem",
      borderRadius: " 50%",
    }}
    onClick={() => toast.dismiss(toastReference.id)}
  >
    X
  </button>
);

export const successToast = (message: string) => {
  toast.remove();
  return toast.success((t) => {
    return (
      <>
        <div
          style={{
            position: "relative",
          }}
        >
          <div>{message}</div>
          <ToastCloseButton toastReference={t} />
        </div>
      </>
    );
  });
};

export const errorToast = (message: string): string => {
  toast.remove();
  return toast.error((t) => {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div>
          {message}
          <br />
          Please contact us at {bpContactEmail} for assistance.
        </div>
        <ToastCloseButton toastReference={t} />
      </div>
    );
  });
};
