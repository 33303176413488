import React, { useContext, useMemo } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IMaskInput } from "react-imask";
import { Link } from "react-router-dom";

import SpinnerButton from "../../../Components/SpinnerButton/SpinnerButton";
import validationSchema from "../../PayYourBalance/validationSchema";
import CompanyContext from "../../../state/contexts/company";
import { getCurrentBrandSigning } from "../../StatementOfWork/utils";
import { bpContactEmail } from "../../../utils/constants";
import { CreditCardValues } from "../../../DataTypes";
import OnboardingInput from "../Input/OnboardingInput";
import paths from "../../../Paths";
import ErrorFormDisplay from "../../ErrorFormDisplay/ErrorFormDisplay";

interface Props {
  onSubmit: (creditCardValues: CreditCardValues) => void;
}

const CheckoutForm = ({ onSubmit }: Props): JSX.Element => {
  const initialValues: CreditCardValues = {
    credit_card_number: "",
    cvv: "",
    expiration_date: "",
    billing_zip_code: "",
    sow_signed: true,
  };
  const { company } = useContext(CompanyContext);

  const brandSigning = useMemo(() => {
    return company && getCurrentBrandSigning(company);
  }, [company]);

  if (!company || !brandSigning) {
    return (
      <p>
        Your FindKeep.Love plan is not ready yet. If you believe it is an error,
        please contact us at
        <a href={`mailto:${bpContactEmail}`} className="link">
          {bpContactEmail}
        </a>
      </p>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(new Date())}
    >
      {({ errors, isSubmitting, submitCount, isValid }) => (
        <Form className="credit-card-form">
          <div className="onboarding-form-row">
            <OnboardingInput
              name="credit_card_number"
              label="Card Number"
              fieldClass="input-card-number"
            />
          </div>
          <div className="onboarding-form-row card-details">
            <OnboardingInput
              name="billing_zip_code"
              label="Billing Zip Code"
              fieldClass="input-billing-zip"
            />
            <OnboardingInput
              name="cvv"
              label="CVV/CVS"
              fieldClass="input-cvv"
            />
            <div className="onboarding-text-input">
              <label htmlFor="expiration_date" className="onboarding-text-input__label">
                Expiration (mm/yy)
              </label>
              <Field name="expiration_date">
                {({ field }: { field: typeof Field }) => (
                  <IMaskInput
                    {...field}
                    id="expiration_date"
                    className="onboarding-text-input__field input-expiration"
                    pattern="\d\d/\d\d"
                    mask="00/00"
                  />
                )}
              </Field>
              <ErrorMessage
                name="expiration_date"
                component="span"
                className="onboarding-text-input__error"
              />
            </div>
          </div>
          <SpinnerButton label="Checkout" className="onboarding-button" />
          <div className="back-btn-container">
            <Link className="onboarding-back-btn" to={paths.checklist.url}>
              Back
            </Link>
          </div>
          <ErrorFormDisplay
            errors={errors}
            isSubmitting={isSubmitting}
            submitCount={submitCount}
            isValid={isValid}
          />
          <p className="onboarding-info">
            {`Questions about your payment? Contact your FindKeep.Love account manager or reach out to us at ${bpContactEmail}`}
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default CheckoutForm;
