import React, { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Auth } from "../../apiClient/Auth";

import { login, useAuth } from "../../state/auth";

import LoginForm, { FormValues } from "./Form/Form";
import { CustomError, handleError } from "../../utils/errorHandler";

import formImage from "../../assets/images/thought-catalog.jpg";
import logo from "../../assets/images/bp-logo.svg";

import { getCompany } from "../../utils/getCompany";
import { getFlowPath } from "../Onboarding/OnboardingStatus";
import "./Login.scss";
import { getUser } from "../../utils/getUser";

const Login: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoginInfo } = useAuth();

  const handleSubmit = async (values: FormValues) => {
    try {
      const { data } = await new Auth().postAuthApi(values);
      login({ ...data, setLoginInfo });
      const accessToken = data.access_token;
      const [company, user] = await Promise.all([
        getCompany(accessToken),
        getUser(accessToken),
      ]);
      const fromPath = (location?.state as { from: { pathname: string } })?.from
        ?.pathname;
      const redirectionRoute = getFlowPath(company, user, fromPath);
      navigate(redirectionRoute);
    } catch (err) {
      const { error } = err as CustomError;
      if (error?.message) {
        setError(error.message);
      } else {
        console.error(handleError(err as CustomError));
      }
    }
  };

  return (
    <div className="login">
      <div className="login-form">
        <figure className="login-form__logo">
          <img src={logo} alt="form login" />
        </figure>
        <div className="login-form__text">
          <p className="paragraph-primary">Welcome!</p>
          <p className="paragraph-primary">Sign in to your account:</p>
        </div>
        <LoginForm onSubmit={handleSubmit} error={error} />
      </div>
      <figure className="login-image">
        <img src={formImage} alt="side login banner" />
      </figure>
    </div>
  );
};

export default Login;
