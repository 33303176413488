import React, { FC } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoading } from "../../state/loader";
import clsx from "clsx";

type Props = {
  label?: string;
  className?: string;
};

const SpinnerButton: FC<Props> = ({ label, className }) => {
  const { loading } = useLoading();
  return (
    <button
      className={clsx("btn", "btn--submit", className)}
      type="submit"
      disabled={loading}
    >
      {loading && (
        <>
          <FontAwesomeIcon className="fa-pulse" icon={faSpinner} />{" "}
        </>
      )}
      {label || "SUBMIT"}
    </button>
  );
};

export default SpinnerButton;
