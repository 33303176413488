import * as yup from "yup";
import { useMemo } from "react";

export function useChecklistValidationSchema(listOfTC: string[]): unknown {
  return useMemo(() => {
    return yup.object().shape({
      terms: yup.bool().oneOf([true], "Terms and conditions must be accepted"),
      sow: yup.bool().oneOf([true], "Statement of work must be accepted"),
      tcGroup: yup.array().length(listOfTC.length),
    });
  }, [listOfTC]);
}
