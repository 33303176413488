import * as yup from "yup";

import { emailValidation } from "../../utils/validations";
import { email } from "../../utils/validations/messages";

const validationSchema = yup.object().shape({
  email: emailValidation().required(email.isRequired),
});

export default validationSchema;
