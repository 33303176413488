import React from "react";
import { FormikErrors } from "formik";
import { Key } from "../../apiClient/data-contracts";
import {
  ApiFields,
  Choice,
  EspName,
  EspPayload,
  PythonType,
  SmsName,
  SmsPayload,
} from "../../DataTypes";

import "./DisplayIntegrationFields.scss";

const parsePythonBooleanValue = (value: string): boolean => {
  try {
    return JSON.parse(value.toLowerCase());
  } catch (error) {
    return false;
  }
};

type IntegrationProps = {
  choices: Choice<EspName | SmsName>[];
  currentIntegrationName: EspName | SmsName;
  slotIntegrationName: string;
  keys?: Key[];
  disabled: boolean;
  handleChange: (e: React.ChangeEvent) => void;
  errors?: FormikErrors<SmsPayload | EspPayload>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

interface CustomOptInFieldProps {
  fieldName: string;
  message: string;
}

const DisplayIntegrationFields = ({
  choices,
  currentIntegrationName,
  slotIntegrationName,
  keys,
  handleChange,
  disabled,
  errors,
  setFieldValue,
}: IntegrationProps): JSX.Element => {
  const getKeys = (fieldName: string): string => {
    if (keys && currentIntegrationName === slotIntegrationName) {
      return keys.filter(({ key }) => key === fieldName)[0]?.value;
    }
    return "";
  };

  const CustomOptInFieldMessage = ({
    fieldName,
    message,
  }: CustomOptInFieldProps): JSX.Element =>
    fieldName === "single_optin" && (
      <p className="optin__field-message">{message}</p>
    );

  const anotherEspRecommended: EspName[] = ["Mailchimp", "ConstantContact"];
  const managerInTouchMessage: EspName[] = ["Other"];
  const singleOptInMessage: EspName[] = ["Klaviyo"];

  const renameFieldLabel = (
    field: ApiFields,
    integration: EspName | SmsName
  ): string => {
    if (integration === "Klaviyo" && field.name === "api_key") {
      return "private_api_key";
    }
    if (integration === "Mailchimp" && field.name === "audience_list") {
      return "audience_id";
    }
    return field.name;
  };

  return (
    <>
      {choices.map((integration, idx) => (
        <div key={idx}>
          {integration.value === currentIntegrationName && (
            <div>
              <div className="fields-container">
                {integration.fields.map((field: ApiFields, idy) => (
                  <div key={idy} className="form__control field-item">
                    <div>
                      <CustomOptInFieldMessage
                        fieldName={field.name}
                        message={
                          "I confirm the above list is set up as single opt-in in Klaviyo"
                        }
                      />
                      <label
                        htmlFor={`${currentIntegrationName}-${field.name}`}
                      >
                        {renameFieldLabel(field, integration.value)}
                      </label>
                    </div>
                    {field.type === PythonType.Bool ? (
                      <input
                        type="checkbox"
                        id={`${currentIntegrationName}-${field.name}`}
                        name={`${currentIntegrationName}-${field.name}`}
                        defaultChecked={parsePythonBooleanValue(
                          getKeys(field.name)
                        )}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            `${currentIntegrationName}-${field.name}`,
                            e.currentTarget.checked ? "on" : ""
                          );
                        }}
                        disabled={disabled}
                      />
                    ) : (
                      <input
                        id={`${currentIntegrationName}-${field.name}`}
                        name={`${currentIntegrationName}-${field.name}`}
                        defaultValue={getKeys(field.name)}
                        className="form__input"
                        onChange={handleChange}
                        disabled={disabled}
                      />
                    )}

                    {errors[
                      `${currentIntegrationName}-${field.name}` as keyof (
                        | EspPayload
                        | SmsPayload
                      )
                    ] && (
                      <div className="form__control--error">
                        {
                          errors[
                            `${currentIntegrationName}-${field.name}` as keyof (
                              | EspPayload
                              | SmsPayload
                            )
                          ]
                        }
                      </div>
                    )}
                    <div className="message">
                      {integration.value === "Mailchimp" &&
                        field.name === "audience_list" &&
                        "Audience ID of the Audience List you'd like the sweepstakes opt-ins to feed to"}
                      {integration.value === "Mailchimp" &&
                        field.name === "tag" &&
                        "Tag you'd like the sweepstakes opt-ins to feed to"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="esp-note">
                {managerInTouchMessage.includes(integration.value as EspName) &&
                  "* Your account manager will be in touch shortly to discuss API integration processes."}
                {anotherEspRecommended.includes(integration.value as EspName) &&
                  "* We would recommend using another ESP for this use-case"}
                {singleOptInMessage.includes(integration.value as EspName) &&
                  `Klaviyo defaults to double opt-in at the list level. Every
                  time you create a new list for FindKeep.Love use, you must visit the
                  individual list settings and update to single opt-in. This is
                  mandatory to proceed.`}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default DisplayIntegrationFields;
