import React from "react";
import Chart from "chart.js";
import { OptinReportPoint } from "../../../apiClient/data-contracts";
import { Colors } from "../../../DataTypes";
import {
  assignColorToLabel,
  convertDataToLabels,
  fillBrandOptInsByDate,
  formatDateLabel,
  formatFullDate,
  generateOptInsDatesRange,
  getOptinsBetweenDates,
} from "../utils";
import { Bar } from "react-chartjs-2";

interface Report {
  startDate: string;
  endDate: string;
  optInsData: OptinReportPoint[];
  totalOptInsData?: OptinReportPoint[];
  isCpl?: boolean;
}
interface BarChartProps {
  report: Report;
  companyName?: string;
}

const BarChart = ({ report, companyName }: BarChartProps): JSX.Element => {
  const totalOptInsChartData = convertDataToLabels(
    getOptinsBetweenDates(
      report.startDate,
      report.endDate,
      report.totalOptInsData
    )
  );

  const dates = generateOptInsDatesRange(totalOptInsChartData?.xLabels);
  const brandOptinsData = fillBrandOptInsByDate(report.optInsData, dates);
  const brandOptinChartData = convertDataToLabels(
    getOptinsBetweenDates(report.startDate, report.endDate, brandOptinsData)
  );

  const data: Chart.ChartData = {
    labels: totalOptInsChartData?.xLabels,
    datasets: [
      {
        data: totalOptInsChartData?.yLabels,
        backgroundColor: assignColorToLabel(
          totalOptInsChartData?.xLabels,
          Colors
        ),
        borderWidth: 1,
        hoverBorderColor: "black",
      },
      {
        hidden: true,
        type: "bubble",
        data: brandOptinChartData?.yLabels,
        backgroundColor: assignColorToLabel(
          totalOptInsChartData?.xLabels,
          Colors
        ),
        borderWidth: 1,
        hoverBorderColor: "black",
        fill: false,
      },
    ],
  };

  const options: Chart.ChartOptions = {
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Opt-ins",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Days",
          },
          gridLines: {
            drawBorder: true,
            display: false,
          },
          ticks: {
            maxRotation: 90,
            minRotation: 90,
            callback: (value) =>
              report.isCpl
                ? formatFullDate(value.toString())
                : formatDateLabel(value.toString()),
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: "#eff1f0",
      borderColor: "black",
      borderWidth: 1,
      cornerRadius: 0,
      titleFontColor: "black",
      bodyFontColor: "black",
      titleAlign: "center",
      bodyAlign: "center",
      displayColors: false,
      callbacks: {
        beforeTitle: (item) => formatFullDate(item[0].label),
        title: (item) => `${item[0].value} OPT-INS`,
        label: (item, data) =>
          companyName &&
          `${JSON.stringify(
            data.datasets[1].data[item.index]
          )} from ${companyName}`,
      },
    },
  };

  return <Bar type="bar" data={data} options={options} />;
};

export default BarChart;
