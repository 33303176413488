import React, { FC } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "react-query/devtools";
import Pages from "./pages/Pages";

import "react-datepicker/dist/react-datepicker.css";
import "./sass/main.scss";
import AppWrapper from "state/providers/AppProvider";
import Zendesk from "utils/ZendeskWrapper";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

const App: FC = () => {
  return (
    <AppWrapper>
      <Pages />
      <ReactQueryDevtools />
      <Zendesk />
    </AppWrapper>
  );
};

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>Error Happened</p>}>
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById("app")
);
