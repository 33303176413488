import { createContext } from "react";
import { CPL } from "../../DataTypes";

interface CplContextValue {
  cpl: CPL;
  setCpl: (cpl: CPL) => void;
}

const CplContext = createContext<CplContextValue>({
  cpl: null,
  setCpl: null,
});

export default CplContext;
