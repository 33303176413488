import React, { useEffect, useState } from "react";

import { SweepReport } from "../../apiClient/data-contracts";
import { Sweep, SweepstakeStatus } from "../../DataTypes";

import useSweeps from "../../hooks/useSweeps";

import { convertToPercentageOneDecimal } from "../../utils";
import { handleSweepReportDownload } from "../../pages/Reports/utils";

import BarChart from "../Charts/BarChart/BarChart";
import FileDownloader from "../FileDownloader/FileDownloader";
import Loader from "../Loader/Loader";
import { usePastSweepsReport } from "../../hooks/useReport";
import { useIsFetching } from "react-query";

interface SweepsListProps {
  sweeps: Sweep[];
  sweepsReport: SweepReport[];
}

const sortByDate = (sweepA: Sweep, sweepB: Sweep) =>
  new Date(sweepB.start_date).getTime() - new Date(sweepA.start_date).getTime();

const hasReport = (sweeps: Sweep[], sweepsReport: SweepReport[]) =>
  sweeps.length > 0 && sweepsReport.length > 0;

const NoSweeps = ({
  message = "No past sweepstakes available",
}: {
  message?: string;
}): JSX.Element => <h2 className="heading-secondary">{message}</h2>;

const SweepsList = ({ sweeps, sweepsReport }: SweepsListProps): JSX.Element => (
  <>
    {sweeps.map((sweep, id) => (
      <section key={sweep.id} className="current-sweepstakes">
        <article className="chart">
          <header className="chart__heading">
            <div className="chart__left-col">
              <h1 className="chart__title">{sweepsReport[id].name}</h1>
              <p className="chart__date">
                {sweepsReport[id].start_date} - {sweepsReport[id].end_date}
              </p>
            </div>
            <div className="chart__right-col">
              <p className="chart__totals">
                Total opt-ins to date:{" "}
                <span className="bold uppercase">
                  {sweepsReport[id].total_opt_ins}
                </span>
              </p>
              <FileDownloader
                report={sweep}
                downloadHandler={() => handleSweepReportDownload(sweep)}
              />
            </div>
          </header>

          <BarChart
            report={{
              startDate: sweepsReport[id].start_date,
              endDate: sweepsReport[id].end_date,
              optInsData: sweepsReport[id].opt_ins_data,
              totalOptInsData: sweepsReport[id].total_opt_ins_data,
            }}
            companyName={sweep.company_name}
          />

          <footer className="chart__footer">
            <div className="chart__summary">
              <ul className="chart__list">
                <li className="chart__list-item">
                  {sweep.company_name} opt-ins to date:{" "}
                  <span className="uppercase bold">
                    {sweepsReport[id].opt_ins}
                  </span>
                </li>
                <li className="chart__list-item">
                  {sweep.company_name} email list size:{" "}
                  <span className="uppercase bold">
                    {sweepsReport[id].list_size}
                  </span>
                </li>
                <li className="chart__list-item">
                  {sweep.company_name} opt-in rate to date:{" "}
                  <span className="uppercase bold">
                    {convertToPercentageOneDecimal(
                      sweepsReport[id].opt_in_rate
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </footer>
        </article>
      </section>
    ))}
  </>
);

const PastSweepstakes = (): JSX.Element => {
  const [sweeps, setSweeps] = useState<Sweep[]>([]);
  const isFetching = useIsFetching();

  const { data: sweepsFromApi = [], error } = useSweeps();
  const { data: sweepsReport = [], error: reportError } =
    usePastSweepsReport(sweeps);

  useEffect(() => {
    if (sweepsFromApi.length > 0) {
      const completedSweeps = sweepsFromApi.filter(
        (sweep) =>
          sweep.status === SweepstakeStatus.Completed ||
          sweep.status === SweepstakeStatus.Archived
      );
      completedSweeps.sort(sortByDate);
      setSweeps(completedSweeps);
    }
  }, [sweepsFromApi]);

  if (isFetching) {
    return <Loader />;
  }

  if (error || reportError) {
    return <NoSweeps message="There was an error loading sweeps" />;
  }

  if (!hasReport(sweeps, sweepsReport)) {
    return <NoSweeps />;
  }

  return (
    <>
      <h1 className="heading-primary current-sweepstakes__title">
        Reporting &amp; Analytics
      </h1>
      <h3 className="current-sweepstakes__subtitle">Opt-in reports</h3>
      <SweepsList sweeps={sweeps} sweepsReport={sweepsReport} />
    </>
  );
};

export default PastSweepstakes;
