import React, { FC } from "react";
import SlotProvider from "./SlotProvider";
import SweepProvider from "./SweepProvider";
import ReportProvider from "./ReportProvider";
import CompanyProvider from "./CompanyProvider";
import UserProvider from "./UserProvider";
import CplProvider from "./CplProvider";
import CheckoutProvider from "./CheckoutProvider";

import { ProvideAuth } from "../auth";
import { ProvideLoading } from "../loader";

import CombineProviders from "../utils/CombineProviders";
import { FeatureFlagsProvider } from "utils/featureFlags";
import { BrowserRouter } from "react-router-dom";
import ToasterProvider from "./ToasterProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import LastRecurringPaymentProvider from "./LastRecurringPaymentProvider";

const appProviders = [
  SweepProvider,
  SlotProvider,
  ReportProvider,
  CompanyProvider,
  UserProvider,
  CplProvider,
  LastRecurringPaymentProvider,
  CheckoutProvider,
];

export const AppContextProvider = CombineProviders(...appProviders);

const AppWrapper: FC<{ queryClient?: QueryClient }> = ({
  children,
  queryClient,
}) => {
  const reactQueryClient = queryClient || new QueryClient();

  return (
    <FeatureFlagsProvider>
      <BrowserRouter>
        <ToasterProvider>
          <ProvideAuth>
            <ProvideLoading>
              <QueryClientProvider client={reactQueryClient}>
                {children}
              </QueryClientProvider>
            </ProvideLoading>
          </ProvideAuth>
        </ToasterProvider>
      </BrowserRouter>
    </FeatureFlagsProvider>
  );
};

export default AppWrapper;
