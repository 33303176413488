import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import DatePicker from "react-datepicker";

import CopyBox from "../CopyBox/CopyBox";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import ErrorFormDisplay from "../ErrorFormDisplay/ErrorFormDisplay";

import validationSchema from "./validations";
import { Slot, SlotPayload, Sweep } from "../../DataTypes";

import { formatDateInUTC, parseDate } from "../../utils";
import { isSweepstakesPromotionComplete } from "../../pages/SponsorChecklist/utils";
import { bpContactEmail } from "../../utils/constants";

type Props = {
  slot: Slot;
  sweep: Sweep;
  onSubmit: (values: SlotPayload) => void;
};

const PromotionForm = ({ slot, sweep, onSubmit }: Props): JSX.Element => {
  const startDate = sweep?.start_date;
  const weeks = 2;

  const [emailTrackingLink, setEmailTrackingLink] = useState<string>("");
  const [socialMediaTrackingLink, setSocialMediaTrackingLink] =
    useState<string>("");

  useEffect(() => {
    const parseTrackingLinks = () => {
      slot.tracking_links.forEach((link) => {
        if (link.api_tracking_link.includes("utm_medium=social")) {
          setSocialMediaTrackingLink(link.api_tracking_link);
        } else if (link.api_tracking_link.includes("utm_medium=email")) {
          setEmailTrackingLink(link.api_tracking_link);
        } else {
          return;
        }
      });
    };

    parseTrackingLinks();
  }, [slot]);

  const isDisabled = isSweepstakesPromotionComplete(slot);

  const initialValues: SlotPayload = {
    email_promotion_date: slot?.email_promotion_date,
    social_promotion_date: slot?.social_promotion_date,
    is_test_email_sent: slot?.is_test_email_sent,
  };

  const CopyLink = (
    link: string | null,
    alt = "copy link",
    noLinkMessage = "Link not set yet!"
  ): JSX.Element =>
    link ? (
      <div className="form-promotion__copy-box">
        <CopyBox value={link} alt={alt} />
      </div>
    ) : (
      <div className="form-question__error">{noLinkMessage}</div>
    );

  return (
    <Formik
      validationSchema={validationSchema({ startDate, weeks })}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const {
          setFieldValue,
          values,
          errors,
          isSubmitting,
          isValid,
          submitCount,
        } = formProps;

        return (
          <Form data-testid="form" className="form form-promotion">
            <section className="form-promotion__section email-promotion">
              <h2 className="form-promotion__title">Email Promotion</h2>
              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  1. Email Promotion Date
                </h3>
                <div className="form__control">
                  <Field name="email_promotion_date">
                    {() => (
                      <DatePicker
                        selected={
                          parseDate(values.email_promotion_date) ||
                          parseDate(startDate)
                        }
                        onChange={(date) => {
                          setFieldValue(
                            "email_promotion_date",
                            formatDateInUTC(date as Date)
                          );
                        }}
                        className="form__input"
                        placeholderText={"Sat, Feb 20"}
                        dateFormat="EEE, MMM dd"
                        disabled={isDisabled}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="email_promotion_date"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  2. Copy your required email tracking link
                </h3>
                <p className="paragraph-secondary">
                  This link must be used in the email you send to your audience
                  to promote the sweepstakes.
                </p>
                {CopyLink(emailTrackingLink)}
              </div>

              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  3. Download email creative
                </h3>
                <p className="paragraph-secondary">
                  We've created an email template that you can use to promote
                  the sweepstakes. <br />
                  You're welcome to modify the email as long as you do not
                  modify the prize value or partner brand logos.
                </p>
                <p className="paragraph-tertiary">
                  * This download will open in a new window. Please right-click
                  on the image to save.
                </p>
                <a
                  className="btn btn--secondary"
                  href={sweep.email_creative}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </div>

              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  4. Email Footer Text
                </h3>
                <p>
                  FindKeep.Love requires the below text as part of your email
                  footer
                </p>
                <p className="paragraph__secondary quote">
                  "No purchase necessary to enter. Void where prohibited.
                  Eligibility and Sweepstakes subject to Official Rules and
                  Consumer Disclosure"
                </p>
                <p>
                  Please copy the below custom URL and link it to the word
                  "Rules" in the required footer text.
                </p>
                {CopyLink(
                  sweep.terms_and_conditions_link,
                  "terms_and_conditions_link"
                )}
              </div>

              <div className="form__group">
                <div className="form-promotion__reminder">
                  <h5 className="form-promotion__reminder-title">Reminder:</h5>
                  <p className="form-promotion__reminder-text">
                    <strong>
                      It's required that you send an email test to the
                      FindKeep.Love team (
                      <a href={`mailto:${bpContactEmail}`}>{bpContactEmail}</a>)
                      before setting it live for your audience. Have you
                      completed this test send yet?
                    </strong>
                  </p>
                  <Field name="emailPromotionReminder">
                    {({ field }: FieldProps) => (
                      <div className="form__control">
                        <div className="radio-item">
                          <input
                            {...field}
                            id="yes"
                            value="Yes"
                            defaultChecked={values.is_test_email_sent}
                            type="radio"
                            name="is_test_email_sent"
                            onChange={() => (values.is_test_email_sent = true)}
                            disabled={isDisabled}
                          />
                          <label htmlFor="yes">Yes</label>
                        </div>

                        <div className="radio-item">
                          <input
                            {...field}
                            id="no"
                            value="No"
                            defaultChecked={!values.is_test_email_sent}
                            type="radio"
                            name="is_test_email_sent"
                            onChange={() => (values.is_test_email_sent = false)}
                            disabled={isDisabled}
                          />
                          <label htmlFor="no">No</label>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </section>

            <section className="form-promotion__section social-promotion">
              <h2 className="form-promotion__title">Social + Blog Promotion</h2>

              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  5. Social media promotion date
                </h3>
                <div className="form__control">
                  <Field name="social_promotion_date">
                    {() => (
                      <DatePicker
                        selected={
                          parseDate(values.social_promotion_date) ||
                          parseDate(startDate)
                        }
                        onChange={(date) =>
                          setFieldValue(
                            "social_promotion_date",
                            formatDateInUTC(date as Date)
                          )
                        }
                        className="form__input"
                        placeholderText={"Sat, Feb 20"}
                        dateFormat="EEE, MMM dd"
                        disabled={isDisabled}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="social_promotion_date"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  6. Copy your required social media tracking link
                </h3>
                <p className="paragraph-secondary">
                  This link must be used in all social media posts that you
                  create to promote the sweepstakes.
                </p>
                {CopyLink(socialMediaTrackingLink)}
              </div>
            </section>

            <section className="form-promotion__section creatives">
              <h2 className="form-promotion__title">Creatives</h2>

              <div className="form__group">
                <h3 className="form-promotion__subtitle">
                  7. Sweepstakes creatives
                </h3>

                <p className="paragraph-secondary">
                  We've created email &amp; social templates that you can use to
                  promote the sweepstakes. The editable files are also available
                  if you would like to make changes, as long as you do not
                  modify the prize value or partner brand logos.
                </p>
              </div>

              <p className="paragraph-secondary">
                <a
                  href={sweep.dropbox_link}
                  className="black-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  All creative files can be accessed via this link.
                </a>
              </p>
            </section>

            {!isDisabled && (
              <div className="promotion__submit">
                <SpinnerButton />
              </div>
            )}

            <ErrorFormDisplay
              errors={errors}
              isSubmitting={isSubmitting}
              submitCount={submitCount}
              isValid={isValid}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PromotionForm;
