import React from "react";
import clsx from "clsx";
import "./Label.scss";

interface LabelProps {
  label: string;
  name: string;
  customClassName?: string[];
}

const Label = ({ customClassName, label, name }: LabelProps): JSX.Element => (
  <label htmlFor={name} className={clsx("label", customClassName)}>
    {label}
  </label>
);

export default Label;
