import React from "react";
import { Link } from "react-router-dom";
import { ErrorMessage, Formik, Form, Field } from "formik";

import validationSchema from "./validations";

import paths from "../../../Paths";

export interface FormValues {
  email: string;
  password: string;
}

type Props = {
  error?: string;
  onSubmit: (values: FormValues) => void;
};

const LoginForm = ({ error, onSubmit }: Props): JSX.Element => {
  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        <Form data-testid="form" className="login-form__form">
          <label className="login-form__form-group">
            <span className="login-form__form-label">Email:</span>
            <Field type="email" name="email" aria-label="email" />
            <ErrorMessage
              name="email"
              component="div"
              className="form__control--error"
            />
          </label>

          <label className="login-form__form-group">
            <span className="login-form__form-label">Password:</span>
            <Field type="password" name="password" aria-label="password" />
            <ErrorMessage
              name="password"
              component="div"
              className="form__control--error"
            />
          </label>

          {error && (
            <div className="capitalize-first-letter warning">{error}</div>
          )}

          <button className="btn btn--submit login-form__button" type="submit">
            Login
          </button>
        </Form>
      </Formik>
      <Link className="link login-form__link" to={paths.forgotPassword.url}>
        Forgot your password?
      </Link>
    </>
  );
};

export default LoginForm;
