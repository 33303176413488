/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  deleteAssetsUploadPayload,
  patchCplBrandPortalApi,
  patchCplPayload,
  postAssetsUploadPayload,
  putAssetsUploadPayload,
  CPL,
} from "../DataTypes";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Cpl extends HttpClient {
  /**
   * No description
   *
   * @tags cpl
   * @name GetCplBrandPortalApi
   * @summary Latest CPL from the current user's latest company
   * @request GET:/cpl/
   * @secure
   */
   getCplBrandPortalApi = (params?: RequestParams) =>
   this.request<CPL, any>(`/cpl/`, "GET", params, null, BodyType.Json, true);
  /**
   * No description
   *
   * @tags cpl
   * @name PostAssetsUploadApi
   * @summary Add new assets to CPL
   * @request POST:/cpl/{cpl_id}/assets/
   * @secure
   */
  postAssetsUploadApi = (
    cpl_id: number,
    data: postAssetsUploadPayload,
    params?: RequestParams,
  ) => this.request<CPL, any>(`/cpl/${cpl_id}/assets/`, "POST", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags cpl
   * @name PutAssetsUploadApi
   * @summary Updates an asset from a CPL
   * @request PUT:/cpl/{cpl_id}/assets/
   * @secure
   */
  putAssetsUploadApi = (
    cpl_id: number,
    data: putAssetsUploadPayload,
    params?: RequestParams,
  ) => this.request<any, any>(`/cpl/${cpl_id}/assets/`, "PUT", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags cpl
   * @name DeleteAssetsUploadApi
   * @summary Deletes an asset from a CPL
   * @request DELETE:/cpl/{cpl_id}/assets/
   * @secure
   */
  deleteAssetsUploadApi = (
    cpl_id: number,
    data: deleteAssetsUploadPayload,
    params?: RequestParams,
  ) => this.request<any, any>(`/cpl/${cpl_id}/assets/`, "DELETE", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags cpl
   * @name PatchEspApi
   * @summary Update CPL data for ESP integration
   * @request PATCH:/cpl/{cpl_id}/esp/
   * @secure
   */
  patchEspApi = (
    cpl_id: number,
    data: patchCplPayload,
    params?: RequestParams,
  ) => this.request<CPL, any>(`/cpl/${cpl_id}/esp/`, "PATCH", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags cpl
   * @name PatchCplBrandPortalApi
   * @summary Update CPL data for Offer/Promo
   * @request PATCH:/cpl/{cpl_id}/offer
   * @secure
   */
  patchCplBrandPortalApi = (
    cpl_id: number,
    data: patchCplBrandPortalApi,
    params?: RequestParams,
  ) => this.request<CPL, any>(`/cpl/${cpl_id}/offer`, "PATCH", params, data, BodyType.FormData, true);
}
