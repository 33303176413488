import clsx from "clsx";
import React, { DetailedHTMLProps, FC, LabelHTMLAttributes } from "react";
import "./FieldLabel.scss";

type FieldLabelProps = {
  secondary?: boolean;
  lowercase?: boolean;
} & DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

const FieldLabel: FC<FieldLabelProps> = ({
  children,
  secondary,
  lowercase,
  ...props
}) => {
  return (
    <label
      className={clsx(
        "custom-field_label",
        secondary
          ? "custom-field_label--secondary"
          : "custom-field_label--dark",
        lowercase && "custom-field_label--lowercase"
      )}
      {...props}
    >
      {children}
    </label>
  );
};

export default FieldLabel;
