import * as yup from "yup";
import { PrizingOptions, Slot } from "../../DataTypes";
import { requiredField } from "../../utils/validations/messages";

type SharedData = {
  sweeps_prize_option: PrizingOptions;
  sweeps_prize_value: number;
};

type ValuesGiftCard = SharedData & {
  sweeps_prize_description: string;
  sweeps_prize_code: string;
};

type ValuesCreditCard = SharedData;

type ValuesPhysical = SharedData & {
  sweeps_prize_description: string;
};

// Forms initial values

export const initialValuesGiftCard = (slot: Slot): ValuesGiftCard => {
  return {
    sweeps_prize_option: PrizingOptions.GiftCard,
    sweeps_prize_description: slot.sweeps_prize_description || "",
    sweeps_prize_code: slot.sweeps_prize_code || "",
    sweeps_prize_value: slot.sweeps_prize_value || null,
  };
};

export const initialValuesCreditCard = (slot: Slot): ValuesCreditCard => {
  return {
    sweeps_prize_option: PrizingOptions.CreditCard,
    sweeps_prize_value: slot.sweeps_prize_value || null,
  };
};

export const initialValuesPhysicalPrize = (slot: Slot): ValuesPhysical => {
  return {
    sweeps_prize_option: PrizingOptions.Physical,
    sweeps_prize_description: slot.sweeps_prize_description || "",
    sweeps_prize_value: slot.sweeps_prize_value || null,
  };
};

// Forms Schemas

const baseSchema = yup.object().shape({
  sweeps_prize_value: yup
    .number()
    .typeError(requiredField)
    .required(requiredField)
    .min(400, "Minimum value is 400"),
});

export const validationSchemaGiftCard = baseSchema.shape({
  sweeps_prize_description: yup.string().required(requiredField),
  sweeps_prize_code: yup.string().required(requiredField),
});

export const validationSchemaCreditCard = baseSchema;

export const validationSchemaPhysicalPrize = baseSchema.shape({
  sweeps_prize_description: yup.string().required(requiredField),
});
