import * as yup from "yup";
import { OfferType } from "../../DataTypes";
import { requiredField } from "../../utils/validations/messages";

/**
 *
 * @deprecated
 * expiration field is no longer required
 */
const monthsFrom =
  (months: number) =>
  (currentDate = new Date()): Date =>
    new Date(currentDate.setMonth(currentDate.getMonth() + months));

/**
 *
 * @deprecated
 * expiration field is no longer required
 */
const sixMonthsFrom = monthsFrom(6);

const numberFieldValidation = yup
  .number()
  .required(requiredField)
  .min(0, "Minimum Value is 0")
  .max(999, "Limit to 3 digits");

/**
 *
 * @deprecated
 * expiration field is no longer required
 */
const baseValidationSchema = (date = new Date()): yup.AnyObjectSchema =>
  yup.object().shape({
    promo_code: yup
      .string()
      .required(requiredField)
      .max(200, "Limit 200 characters")
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Only alphanumerical characters allowed expect spaces and :/,-.?=!"
      ),
    expiration: yup
      .date()
      .required(requiredField)
      .min(
        sixMonthsFrom(date),
        "Promo code cannot expire for at least 6 months (recommended evergreen)"
      ),
    exclusions_notes: yup
      .string()
      .required(requiredField)
      .max(1000, "Limit 1000 characters"),
  });

/**
 *
 * @deprecated
 * expiration field is no longer required
 */
const validationSchemaOffOffer = (
  currentDate = new Date()
): yup.AnyObjectSchema =>
  baseValidationSchema(currentDate).shape({
    off_amount_min_purchase_amount: numberFieldValidation,
    off_amount: numberFieldValidation.moreThan(14, "Only Numbers Above 14"),
  });

/**
 *
 * @deprecated
 * expiration field is no longer required
 */
const validationSchemaGiftOffer = (
  currentDate = new Date()
): yup.AnyObjectSchema =>
  baseValidationSchema(currentDate).shape({
    free_gift_min_purchase_qualifications: numberFieldValidation,
    free_gift: yup.string().required(requiredField).max(200),
  });

/**
 * This is the new validation schema used in the
 * Promo Offer form
 * src/Components/OfferPromoCode/v2/AcceptanceForm
 */
const validationSchema = yup.object().shape({
  offer_type: yup.string().required(requiredField),
  off_amount: yup.number().when("offer_type", {
    is: (val: string) => val === OfferType.OffOffer,
    then: numberFieldValidation.moreThan(14, "Only Numbers Above 14"),
    otherwise: yup.number().optional(),
  }),
  off_amount_min_purchase_amount: yup.number().when("offer_type", {
    is: (val: string) => val === OfferType.OffOffer,
    then: numberFieldValidation,
    otherwise: yup.number().optional(),
  }),
  free_gift: yup.string().when("offer_type", {
    is: (val: string) => val === OfferType.FreeGift,
    then: yup.string().required(requiredField).max(200),
    otherwise: yup.string().optional(),
  }),
  free_gift_min_purchase_qualifications: yup.number().when("offer_type", {
    is: (val: string) => val === OfferType.FreeGift,
    then: numberFieldValidation,
    otherwise: yup.number().optional(),
  }),
  promo_code: yup
    .string()
    .required(requiredField)
    .max(200, "Limit 200 characters")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Only alphanumerical characters allowed expect spaces and :/,-.?=!"
    ),
  exclusions_notes: yup
    .string()
    .required(requiredField)
    .max(1000, "Limit 1000 characters"),
});

export {
  validationSchemaOffOffer,
  validationSchemaGiftOffer,
  validationSchema,
};
