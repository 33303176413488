import * as yup from "yup";

const imageSchema = yup
  .object()
  .required("Add product images")
  .shape({
    title: yup
      .string()
      .required("Title is required")
      .max(100, "Max. 100 characters"),
    description: yup
      .string()
      .required("Description is required")
      .max(130, "Max. 130 characters"),
    isUploaded: yup.boolean(),
    image: yup
      .mixed()
      .required("Required")
      .test("imageUpload", "Invalid file", (value) => {
        if (!value || !value?.dataURL || typeof value.dataURL !== "string") {
          return false;
        }

        return true;
      }),
  });

const validationSchema = yup
  .array()
  .of(imageSchema)
  .min(10, "Upload 10 product images.")
  .max(10, "Upload 10 product images.");

export default validationSchema;
