import React, { useContext } from "react";

import OfferAcceptanceForm from "./OfferAcceptanceForm/OfferAcceptanceForm";
import CplContext from "../../state/contexts/cpl";

import "./OfferPromoCode.scss";
import { useNavigate } from "react-router-dom";
import paths from "../../Paths";

/**
 * @deprecated use the one in components/v2 folder
 * @returns JSX.Element
 */
const OfferPromoCode = (): JSX.Element => {
  const { cpl } = useContext(CplContext);
  const navigate = useNavigate();

  if (!cpl) {
    navigate(paths.home.url);
    return null;
  }

  return (
    <section className="offer-promo-code">
      <h1 className="heading-primary">CPL Offer / Promo Code</h1>
      <h2 className="heading-secondary">Submit Your Offer</h2>
      <p className="paragraph-secondary">
        Offers are required to be a free gift with a purchase with a retail
        value of $20 or more, or $20 or more off a purchase.
      </p>
      <p className="paragraph-secondary">(We do not allow a % off offer)</p>

      <OfferAcceptanceForm />
    </section>
  );
};

export default OfferPromoCode;
