import { useContext, useEffect, useMemo, useState } from "react";
import { getBrandSigningLastPayment } from "utils/getBrandSigningLastPayment";
import { Payment } from "../../../apiClient/data-contracts";
import { useAuth } from "../../../state/auth";
import CompanyContext from "../../../state/contexts/company";
import { getCurrentBrandSigning } from "../../StatementOfWork/utils";

const useLastPayment = (): Payment | null => {
  const { loginInfo } = useAuth();
  const { company } = useContext(CompanyContext);
  const brandSigning = useMemo(
    () => getCurrentBrandSigning(company),
    [company]
  );
  const [lastPayment, setLastPayment] = useState<Payment>(null);

  useEffect(() => {
    if (brandSigning && loginInfo) {
      getBrandSigningLastPayment(loginInfo.token, brandSigning.id)
      .then(
        (lastPayment) => {
          setLastPayment(lastPayment);
        }
      )
      .catch(_ => {
        console.error("Error getting the last payment");
      });
    }
  }, [brandSigning, loginInfo]);

  return lastPayment;
};

export default useLastPayment;
