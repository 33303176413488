import React, { FC, Fragment, useState } from "react";
import clsx from "clsx";
import { FieldArray, Field as FormikField, FieldProps, useField } from "formik";
import ImageUploading, {
  ErrorsType,
  ImageListType,
} from "react-images-uploading";
import UploadIcon from "../../../../../../../assets/images/upload.svg";
import Field, {
  InputProps,
} from "../../../../../../../Components/v2/Field/Field";
import UploadPreview from "../../../../../../../Components/v2/UploadPreview/UploadPreview";
import "./ProductImages.scss";

type ProductImagesProps = InputProps;

export const count = 10;
const size = 200; // KB

const Errors: FC<{ errors: ErrorsType; name: string; length: number }> = ({
  length,
  errors,
  name,
}) => {
  return (
    <>
      {length < count && (
        <FormikField name={name}>
          {(props: FieldProps) => (
            <>
              <div className="custom-field__validation-message center custom-field__validation-message--info">
                {length} of {count} images added
              </div>
              {props.form.submitCount !== 0 && length !== count && (
                <div className="form__control--error bold center">
                  Upload {count} product images.
                </div>
              )}
            </>
          )}
        </FormikField>
      )}
      {errors && (
        <div className="form__control--error bold center">
          {errors.maxNumber && (
            <span>Number of selected images exceed limit of {count}</span>
          )}
          {errors.acceptType && (
            <span>Your selected image format is not allowed</span>
          )}
          {errors.maxFileSize && (
            <span>
              Looks like some of your images are too large. Each image can
              {"\u2019"}t exceed {size} KB. You can use{" "}
              <a
                href="https://squoosh.app/"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                this tool
              </a>{" "}
              to quickly compress your files.
            </span>
          )}
          {errors.resolution && (
            <span>
              Looks like some of your images don{"\u2019"}t fit the required
              ratio. Your image must be 1:1 ratio (square). Please edit your
              images to fit these requirements to avoid cropping and distortion.
            </span>
          )}
        </div>
      )}
    </>
  );
};

const ProductImages: FC<ProductImagesProps> = ({ name, ...fieldProps }) => {
  const [field] = useField<ImageListType>(name);
  const [value, setValue] = useState<ImageListType>(
    field.value?.map(({ image }) => image) || []
  );

  return (
    <div className="product-images">
      <Field name="name" {...fieldProps}>
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <ImageUploading
              value={value}
              onChange={(imageList, addUpdateIndex) => {
                if (addUpdateIndex) {
                  addUpdateIndex.map((index) =>
                    arrayHelpers.push({
                      image: imageList[index],
                      title: "",
                      description: "",
                      isUploaded: false,
                    })
                  );
                  setValue((values) => [...values, ...imageList]);
                }
              }}
              maxNumber={count}
              acceptType={["png", "jpg", "jpeg"]}
              resolutionType="ratio"
              resolutionHeight={800}
              resolutionWidth={800}
              maxFileSize={size * 1024}
            >
              {({
                imageList,
                onImageUpload,
                isDragging,
                dragProps,
                errors,
              }) => (
                <>
                  <div className="product-images__list">
                    {imageList.map((image, index) => (
                      <Fragment key={index}>
                        <div className="product-images__preview">
                          <UploadPreview
                            value={image.dataURL}
                            onRemove={() => {
                              arrayHelpers.remove(index);
                              setValue((values) => {
                                values.splice(index, 1);
                                return values;
                              });
                            }}
                          />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <Field
                            label="Title:"
                            secondary
                            type="text"
                            name={`${name}[${index}].title`}
                          />
                          <Field
                            label="Description:"
                            secondary
                            maxLength={130}
                            type="textarea"
                            name={`${name}[${index}].description`}
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                  {imageList.length === 0 && (
                    <div
                      className={clsx(
                        "product-images__upload-area",
                        isDragging && "product-images__upload-area--active"
                      )}
                      {...dragProps}
                    >
                      <img
                        src={UploadIcon}
                        alt="Put images here"
                        className="product-images__upload-area__icon"
                      />
                      <button
                        type="button"
                        className={clsx(
                          "btn btn--primary",
                          isDragging && "product-images__button--active"
                        )}
                        onClick={onImageUpload}
                      >
                        Upload first image
                      </button>
                      <Errors
                        errors={errors}
                        name={name}
                        length={imageList.length}
                      />
                    </div>
                  )}
                  {imageList?.length > 0 && imageList.length < count && (
                    <div className="product-images__upload-more-button">
                      <button
                        type="button"
                        className="btn btn--primary"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Upload next image
                      </button>
                      <Errors
                        errors={errors}
                        name={name}
                        length={imageList.length}
                      />
                    </div>
                  )}
                  {imageList?.length >= count && (
                    <Errors
                      errors={errors}
                      name={name}
                      length={imageList.length}
                    />
                  )}
                </>
              )}
            </ImageUploading>
          )}
        />
      </Field>
    </div>
  );
};

export default ProductImages;
