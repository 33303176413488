import React, { FC } from "react";
import { Field, FieldAttributes } from "formik";
import clsx from "clsx";

interface Props {
  customClassName?: string[];
}

type InputProps = FieldAttributes<unknown> & Props;

const Input: FC<InputProps> = (props): JSX.Element => {
  const { customClassName, name } = props;
  return (
    <Field
      className={clsx("form-field", customClassName)}
      id={name}
      {...props}
    />
  );
};

export default Input;
