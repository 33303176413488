/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Payment, PaymentPage } from "./data-contracts";
import { BodyType, HttpClient, RequestParams } from "./http-client";

export class Payments extends HttpClient {
  /**
   * @description net
   *
   * @tags payment
   * @name PostBrandSigningPaymentApi
   * @summary Sends payments to Authorize
   * @request POST:/payment/{brand_signing_id}
   * @secure
   */
  postBrandSigningPaymentApi = (
    brand_signing_id: number,
    data: { number: number; expiration_date: string; code: string; billing_zip_code: string },
    params?: RequestParams,
  ) => this.request<Payment, any>(`/payment/${brand_signing_id}`, "POST", params, data, BodyType.FormData, true);
  /**
   * No description
   *
   * @tags payment
   * @name GetBrandSigningPaymentApi
   * @request GET:/payment/{brand_signing_id}
   * @secure
   */
  getBrandSigningPaymentApi = (brand_signing_id: number, params?: RequestParams) =>
    this.request<PaymentPage, any>(`/payment/${brand_signing_id}`, "GET", params, null, BodyType.Json, true);
}
