import React, { FC, Fragment, useContext, useMemo } from "react";
import { getCurrentContact } from "../../../Components/BrandInformation/utils";
import Loader from "../../../Components/Loader/Loader";
import FieldLabel from "../../../Components/v2/FieldLabel/FieldLabel";
import Footer from "../../../Components/v2/UI/Footer/Footer";
import Heading from "../../../Components/v2/UI/Headings/Heading";
import Navbar from "../../../Components/v2/UI/Navbar/Navbar";
import Paragraph from "../../../Components/v2/UI/Paragraphs/Paragraph";
import { useUser } from "../../../hooks/useUser";
import CompanyContext from "../../../state/contexts/company";
import ContactDetails from "./components/ContactDetails";
import "./MyAccount.scss";
import { bpContactEmail } from "utils/constants";

const MyAccount: FC = () => {
  const { company } = useContext(CompanyContext);
  const user = useUser();
  const primaryContact = useMemo(
    () => (company && user ? getCurrentContact(company, user) : null),
    [company, user]
  );
  const secondaryContacts = useMemo(
    () =>
      primaryContact
        ? company.contacts.filter(
            ({ contact }) => contact.id !== primaryContact.id
          )
        : [],
    [company, primaryContact]
  );

  return (
    <>
      <div className="advertising">
        <Navbar />

        <div className="content2">
          <section className="section">
            <Heading type="primary">My Account</Heading>
            <Paragraph type="primary" customClassName={["u-mb-large"]}>
              {`To make changes to your account, please reach out to us at
              ${bpContactEmail}`}
            </Paragraph>
            {company ? (
              <>
                <FieldLabel lowercase>Company Information</FieldLabel>
                <div className="my_account-info">
                  <Paragraph type="primary">
                    <strong className="bold">{company.name}</strong>
                  </Paragraph>
                  <Paragraph type="primary">
                    <span data-testid="address">
                      {company.street_address_1 &&
                        `${company.street_address_1}, `}
                      {company.street_address_2 &&
                        `${company.street_address_2}, `}
                      {company.city && `${company.city} `}
                      {company.state && `${company.state} `}
                      {company.zip_code}
                    </span>
                  </Paragraph>
                  {company.url && (
                    <Paragraph type="primary">
                      <a
                        href={company.url}
                        className="link"
                        rel="noreferrer noopener nofollow"
                      >
                        {company.url}
                      </a>
                    </Paragraph>
                  )}
                </div>

                <FieldLabel lowercase>Your Contact Information</FieldLabel>
                {primaryContact && <ContactDetails contact={primaryContact} />}

                {secondaryContacts.length > 0 &&
                  secondaryContacts.map(({ contact }) =>
                    contact ? (
                      <Fragment key={contact?.id}>
                        <FieldLabel lowercase>
                          Alternative Contact Information
                        </FieldLabel>
                        <ContactDetails contact={contact} />
                      </Fragment>
                    ) : null
                  )}
              </>
            ) : (
              <Loader />
            )}
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MyAccount;
