import React from "react";
import { Sweep } from "../../apiClient/data-contracts";
import { SweepstakeStatus } from "../../DataTypes";
import { SweepLink, sweepPresenter } from "../SweepLink/SweepLink";
import useSweeps from "../../hooks/useSweeps";

import "./UpcomingSweeps.scss";

interface UpcomingSweepsProps {
  redirectionPath: string;
  status?: SweepstakeStatus;
}

interface SweepsListProps {
  sweeps: Sweep[];
  status?: SweepstakeStatus;
  path: string;
}

interface SweepListItemProps {
  sweep: Sweep;
  path: string;
  collapsed?: boolean;
}

const isSweepCompletedOrArchived = (status: SweepstakeStatus) =>
  status === SweepstakeStatus.Completed || status === SweepstakeStatus.Archived;

const SweepsList = ({ sweeps, status, path }: SweepsListProps): JSX.Element => {
  const filteredSweeps = sweeps.filter(
    (sweep) =>
      !isSweepCompletedOrArchived(sweep.status) &&
      (!status || sweep.status === status)
  );

  return filteredSweeps.length ? (
    <ul className="upcoming-sweeps__items">
      {filteredSweeps.map((sweep, index) => (
        <SweepListItem
          key={sweep.id}
          sweep={sweep}
          path={path}
          collapsed={index >= 2}
        />
      ))}
    </ul>
  ) : (
    <p className="upcoming-sweeps__text">No sweeps available right now.</p>
  );
};

const SweepListItem = ({
  sweep,
  path,
  collapsed,
}: SweepListItemProps): JSX.Element => (
  <li className="upcoming-sweeps__item">
    <SweepLink
      sweep={sweepPresenter(sweep)}
      path={path}
      collapsed={collapsed}
    />
  </li>
);

const UpcomingSweeps = ({
  redirectionPath,
  status,
}: UpcomingSweepsProps): JSX.Element => {
  const waitingMessage = "Getting sweeps...";
  const errorMessage = "There was an error loading sweeps";

  const { data: sweeps = [], isLoading, error } = useSweeps();

  if (isLoading) {
    return <p className="upcoming-sweeps__loading-msg">{waitingMessage}</p>;
  }

  if (error) {
    return <p className="upcoming-sweeps__loading-msg">{errorMessage}</p>;
  }

  return (
    <div className="upcoming-sweeps__box">
      <SweepsList status={status} sweeps={sweeps} path={redirectionPath} />
    </div>
  );
};

export default UpcomingSweeps;
