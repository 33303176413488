import { createContext } from "react";
import { User } from "../../apiClient/data-contracts";

type UserContextValue = {
  user: User;
  setUser: (user: User) => void;
};

const UserContext = createContext<UserContextValue>({
  user: null,
  setUser: null,
});

export default UserContext;
