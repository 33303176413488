import paths from "../../Paths";
import laptop from "../../assets/images/laptop.jpeg";
import laptopTyping from "../../assets/images/laptop-typing.jpeg";
import tablet from "../../assets/images/tablet-in-hand.jpeg";
import laptopCard from "../../assets/images/laptop-card.png";

export const onboardingImages: Record<string, string> = {
  [paths.onboarding.url]: laptop,
  [paths.explainer.url]: laptop,
  [paths.companyInformation.url]: laptopTyping,
  [paths.checklist.url]: tablet,
  [paths.checkout.url]: laptopCard,
};
