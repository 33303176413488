import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import EspForm from "./Form";

import { EspPayload } from "../../DataTypes";
import SlotContext from "../../state/contexts/slot";
import SweepContext from "../../state/contexts/sweep";
import { useAuth } from "../../state/auth";
import { CustomError, handleError } from "../../utils/errorHandler";
import { useLoading } from "../../state/loader";

import { Slots } from "../../apiClient/Slot";

import nextIncompleteSection from "../../utils/nextSectionRedirection";
import {
  isESPComplete,
  sponsorChecklistSectionOrder,
} from "../../pages/SponsorChecklist/utils";

import paths from "../../Paths";
import { bpContactEmail } from "../../utils/constants";
import { errorToast } from "../../utils/toast";

const Esp: FC = () => {
  const navigate = useNavigate();
  const { slot, setSlot } = useContext(SlotContext);
  const { sweep } = useContext(SweepContext);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [err, setErr] = useState("Error");
  const [supportedEsp, setSupportedESP] = useState(false);

  if (!slot) {
    navigate(paths.sponsorChecklist.url, { replace: true });
    return <></>;
  }

  const onSubmit = async (values: EspPayload): Promise<void> => {
    const slotClient = new Slots();
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure the information is correct?")) {
        setLoading(true);
        const response = await slotClient.patchEspApi(
          slot.id,
          { ...values, esp_integration_enable: true },
          {
            headers: {
              Authorization: loginInfo.token,
            },
          }
        );
        setSubmitted(true);
        setSlot(response.data);
        setValid(true);

        if (response.data.supported_esp) {
          setSupportedESP(true);
        }
      }
    } catch (error) {
      const customError = error as CustomError;
      if (customError.status === 417) {
        setErr(handleError(customError).message);
        setSubmitted(true);
        setValid(false);
      } else {
        errorToast(handleError(error as CustomError).message);
      }
    } finally {
      setLoading(false);
    }
  };

  const nextSection = () => {
    nextIncompleteSection(navigate, sponsorChecklistSectionOrder(slot, sweep));
  };

  return (
    <div>
      <h1 className="heading-primary heading__font">Email Integration</h1>
      <article>
        <p>
          Use our easy API integration to set up a real-time sync of your
          sweepstakes entries to your email provider. Immediate email sync and
          welcome flows are proven to increase conversion and open rates. Usage
          of this feature was included in your sweepstakes payment.
        </p>
        <p>
          Confused? Please reach out to{" "}
          <a href={`mailto:${bpContactEmail}`} className="link">
            {bpContactEmail}
          </a>{" "}
          for help
        </p>
      </article>
      <EspForm
        onSubmit={onSubmit}
        dataSource={slot}
        isComplete={isESPComplete}
        sweepStatus={sweep.status}
        valid={valid}
        err={err}
        supportedEsp={supportedEsp}
        submitted={submitted}
      />
      <div>
        {submitted && valid ? (
          <button className="btn btn--submit" onClick={nextSection}>
            Next
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Esp;
