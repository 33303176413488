import React from "react";

import "./Footer.scss";
import logo from "../../assets/images/brand-portal-logo.png";
import { bpContactEmail } from "../../utils/constants";

const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <img className="footer__image" src={logo} alt="" />
      <p>
        {`At FindKeep.Love, we are on a mission to transform online
      shopping. We're building a portal where consumers can discover "the best
      new everything" -- from trendy apparel and accessories, to home decor, health/fitness, and everything in between.`}
      </p>

      <p>
        {`Questions? Contact our account manager directly for the quickest response, or you can reach our general mailbox at `}{" "}
        <a href={`mailto:${bpContactEmail}`} className="link">
          {bpContactEmail}
        </a>
      </p>

      <p>COPYRIGHT {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
