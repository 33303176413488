import { useEffect, useState } from "react";

const RESIZE_THROTTLE_MS = 100;

interface windowDimensions {
  width: number;
  height: number;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = (): windowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const handleResize = () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        setWindowDimensions(getWindowDimensions());
      }, RESIZE_THROTTLE_MS);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
