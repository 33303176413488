import * as yup from "yup";
import { password } from "../../utils/validations/messages";

const validationSchema = yup.object().shape({
  password: yup.string().required(password.isRequired),
  confirmPassword: yup
    .string()
    .required(password.confirm)
    .oneOf([yup.ref("password"), null], password.mustMatch),
});

export default validationSchema;
