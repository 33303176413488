import React, { FC, useMemo } from "react";
import { Navigate, Routes, Route } from "react-router";
import paths from "Paths";
import { useCplV2 } from "hooks/useCpl";
import Steps from "Components/v2/UI/Steps/Steps";
import Navbar from "Components/v2/UI/Navbar/Navbar";
import Paragraph from "Components/v2/UI/Paragraphs/Paragraph";
import Footer from "Components/v2/UI/Footer/Footer";
import Heading from "Components/v2/UI/Headings/Heading";
import OfferPromoCodeV2 from "./routes/OfferPromoCode/OfferPromoCode";
import CplEsp from "./routes/CplEsp/CplEsp";
import AssetCollection from "./routes/AssetCollection/AssetCollection";
import routes from "./routes";
import { getStepsCompletion } from "./utils";

import "./CostPerLead.scss";

const CostPerLead: FC = () => {
  const { data: cpl, isLoading } = useCplV2();

  const titles = {
    [routes.offer]: "Your Promo Offer",
    [routes.assetCollection]: "Brand Assets",
    [routes.espIntegration]: "Integration",
  };

  const completeSteps = useMemo(() => getStepsCompletion(cpl), [cpl]);

  if (!isLoading && !cpl) {
    return (
      <>
        <Navigate to={paths.home.url} replace />
      </>
    );
  }

  return (
    <div className="advertising">
      <Navbar />

      <div className="content2">
        <section className="section">
          <Heading type="primary">Advertising</Heading>
          <Paragraph type="primary" customClassName={["u-mb-extra-large"]}>
            Your brand's checklist to start advertising with FindKeep.Love
          </Paragraph>
          <Steps
            items={Object.entries(routes).map(([key, path]) => ({
              path,
              name: titles[path],
              complete: completeSteps[key as keyof typeof routes],
            }))}
          />

          <Routes>
            <Route
              path={routes.offer}
              element={<OfferPromoCodeV2 next={`../${routes.assetCollection}`} />}
            />
            <Route
              path={routes.assetCollection}
              element={<AssetCollection next={`../${routes.espIntegration}`} />}
            />
            <Route path={routes.espIntegration} element={<CplEsp />} />

            <Route path="/" element={<Navigate to={routes.offer} replace />} />
          </Routes>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default CostPerLead;
