import { useCallback } from "react";
import { Cpl } from "../../../../../../apiClient/Cpl";
import { HttpResponse } from "../../../../../../apiClient/http-client";
import { CPL, Field } from "../../../../../../DataTypes";
import { useAuth } from "../../../../../../state/auth";

const useDeleteAsset = (
  cpl: CPL
): ((field: Field, assetId: number) => Promise<HttpResponse<CPL, unknown>>) => {
  const { loginInfo } = useAuth();

  return useCallback(
    async (field: Field, assetId: number) =>
      new Cpl().deleteAssetsUploadApi(
        cpl?.id,
        {
          field,
          asset_id: assetId,
        },
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      ),
    [cpl?.id, loginInfo?.token]
  );
};

export default useDeleteAsset;
