import React, { useContext } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { Contact } from "../../apiClient/data-contracts";

import { isBrandInformationComplete } from "../../pages/GetStartedCheckList/GetStartedChecklist";
import SpinnerButton from "../SpinnerButton/SpinnerButton";
import ErrorFormDisplay from "../ErrorFormDisplay/ErrorFormDisplay";

import validationSchema from "./validations";
import UserContext from "../../state/contexts/user";
import {
  BrandInformationPayload,
  Company,
  CompanyDataFetch,
  ContactPayload,
  PhoneTypes,
  States,
} from "../../DataTypes";

import { getAlternateEmailAddress, getPhoneNumber } from "./utils";
import "./BrandInformation.scss";

interface Props {
  company: Company;
  contact: Contact;
  secondaryContact?: Contact;
  onSubmit: (values: BrandInformationPayload) => void;
}

const getCompanyInitialValues = (company: Company): CompanyDataFetch => ({
  parent_company: company.parent_company || "",
  name: company.name || "",
  street_address_1: company.street_address_1 || "",
  street_address_2: company.street_address_2 || "",
  city: company.city || "",
  state: company.state || "",
  zip_code: company.zip_code || "",
  phone: company.phone || "",
  phone_extension: company.phone_extension || "",
  url: company.url || "https://",
  // TODO: this field is hardcoded
  esp: "Moosend",
});

const getPrimaryContactInitialValues = (contact: Contact): ContactPayload => ({
  first_name: contact.first_name || "",
  last_name: contact.last_name || "",
  position_title: contact.position_title || "",
  email_address_1: contact.email_address || "",
  email_address_2: getAlternateEmailAddress(contact) || "",
  phone_1_number: getPhoneNumber(contact, PhoneTypes.WORK)?.number || "",
  phone_1_extension: getPhoneNumber(contact, PhoneTypes.WORK)?.extension || "",
  phone_2_number: getPhoneNumber(contact, PhoneTypes.OTHER)?.number || "",
});

const getSecondaryContactInitialValues = (secondaryContact: Contact) => ({
  secondary_first_name: secondaryContact?.first_name || "",
  secondary_last_name: secondaryContact?.last_name || "",
  secondary_position_title: secondaryContact?.position_title || "",
  secondary_email_address_1: secondaryContact?.email_address || "",
  secondary_email_address_2: getAlternateEmailAddress(secondaryContact) || "",
  secondary_phone_1_number:
    getPhoneNumber(secondaryContact, PhoneTypes.WORK)?.number || "",
});

const BrandForm = ({
  company,
  contact,
  secondaryContact,
  onSubmit,
}: Props): JSX.Element => {
  const { user } = useContext(UserContext);

  const initialValues = {
    ...getCompanyInitialValues(company),
    ...getPrimaryContactInitialValues(contact),
    ...getSecondaryContactInitialValues(secondaryContact),
  };

  const isDisabled = isBrandInformationComplete(company, user);

  const MapStatesToOptions = (): JSX.Element => {
    return (
      <>
        {Object.entries(States).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </>
    );
  };

  return (
    <section className="get-started">
      <h1 className="heading-primary">
        Welcome to the FindKeep.Love Brand Portal!
      </h1>
      <p>Tell us a little about yourself.</p>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, submitCount, isValid, handleBlur }) => (
          <Form className="form brand-information">
            <article className="get-started__company-information">
              <h3 className="heading-tertiary">1. Company Information*</h3>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Parent Company Name{" "}
                    <Field
                      name="parent_company"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="parent_company"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Brand Name{" "}
                    <Field
                      name="name"
                      type="text"
                      className="form__input"
                      disabled
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="name"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Street Address 1{" "}
                    <Field
                      name="street_address_1"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="street_address_1"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Street Address 2{" "}
                    <Field
                      name="street_address_2"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="street_address_2"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form_group col col--one-third">
                  <label className="form__control">
                    City{" "}
                    <Field
                      name="city"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="city"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form_group col col--one-third">
                  <label className="form__control">
                    State{" "}
                    <Field
                      as="select"
                      name="state"
                      type="text"
                      className="form__input form__input--select"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    >
                      <option value="" defaultChecked>
                        Select
                      </option>
                      <MapStatesToOptions />
                    </Field>
                  </label>

                  <ErrorMessage
                    name="state"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form_group col col--one-third">
                  <label className="form__control">
                    Zip Code{" "}
                    <Field
                      name="zip_code"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="zip_code"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form_group col col--one-half">
                  <label className="form__control">
                    Phone{" "}
                    <Field
                      name="phone"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="phone"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form_group col col--one-half">
                  <label className="form__control">
                    Phone Extension{" "}
                    <Field
                      name="phone_extension"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="phone_extension"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Website{" "}
                    <Field
                      name="url"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="url"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>
            </article>

            <article className="get-started__primary-contact-information">
              <h3 className="heading-tertiary">
                2. Primary Contact Information
              </h3>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    First Name{" "}
                    <Field
                      name="first_name"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="first_name"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Last Name{" "}
                    <Field
                      name="last_name"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="last_name"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Title{" "}
                    <Field
                      name="position_title"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="position_title"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Email{" "}
                    <Field
                      name="email_address_1"
                      type="text"
                      className="form__input"
                      disabled
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="email_address_1"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Alternative Email{" "}
                    <Field
                      name="email_address_2"
                      type="email"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="email_address_2"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Work Phone{" "}
                    <Field
                      name="phone_1_number"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="phone_1_number"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Phone Extension{" "}
                    <Field
                      name="phone_1_extension"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="phone_1_extension"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Mobile Phone{" "}
                    <Field
                      name="phone_2_number"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="phone_2_number"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <p className="form__group col col--one-half">
                  FindKeep.Love may communicate via SMS for time-sensitive
                  information if contact is unavailable by email
                </p>
              </div>
            </article>

            <article className="get-started__secondary-contact-information">
              <h3 className="heading-tertiary">
                3. Secondary Contact Information
              </h3>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    First Name{" "}
                    <Field
                      name="secondary_first_name"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="secondary_first_name"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Last Name{" "}
                    <Field
                      name="secondary_last_name"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="secondary_last_name"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Title{" "}
                    <Field
                      name="secondary_position_title"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="secondary_position_title"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Email{" "}
                    <Field
                      name="secondary_email_address_1"
                      type="text"
                      className="form__input"
                      disabled={!!secondaryContact?.email_address}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="secondary_email_address_1"
                    component="span"
                    className="form-question__error"
                  />
                </div>

                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Alternative Email{" "}
                    <Field
                      name="secondary_email_address_2"
                      type="email"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="secondary_email_address_2"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form__group col col--one-half">
                  <label className="form__control">
                    Phone{" "}
                    <Field
                      name="secondary_phone_1_number"
                      type="text"
                      className="form__input"
                      disabled={isDisabled}
                      onFocus={handleBlur}
                    />
                  </label>

                  <ErrorMessage
                    name="secondary_phone_1_number"
                    component="span"
                    className="form-question__error"
                  />
                </div>
              </div>
            </article>
            {!isDisabled && <SpinnerButton />}
            <ErrorFormDisplay
              errors={errors}
              isSubmitting={isSubmitting}
              submitCount={submitCount}
              isValid={isValid}
            />
            <p className="paragraph-tertiary">
              * Information will appear on terms/conditions
            </p>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default BrandForm;
