import React, { FC, ReactNode, useState } from "react";
import { Field as FormikField, FieldProps } from "formik";
import ImageUploading, {
  ErrorsType,
  ImageType,
  ImageUploadingPropsType,
} from "react-images-uploading";

import Field, { InputProps } from "../Field/Field";
import UploadPreview from "../UploadPreview/UploadPreview";

export type ImageInputProps = Omit<InputProps, "type" | "maxLength"> & {
  acceptType?: ImageUploadingPropsType["acceptType"];
  maxFileSize?: ImageUploadingPropsType["maxFileSize"];
  resolutionWidth?: ImageUploadingPropsType["resolutionWidth"];
  resolutionHeight?: ImageUploadingPropsType["resolutionHeight"];
  resolutionType?: ImageUploadingPropsType["resolutionType"];
} & {
  imageValidationMessages?: Partial<
    Record<"acceptType" | "maxFileSize" | "resolution", ReactNode>
  >;
};

const defaultValidationMessages = {
  acceptType: "Uploaded image has incorrect format.",
  maxFileSize: "Uploaded image is too large.",
  resolution: "Uploaded image has incorrect resolution.",
};

const ImageInput: FC<ImageInputProps> = ({
  name,
  acceptType = ["png", "jpg", "jpeg"],
  maxFileSize,
  resolutionWidth,
  resolutionHeight,
  resolutionType,
  imageValidationMessages = {},
  ...fieldProps
}) => {
  const [error, setError] = useState<ErrorsType>({});
  const imageUploadingProps = {
    acceptType,
    maxFileSize,
    resolutionWidth,
    resolutionHeight,
    resolutionType,
  };
  const errorMessages = {
    ...defaultValidationMessages,
    ...imageValidationMessages,
  };

  return (
    <FormikField name={name}>
      {(props: FieldProps<ImageType>) => (
        <Field name={name} {...fieldProps} showErrorsImmediately>
          <ImageUploading
            value={[props.field.value]}
            onChange={(imageList, addUpdateIndex) => {
              setError({});
              if (addUpdateIndex) {
                props.form.setFieldValue(props.field.name, imageList[0]);
              }
            }}
            inputProps={{ name, id: name }}
            onError={(error) => {
              setError(error);
            }}
            {...imageUploadingProps}
          >
            {({ onImageUpload, dragProps }) =>
              props.field.value?.dataURL ? (
                <UploadPreview
                  value={props.field.value.dataURL}
                  onRemove={() => {
                    if (!!props.field.value) {
                      props.form.setFieldValue(props.field.name, undefined);
                    }
                  }}
                />
              ) : (
                <button
                  type="button"
                  className="btn btn--primary"
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Upload
                </button>
              )
            }
          </ImageUploading>

          {error?.acceptType && (
            <div className="custom-field__validation-message">
              {errorMessages.acceptType}
            </div>
          )}
          {error?.maxFileSize && (
            <div className="custom-field__validation-message">
              {errorMessages.maxFileSize}
            </div>
          )}
          {error?.resolution && (
            <div className="custom-field__validation-message">
              {errorMessages.resolution}
            </div>
          )}
        </Field>
      )}
    </FormikField>
  );
};

export default ImageInput;
