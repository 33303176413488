import React, { FC } from "react";
import Content from "Layout/Content/Content";
import NavBar from "Layout/NavBar/NavBar";
import Page from "Layout/Page/Page";
import Sidebar from "Layout/Sidebar/Sidebar";
import Home from "Components/Home/Home";
import Main from "Layout/Main/Main";

const HomePage: FC = () => (
  <Page>
    <Sidebar />
    <Content>
      <NavBar />
      <Main>
        <Home />
      </Main>
    </Content>
  </Page>
);

export default HomePage;
