import { useQuery } from "react-query";
import queryKeys from "../reactQuery/queryKeys";

import { Sweep } from "../apiClient/data-contracts";
import { Sweeps } from "../apiClient/Sweep";
import { useAuth } from "../state/auth";

interface IUseSweeps {
  data: Sweep[];
  error: unknown;
  isLoading: boolean;
}

function useSweeps(): IUseSweeps {
  const { loginInfo } = useAuth();

  const fetchSweeps = async () => {
    const { data } = await new Sweeps().getSweepApi({
      headers: { Authorization: loginInfo.token },
    });
    return data;
  };

  const { data, error, isLoading } = useQuery(queryKeys.SWEEPS, fetchSweeps);
  return { data, error, isLoading };
}

export default useSweeps;
