interface Path {
  url: string;
}

interface Paths {
  root: Path;
  login: Path;
  resetPassword: Path;
  forgotPassword: Path;
  featureFlags: Path;
  home: Path;
  sponsorChecklist: Path;
  sweepstakeDeliverables: Path;
  esp: Path;
  sms: Path;
  sweepstakePromotion: Path;
  getStartedCheckList: Path;
  brandInformation: Path;
  letsGetStarted: Path;
  statementOfWork: Path;
  reports: Path;
  currentSweepstakes: Path;
  pastSweepstakes: Path;
  cplAnalytics: Path;
  payYourBalance: Path;
  prizingList: Path;
  costPerLead: Path;
  costPerLeadV2: Path;
  cplOfferPromoCode: Path;
  cplAssetsCollection: Path;
  cplEspIntegration: Path;
  cplEspIntegrationV2: Path;
  onboarding: Path;
  explainer: Path;
  companyInformation: Path;
  checklist: Path;
  checkout: Path;
  myAccount: Path;
}

const paths: Paths = {
  root: {
    url: "/",
  },
  login: {
    url: "/login",
  },
  forgotPassword: {
    url: "/user/forgot-password",
  },
  resetPassword: {
    url: "/user/reset-password/:token",
  },
  featureFlags: {
    url: "/feature-flags",
  },
  home: {
    url: "/home",
  },
  sponsorChecklist: {
    url: "/sponsor-checklist",
  },
  sweepstakeDeliverables: {
    url: "/sponsor-checklist/sweepstakes-deliverables",
  },
  esp: {
    url: "/sponsor-checklist/esp",
  },
  sms: {
    url: "/sponsor-checklist/sms",
  },
  sweepstakePromotion: {
    url: "/sponsor-checklist/sweepstake-promotion",
  },
  prizingList: {
    url: "/sponsor-checklist/prizing-list",
  },
  getStartedCheckList: {
    url: "/get-started-checklist",
  },
  // TODO: remove deprecated route along with related components
  brandInformation: {
    url: "/get-started-checklist/brand-information",
  },
  letsGetStarted: {
    url: "/get-started-checklist/lets-get-started",
  },
  statementOfWork: {
    url: "/get-started-checklist/statement-of-work",
  },
  payYourBalance: {
    url: "/get-started-checklist/pay-your-balance",
  },
  reports: {
    url: "/reports",
  },
  currentSweepstakes: {
    url: "/reports/current-sweepstakes",
  },
  pastSweepstakes: { url: "/reports/past-sweepstakes" },
  cplAnalytics: { url: "/reports/cpl-analytics" },
  costPerLead: { url: "/cpl" },
  costPerLeadV2: { url: "/v2/cpl" },
  // TODO: remove deprecated route along with related components
  cplOfferPromoCode: { url: "/cpl/offer-promo-code" },
  cplAssetsCollection: {
    url: "/cpl/assets-collection",
  },
  cplEspIntegration: { url: "/cpl/esp-integration" },
  cplEspIntegrationV2: { url: "/v2/cpl/esp-integration" },
  onboarding: { url: "/onboarding" },
  explainer: { url: "/onboarding/get-started" },
  companyInformation: { url: "/onboarding/company-information" },
  checklist: { url: "/onboarding/checklist" },
  checkout: { url: "/onboarding/checkout" },
  myAccount: { url: "/v2/my-account" },
};

export default paths;
