import React, { FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import SpinnerButton from "../../Components/SpinnerButton/SpinnerButton";
import validationSchema from "./validationSchema";

import "./Form.scss";

interface FormProps {
  error?: string;
  onSubmit: (values: { email: string }) => void;
}

const ForgotPasswordForm: FC<FormProps> = ({ error, onSubmit }) => {
  const initialValues = {
    email: "",
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form className="forgot-password-form__form">
        <label className="forgot-password-form__form-group">
          <span className="forgot-password-form__form-label">Email:</span>
          <Field name="email" type="email" aria-label="email" />
          <ErrorMessage
            name="email"
            component="div"
            className="form__control--error"
          />
        </label>

        {error ? <div className="form__control--error">{error}</div> : null}
        <SpinnerButton label="Send reset password email" />
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
