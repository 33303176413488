import React, { ReactNode, useState, useEffect } from "react";
import { Company } from "../../DataTypes";
import { useCompany } from "../../hooks/useCompany";
import CompanyContext from "../contexts/company";

interface CompanyProviderProps {
  children?: ReactNode;
}

const CompanyProvider = ({ children }: CompanyProviderProps): JSX.Element => {
  const [company, setCompany] = useState<Company>(null);
  const companyFromApi = useCompany(1);

  useEffect(() => {
    companyFromApi && setCompany(companyFromApi);
  }, [companyFromApi]);

  return (
    <CompanyContext.Provider value={{ company, setCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
