import React from "react";
import { Report } from "../../DataTypes";

interface FileDownloaderProps {
  label?: string;
  report: Report;
  downloadHandler: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    report: Report
  ) => void;
}

const FileDownloader = ({
  label = "Download opt-ins",
  report,
  downloadHandler,
}: FileDownloaderProps): JSX.Element => (
  <button
    className="black-link"
    onClick={(event) => downloadHandler(event, report)}
  >
    {label}
  </button>
);

export default FileDownloader;
