/* eslint-disable sonarjs/cognitive-complexity */

import assertNever from "assert-never";
import paths from "../../Paths";
import { Company } from "../../DataTypes";
import {
  isBrandInformationComplete,
  isPayBalanceComplete,
} from "../GetStartedCheckList/GetStartedChecklist";
import { isChecklistComplete } from "../../Components/Onboarding/Checklist/ChecklistUtils";
import { getCurrentBrandSigning } from "../../Components/StatementOfWork/utils";
import { isDefined } from "../../utils";
import redirectionTrigger from "../../utils/redirectionTrigger";
import { useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import CompanyContext from "../../state/contexts/company";
import { User } from "../../apiClient/data-contracts";
import { useUser } from "../../hooks/useUser";

const ONBOARDING_STATUS = "ONBOARDING_STATUS";

export enum OnboardingStatus {
  STARTED = "STARTED",
  COMPANY_INFORMATION = "COMPANY_INFORMATION",
  CHECKLIST = "CHECKLIST",
  CHECKOUT = "CHECKOUT",
  FINISHED = "FINISHED",
}

export enum OnboardingFlowType {
  NONE,
  PENDING,
  NEW_CLIENT,
  FULL_FLOW, // without the explainer screen, that's for new clients only
  PAYMENT_AND_SIGNING,
  JUST_COMPANY,
  JUST_SIGNING,
  JUST_PAYMENT,
}

const statusUrls: Record<OnboardingStatus, string> = {
  [OnboardingStatus.STARTED]: paths.explainer.url,
  [OnboardingStatus.COMPANY_INFORMATION]: paths.companyInformation.url,
  [OnboardingStatus.CHECKLIST]: paths.checklist.url,
  [OnboardingStatus.CHECKOUT]: paths.checkout.url,
  [OnboardingStatus.FINISHED]: paths.home.url,
};

export function getOnboardingStatus(): OnboardingStatus {
  return (
    (localStorage.getItem(ONBOARDING_STATUS) as OnboardingStatus) ||
    OnboardingStatus.STARTED
  );
}

export function setOnboardingStatus(status: OnboardingStatus): void {
  localStorage.setItem(ONBOARDING_STATUS, status);
}

export function getFlowType(
  company: Company,
  user: User,
  isLastRecurringPaymentFailed = false
): OnboardingFlowType {
  if (!company || !user) {
    return OnboardingFlowType.PENDING;
  }

  const brandSigning = getCurrentBrandSigning(company);
  const sowSigned = brandSigning && isDefined(brandSigning.date_agreement);
  const hasPaid = isPayBalanceComplete(company);
  const hasSigned = isChecklistComplete(company) && sowSigned;
  const hasCompletedBrandInfo = isBrandInformationComplete(company, user);
  const status = getOnboardingStatus();
  const isFirstOnboarding = status !== OnboardingStatus.FINISHED;

  const isNewClient =
    !hasCompletedBrandInfo && !hasPaid && !hasSigned && isFirstOnboarding;
  if (isNewClient) {
    return OnboardingFlowType.NEW_CLIENT;
  }

  if (!hasCompletedBrandInfo && (!hasPaid || !hasSigned)) {
    return OnboardingFlowType.FULL_FLOW;
  }
  if (!hasCompletedBrandInfo && hasPaid && hasSigned) {
    return OnboardingFlowType.JUST_COMPANY;
  }
  if (!hasPaid && !hasSigned) {
    return OnboardingFlowType.PAYMENT_AND_SIGNING;
  }
  if (!hasPaid) {
    return OnboardingFlowType.JUST_PAYMENT;
  }
  if (!hasSigned) {
    return OnboardingFlowType.JUST_SIGNING;
  }
  if (isLastRecurringPaymentFailed) {
    setOnboardingStatus(OnboardingStatus.CHECKOUT);
    return OnboardingFlowType.JUST_PAYMENT;
  }

  /*
    We need to mark old clients the first time they log in, in order to avoid
    navigating them through the whole onboarding process more than once.
  */
  setOnboardingStatus(OnboardingStatus.FINISHED);
  return OnboardingFlowType.NONE;
}

export function getFlowPath(
  company: Company,
  user: User,
  fromPath = paths.home.url
): string {
  const flowType = getFlowType(company, user);

  switch (flowType) {
    case OnboardingFlowType.PENDING:
    case OnboardingFlowType.NONE:
      return fromPath;
    case OnboardingFlowType.NEW_CLIENT:
      const status = getOnboardingStatus();
      return statusUrls[status];
    case OnboardingFlowType.PAYMENT_AND_SIGNING:
    case OnboardingFlowType.JUST_SIGNING:
      return paths.checklist.url;
    case OnboardingFlowType.JUST_PAYMENT:
      return paths.checkout.url;
    case OnboardingFlowType.FULL_FLOW:
    case OnboardingFlowType.JUST_COMPANY:
      return paths.companyInformation.url;
    default:
      assertNever(flowType);
  }
}

export function useOnboardingFlow(
  currentStep: OnboardingStatus,
  isLastRecurringPaymentFailed = false
): void {
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext);
  const user = useUser();

  useEffect(() => {
    const flowType = getFlowType(company, user, isLastRecurringPaymentFailed);
    if (flowType === OnboardingFlowType.NONE) {
      return redirectionTrigger(navigate, paths.home.url);
    }
    const status = getOnboardingStatus();
    if (status !== OnboardingStatus.FINISHED) {
      setOnboardingStatus(currentStep);
    }
  }, [user, company, navigate, currentStep, isLastRecurringPaymentFailed]);
}
