import React, { useState } from "react";
import { ProcessPaymentState } from "../../DataTypes";
import CheckoutContext from "../contexts/checkout";

const CheckoutProvider: React.FC = ({ children }) => {
  const [paymentState, setPaymentState] = useState<ProcessPaymentState>(ProcessPaymentState.NotProcessing);

  return (
    <CheckoutContext.Provider value={{ paymentState, setPaymentState }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
