import {
  Slot,
  Sweep,
  PrizingOptions,
  SweepstakeStatus,
} from "../../../DataTypes";

import { sectionOrder } from "../../../utils/nextSectionRedirection";

import paths from "../../../Paths";

export const isSweepstakesDeliverablesComplete = (slot: Slot): boolean =>
  slot &&
  [
    !!slot.company?.image,
    !!slot.company?.brand_description,
    slot.brand_assets?.store_lifestyle_images.length > 1,
    slot.no_sweep_videos || slot.brand_assets?.sweep_videos.length > 0,
  ].every((i) => i);

export const isOnsiteOfferComplete = (slot: Slot): boolean =>
  slot &&
  [
    slot.off_amount > 0 || !!slot.free_gift,
    slot.off_amount_min_purchase_amount > 0 ||
      slot.free_gift_min_purchase_qualifications > 0,
    !!slot.promo_code,
    !!slot.exclusions_notes,
    !!slot.expiration,
    !!slot.offer_type,
    slot.brand_assets.onsite_offer_lifestyle_images.length >= 5,
  ].every((i) => i);

export const isESPComplete = (slot: Slot): boolean =>
  slot &&
  [
    slot.api_setup.is_enabled,
    !["No ESP", "None"].includes(slot.api_setup.name),
    slot.api_setup.keys && slot.api_setup.keys.every((k) => k.value),
  ].every((i) => i);

export const isSMSComplete = (slot: Slot): boolean => {
  if (slot && !slot.sms_wanted_by_brand) {
    return true;
  }
  return (
    slot &&
    [
      slot.sms_setup.is_enabled,
      !["No SMS", "None"].includes(slot.sms_setup.name),
      !!slot.sms_terms_and_conditions,
      !!slot.sms_privacy_policy,
      !!slot.sms_unsubscribe_code,
      !!slot.sms_unsubscribe_number,
      !!slot.sms_max_messages_month,
      slot.sms_setup.keys && slot.sms_setup.keys.every((k) => k.value),
    ].every((i) => i)
  );
};

export const isSweepstakesPromotionComplete = (slot: Slot): boolean =>
  slot &&
  [
    !!slot.email_promotion_date,
    !!slot.social_promotion_date,
    !!slot.is_test_email_sent,
  ].every((i) => i);

export const isSweepstakesPromotionVisible = (
  sweep: Sweep,
  slot: Slot
): boolean =>
  sweep &&
  slot &&
  [
    sweep.status === SweepstakeStatus.Booked ||
      sweep.status === SweepstakeStatus.Live,
    !!sweep.landing_page,
    !!sweep.email_creative,
    !!sweep.dropbox_link,
    !!sweep.terms_and_conditions_link,
    slot.tracking_links?.length > 0,
  ].every((i) => i);

export const isSponsorChecklistComplete = (
  slot: Slot,
  sweep?: Sweep
): boolean => {
  if (isSweepstakesPromotionVisible(sweep, slot)) {
    return [
      isSweepstakesDeliverablesComplete,
      isESPComplete,
      isSMSComplete,
      isSweepstakesPromotionComplete,
      isPrizingListComplete,
    ].every((check) => check(slot));
  }
  return [
    isSweepstakesDeliverablesComplete,
    isESPComplete,
    isSMSComplete,
    isPrizingListComplete,
  ].every((check) => check(slot));
};

export const isPrizingListComplete = (slot: Slot): boolean =>
  [
    !!slot.sweeps_prize_option,
    !!slot.sweeps_prize_value,
    slot.sweeps_prize_option === PrizingOptions.CreditCard ||
      (slot.sweeps_prize_option === PrizingOptions.GiftCard &&
        !!slot.sweeps_prize_description &&
        !!slot.sweeps_prize_code) ||
      (slot.sweeps_prize_option === PrizingOptions.Physical &&
        !!slot.sweeps_prize_description),
  ].every((i) => i);

export const sponsorChecklistSectionOrder = (
  slot: Slot,
  sweep: Sweep
): sectionOrder[] => [
  {
    section: "sweepDeliverables",
    url: paths.sweepstakeDeliverables.url,
    isComplete: isSweepstakesDeliverablesComplete(slot),
    isVisible: true,
  },
  {
    section: "emailIntegration",
    url: paths.esp.url,
    isComplete: isESPComplete(slot),
    isVisible: true,
  },
  {
    section: "prizingList",
    url: paths.prizingList.url,
    isComplete: isPrizingListComplete(slot),
    isVisible: true,
  },
  {
    section: "smsIntegration",
    url: paths.sms.url,
    isComplete: isSMSComplete(slot),
    isVisible: true,
  },
  {
    section: "sweepstakePromotion",
    url: paths.sweepstakePromotion.url,
    isComplete: isSweepstakesPromotionComplete(slot),
    isVisible: isSweepstakesPromotionVisible(sweep, slot),
  },
];
