interface Errors {
  [key: string]: string;
}
interface ServerError {
  errors?: Errors;
  message?: string;
}
export interface CustomError extends Error {
  error?: ServerError;
  status?: number; // the http status code
}

export interface ClientError {
  message: string;
  status: number;
}

export const handleError = (error: CustomError): ClientError => {
  const defaultErrorMessage = "There was an error submitting your form";

  const baseClientError = {
    message: error.message ?? defaultErrorMessage,
    status: error.status,
  };

  if (error.error && Object.entries(error.error).length) {
    const { errors = {}, message = "" } = error.error;

    const parsedErrors = Object.values(errors).reduce(
      (acc, current) => (acc += "\t" + current + "\n\r"),
      ""
    );

    return { ...baseClientError, message: `${message} \n ${parsedErrors}` };
  }

  return baseClientError;
};
