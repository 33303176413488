import { createContext } from "react";
import { Payment } from "apiClient/data-contracts";

type LastRecurringPaymentContextValue = {
  lastRecurringPayment: Payment;
  setLastRecurringPayment: (lastRecurringPayment: Payment) => void;
};

const LastRecurringPaymentContext =
  createContext<LastRecurringPaymentContextValue>({
    lastRecurringPayment: null,
    setLastRecurringPayment: null,
  });

export default LastRecurringPaymentContext;
