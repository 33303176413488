import React, { FC, useContext } from "react";
import Sidebar, { SidebarItem } from "../../Layout/Sidebar/Sidebar";

import Content from "../../Layout/Content/Content";
import Main from "../../Layout/Main/Main";
import NavBar from "../../Layout/NavBar/NavBar";
import Page from "../../Layout/Page/Page";
import SlotContext from "../../state/contexts/slot";
import SweepContext from "../../state/contexts/sweep";
import SponsorChecklistComponent from "Components/SponsorChecklist/SponsorChecklist";

import CompleteModal from "./CompleteModal/CompleteModal";

import {
  isESPComplete,
  isPrizingListComplete,
  isSMSComplete,
  isSweepstakesDeliverablesComplete,
  isSweepstakesPromotionComplete,
  isSweepstakesPromotionVisible,
} from "./utils";

import paths from "../../Paths";
import { Route, Routes } from "react-router-dom";
import SweepstakesDeliverables from "Components/SweepstakesDeliverables/SweepstakesDeliverables";
import SweepstakePromotion from "Components/SweepstakePromotion/SweepstakePromotion";
import PrizingList from "Components/PrizingList/PricingList";
import Esp from "Components/Esp/Esp";
import Sms from "Components/Sms/Sms";

const SponsorChecklist: FC = () => {
  const { slot } = useContext(SlotContext);
  const { sweep } = useContext(SweepContext);

  return (
    <Page>
      {slot && sweep ? (
        <Sidebar title={slot?.company?.name} logo={slot?.company?.image}>
          <SidebarItem
            path={paths.sweepstakeDeliverables.url}
            name="Sweepstakes Deliverables"
            hasCheck
            checked={isSweepstakesDeliverablesComplete(slot)}
          />
          <SidebarItem
            path={paths.esp.url}
            name="Email Integration"
            hasCheck
            checked={isESPComplete(slot)}
          />
          <SidebarItem
            path={paths.prizingList.url}
            name="Prizing Details"
            hasCheck
            checked={isPrizingListComplete(slot)}
          />
          <SidebarItem
            path={paths.sms.url}
            name="SMS Integration"
            hasCheck
            checked={isSMSComplete(slot)}
          />
          {isSweepstakesPromotionVisible(sweep, slot) && (
            <SidebarItem
              path={paths.sweepstakePromotion.url}
              name="Sweepstake Promotion"
              hasCheck
              checked={isSweepstakesPromotionComplete(slot)}
            />
          )}
          <CompleteModal slot={slot} sweep={sweep} />
        </Sidebar>
      ) : (
        <Sidebar />
      )}
      <Content>
        <NavBar />
        <Main>
          <Routes>
            <Route
              path="sweepstakes-deliverables"
              element={<SweepstakesDeliverables />}
            />
            <Route
              path="sweepstake-promotion"
              element={<SweepstakePromotion />}
            />
            <Route path="prizing-list" element={<PrizingList />} />
            <Route path="esp" element={<Esp />} />
            <Route path="sms" element={<Sms />} />
            <Route path="*" element={<SponsorChecklistComponent />} />
          </Routes>
        </Main>
      </Content>
    </Page>
  );
};

export default SponsorChecklist;
