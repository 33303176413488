import { CPL } from "../../../DataTypes";
import routes from "./routes";
import { count as productImagesCount } from "./routes/AssetCollection/components/ProductImages/ProductImages";

const isOfferPromoCodeComplete = (cpl: CPL): boolean =>
  cpl &&
  [
    cpl.offer_type,
    cpl.off_amount || cpl.free_gift,
    cpl.promo_code,
    cpl.exclusions_notes,
    cpl.expiration,
  ].every((i) => i);

const isCPlAssetsComplete = (cpl: CPL): boolean =>
  cpl &&
  [
    !!cpl.company.image,
    !!cpl.company.brand_description,
    !!cpl.company.short_brand_description,
    !!cpl.company.tagline,
    cpl.brand_assets.offer_images.length === 1,
    cpl.brand_assets.product_shot_images.length === productImagesCount,
    cpl.no_cpl_videos || cpl.brand_assets.cpl_videos.length > 0,
  ].every((i) => i);

const isESPComplete = (cpl: CPL): boolean =>
  cpl &&
  [
    cpl.api_setup.is_enabled,
    !["No ESP", "None"].includes(cpl.api_setup.name),
    cpl.api_setup.keys && cpl.api_setup.keys.every((k) => k.value),
  ].every((i) => i);

export const getStepsCompletion = (
  cpl: CPL
): Record<keyof typeof routes, boolean> => ({
  offer: isOfferPromoCodeComplete(cpl),
  assetCollection: isCPlAssetsComplete(cpl),
  espIntegration: isESPComplete(cpl),
});
