import React, { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import SmsForm from "./Form";
import { Slots } from "../../apiClient/Slot";
import { SmsPayload } from "../../DataTypes";

import SlotContext from "../../state/contexts/slot";
import { useAuth } from "../../state/auth";
import { useLoading } from "../../state/loader";
import SweepContext from "../../state/contexts/sweep";

import nextIncompleteSection from "../../utils/nextSectionRedirection";
import { sponsorChecklistSectionOrder } from "../../pages/SponsorChecklist/utils";

import paths from "../../Paths";
import { CustomError, handleError } from "../../utils/errorHandler";
import { errorToast } from "../../utils/toast";

const Sms: FC = () => {
  const { slot, setSlot } = useContext(SlotContext);
  const { setLoading } = useLoading();
  const { loginInfo } = useAuth();
  const navigate = useNavigate();
  const { sweep } = useContext(SweepContext);

  if (!slot) {
    navigate(paths.sponsorChecklist.url);
    return null;
  }

  const onSubmit = async (values: SmsPayload): Promise<void> => {
    const slotClient = new Slots();
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure the information is correct?")) {
        setLoading(true);
        const response = await slotClient.patchSmsApi(
          slot.id,
          { ...values, sms_integration_enable: values.sms_wanted_by_brand },
          {
            headers: {
              Authorization: loginInfo.token,
            },
          }
        );
        setSlot(response.data);
        alert("Data Saved!");
        nextIncompleteSection(
          navigate,
          sponsorChecklistSectionOrder(response.data, sweep)
        );
      }
    } catch (error) {
      errorToast(handleError(error as CustomError).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="heading-primary heading__font">SMS Integration</h1>
      <article>
        <p>
          Take advantage of the high-converting SMS opt-ins to increase revenue
          from your sweepstakes! We support direct integration with three of the
          top SMS providers as well as providing an in-house integration with
          Trumpia.
        </p>
      </article>
      <SmsForm sweepstatus={sweep.status} onSubmit={onSubmit} slot={slot} />
    </div>
  );
};

export default Sms;
