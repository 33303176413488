import { useCallback } from "react";
import { Cpl } from "../../../../../../apiClient/Cpl";
import { HttpResponse } from "../../../../../../apiClient/http-client";
import { CPL, Field } from "../../../../../../DataTypes";
import { useAuth } from "../../../../../../state/auth";

type ImageUploadType = {
  file: File;
  description?: string;
  title?: string;
}

const useImageUpload = (cpl: CPL): (field: Field, image: ImageUploadType) => Promise<HttpResponse<CPL, unknown>> => {
  const { loginInfo } = useAuth();

  return useCallback(
    async (field: Field, image: ImageUploadType) =>
      new Cpl().postAssetsUploadApi(
        cpl?.id,
        {
          field,
          filename: image.file,
          description: image.description || "",
          title: image.title || "",
        },
        {
          headers: {
            Authorization: loginInfo.token,
          },
        }
      ),
    [cpl?.id, loginInfo?.token]
  );
};

export default useImageUpload;
