import { Sweep as BaseSweep } from "../apiClient/data-contracts";

export type Sweep = BaseSweep;

export enum SweepstakeStatus {
  Preparing = 1,
  Booked = 2,
  Live = 3,
  Completed = 4,
  Cancelled = 5,
  ReadyToReviewNewSlots = 6,
  FKLIsOutreachSlots = 7,
  Archived = 8,
}
