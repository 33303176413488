import React, { Fragment, useEffect, useState } from "react";

import ImageUploading, {
  ErrorsType,
  ImageListType,
} from "react-images-uploading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { trimStringToLast25Chars } from "../../utils";
import { CustomImageTypeList } from "../../DataTypes";

import "./MultipleImageUploaderWithDescription.scss";

interface MultipleImageUploaderWithDescriptionProps {
  maxImageNumber: number;
  setUploadedImages: (count: number) => void;
  imageState: ImageListType;
  setImageState: (images: ImageListType) => void;
  resolutionWidth?: number;
  resolutionHeight?: number;
  disabled?: boolean;
  canDelete?: boolean;
  removeUploadedImage?: (id: number) => void;
}

type textType = "description" | "title";

interface DisplayErrorsProps {
  errors: ErrorsType;
  maxImageNumber: number;
  acceptedImageTypes: string[];
}

const DisplayErrors = ({
  errors,
  maxImageNumber,
  acceptedImageTypes,
}: DisplayErrorsProps): JSX.Element =>
  errors && (
    <div className="upload-section__error">
      {errors.maxNumber && (
        <span>Only {maxImageNumber} images can be uploaded</span>
      )}
      {errors.acceptType && (
        <span>Only {acceptedImageTypes.join(", ")} images are accepted</span>
      )}
      {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
      {errors.resolution && (
        <span>Selected file is not match your desired resolution</span>
      )}
    </div>
  );

const MultipleImageUploaderWithDescription = ({
  maxImageNumber,
  setUploadedImages,
  imageState,
  setImageState,
  resolutionWidth,
  resolutionHeight,
  disabled,
  canDelete,
  removeUploadedImage,
}: MultipleImageUploaderWithDescriptionProps): JSX.Element => {
  const [titles, setTitles] = useState<string[]>([]);
  const [descriptions, setDescriptions] = useState<string[]>([]);

  useEffect(() => {
    if (imageState.length > 0) {
      setTitles(imageState.map((image) => image.title || ""));
      setDescriptions(imageState.map((image) => image.description || ""));
    }
  }, [imageState]);

  const maxDescriptionLength = 130;
  const maxTitleLength = maxDescriptionLength;

  const onChange = (imageList: ImageListType & CustomImageTypeList) => {
    setUploadedImages(imageList.length);
    setImageState(imageList);
  };

  const onTextChange =
    (
      textType: textType,
      texts: string[],
      setTexts: (texts: string[]) => void
    ) =>
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newTexts = [...texts];
      newTexts[index] = event.target.value;

      const imagesWithText = imageState.map((image, index) => {
        if (textType === "title") {
          image.title = newTexts[index];
        }
        if (textType === "description") {
          image.description = newTexts[index];
        }
        return image;
      });
      setTexts(newTexts);
      setImageState(imagesWithText);
    };

  const onDescriptionChange = onTextChange(
    "description",
    descriptions,
    setDescriptions
  );

  const onTitleChange = onTextChange("title", titles, setTitles);

  const deleteDescription = (index: number): void =>
    setDescriptions(descriptions.filter((_description, idx) => idx !== index));

  const acceptedImageTypes = ["jpg", "png", "jpeg"];

  return (
    <div className="image-uploader">
      <ImageUploading
        multiple
        value={imageState}
        acceptType={acceptedImageTypes}
        onChange={onChange}
        maxNumber={maxImageNumber}
        resolutionType="more"
        resolutionWidth={resolutionWidth || 1}
        resolutionHeight={resolutionHeight || 1}
      >
        {({ imageList, onImageUpload, onImageRemove, errors }) => (
          <>
            <div className="upload-section">
              {!disabled && (
                <div
                  className="upload-section__btn btn btn--primary"
                  onClick={onImageUpload}
                >
                  UPLOAD
                </div>
              )}
              <DisplayErrors
                errors={errors}
                maxImageNumber={maxImageNumber}
                acceptedImageTypes={acceptedImageTypes}
              />
            </div>

            <div className="images-section">
              {imageList.map((image, index) => (
                <Fragment key={index}>
                  <div className="image-item">
                    <img
                      className="image-item__thumbnail"
                      src={image.dataURL}
                      alt=""
                    />
                    <div className="image-item__name">
                      {image.file
                        ? image.file.name
                        : trimStringToLast25Chars(image.dataURL)}
                    </div>

                    {!disabled && canDelete && (
                      <button
                        className="image-item__btn"
                        onClick={() => {
                          onImageRemove(index);
                          deleteDescription(index);
                          image.id && removeUploadedImage(image.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    )}
                  </div>

                  <div className="wrapper">
                    <input
                      className="image-item__title"
                      type="text"
                      name="title"
                      placeholder="Write a title"
                      onChange={onTitleChange(index)}
                      defaultValue={image.title}
                      maxLength={maxTitleLength}
                    />
                    <div
                      className={`image-item__title--limit ${
                        image.title?.length >= maxTitleLength
                          ? " warning bold"
                          : ""
                      }`}
                    >
                      {image.title?.length || 0}/{maxTitleLength}
                    </div>
                    <textarea
                      className="image-item__description"
                      name="description"
                      placeholder="Write a description..."
                      onChange={onDescriptionChange(index)}
                      defaultValue={image.description}
                      maxLength={maxDescriptionLength}
                    />
                    <div
                      className={`image-item__description--limit ${
                        image.description?.length >= maxDescriptionLength
                          ? " warning bold"
                          : ""
                      }`}
                    >
                      {image.description?.length || 0} / {maxDescriptionLength}
                    </div>
                    {(!image.description || image.description.length === 0) && (
                      <div className="image-item__description--required warning">
                        Field is required
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          </>
        )}
      </ImageUploading>
    </div>
  );
};

export default MultipleImageUploaderWithDescription;
