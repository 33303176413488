import React, { FC, useContext } from "react";

import { useLocation } from "react-router-dom";

import Tab from "../../Components/Tab/Tab";
import paths from "../../Paths";
import CplContext from "../../state/contexts/cpl";
import "./NavBar.scss";

interface NavPath {
  title: string;
  isAvailable: boolean;
  redirection?: string;
}
interface INavBar {
  [key: string]: NavPath;
}

const NavBarComponent: FC = () => {
  const location = useLocation();
  const { cpl } = useContext(CplContext);

  const NavBarItems: INavBar = {
    [paths.home.url]: {
      title: "Home Page",
      isAvailable: true,
    },
    [paths.getStartedCheckList.url]: {
      title: "Get Started",
      isAvailable: true,
      redirection: paths.myAccount.url,
    },
    [paths.sponsorChecklist.url]: {
      title: "Sponsor Checklist",
      isAvailable: true,
    },
    [paths.costPerLead.url]: {
      title: "Cost Per Lead",
      isAvailable: !!cpl,
      redirection: paths.costPerLeadV2.url,
    },
    [paths.reports.url]: {
      title: "Reports",
      isAvailable: true,
      redirection: paths.currentSweepstakes.url,
    },
  };

  return (
    <nav className="navbar">
      <ul className="navbar__items">
        {Object.entries(NavBarItems).map(
          ([route, config]) =>
            config.isAvailable && (
              <li key={route} className="navbar__item">
                <Tab
                  path={config.redirection || route}
                  title={config.title}
                  selected={
                    location.pathname === config.redirection ||
                    location.pathname.startsWith(route)
                  }
                  disabled={false}
                />
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

export default NavBarComponent;
