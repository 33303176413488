import * as yup from "yup";
import { Choice, EspName } from "../../DataTypes";

const validationSchema = (choices: Choice<EspName>[]): yup.AnyObjectSchema => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFields = (choices: Choice<EspName>[]): any => {
    return choices.reduce(
      (previousChoices, integration) => ({
        ...previousChoices,
        ...integration.fields.reduce(
          (previousFields, field) => ({
            ...previousFields,
            [`${integration.value}-${field.name}`]: yup
              .string()
              .when("esp_selected", {
                is: integration.value,
                then: yup.string().required("Required"),
              }),
          }),
          {}
        ),
      }),
      {}
    );
  };

  return yup.object().shape({
    esp_selected: yup.mixed().required("Please select an Email Provider"),
    ...getFields(choices),
  });
};

export default validationSchema;
